<div class="detailarea sticky-top">
  <nav class="navbar navbar-expand detailtoolbar">
    <div class="navbar-collapse collapse">
      <ul class="navbar-nav me-auto">
        <li class="nav-item me-1">
          <a class="nav-link navlink-hover" (click)="save()">
            <img src="./assets/images/Save.svg"/><span>Speichern</span>
          </a>
        </li>
        <li class="nav-item me-1">
          <a class="nav-link navlink-hover" (click)="cancel()">
            <img src="./assets/images/Cancel.svg"/><span>Abbrechen</span>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</div>
<div class="d-flex flex-column flex-grow-1 container-fluid detail-area">
  <form #dialogForm="ngForm" class="p-3">
    <div class="row">
      <div class="card col-xl-6 p-0">
        <div class="card-header p-2">Rollendaten</div>
        <div class="card-body">
          <div class="form-group row">
            <label for="roleName" class="col-form-label col-sm-3">Rollenname:</label>
            <div class="col">
              <input type="text" class="form-control" autofocus="autofocus" id="roleName" name="role.roleName"
                     [(ngModel)]="role.roleName" required maxlength="255"
                     ngbTooltip="Die Eingabe eines eindeutigen Rollennamens ist erforderlich."
                     tooltipClass="errortooltip"
                     [disableTooltip]="!dialogForm.form.controls['role.roleName']?.errors || !dialogForm.form.controls['role.roleName']?.touched">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="card col-xl-6 p-0">
        <div class="card-header p-2">Berechtigungen</div>
        <div class="card-body">
          <ul class="list-group list-group-flush ">
            <ng-container *ngFor="let perm of userManagerService.permissions; let i=index;">
              <li class="list-group-item pt-1 pb-1 ps-0 pe-0 border-0">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" [id]="perm.permissionKey"
                         name="{{'userPermissions['+i+'].permission'}}"
                         [(ngModel)]="userPermissions[i].permission">
                  <label class="custom-control-label ms-2" [for]="perm.permissionKey">{{perm.permisssionName}}</label>
                </div>
              </li>
            </ng-container>
          </ul>
          <div class="mt-3">
            <a class="linkbutton" (click)="selectAllRigths(true)">Alle auswählen</a>
            <a class="linkbutton ms-3" (click)="selectAllRigths(false)">Keine auswählen</a>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
