<div class="modal-header">
  <div class="modal-title" id="modal-basic-title">{{title}}</div>
  <button type="button" class="btn-close" aria-label="Close" (click)="onClose()">

  </button>
</div>

<form #dialogForm="ngForm" (ngSubmit)="onOK()">
  <div class="modal-body">
    <div class="modal-center">
      <div class="form-group">
        <label for="lieferantenWawinr" class="col-form-label">WAWI-Nr.:</label>
        <input ngbAutofocus type="text" class="form-control" id="lieferantenWawinr" name="lieferanten.wawinr"
               [(ngModel)]="lieferant.wawinr" required maxlength="5"
               ngbTooltip="Die Eingabe einer eindeutigen WAWI-Nr. ist erforderlich." tooltipClass="errortooltip"
               [disableTooltip]="!dialogForm.form.controls['lieferanten.wawinr']?.errors || !dialogForm.form.controls['lieferanten.wawinr']?.touched">
      </div>
      <div class="form-group">
        <label for="vlnr" class="col-form-label">VL-Nr.:</label>
        <input type="number" class="form-control" id="vlnr" name="lieferanten.vlnr" [(ngModel)]="lieferant.vlnr"
               required
               ngbTooltip="Die Eingabe einer eindeutigen VL-Nr. ist erforderlich." tooltipClass="errortooltip"
               [disableTooltip]="!dialogForm.form.controls['lieferanten.vlnr']?.errors || !dialogForm.form.controls['lieferanten.vlnr']?.touched">
      </div>
      <div class="form-group">
        <label for="lieferantenName" class="col-form-label">Name:</label>
        <input type="text" class="form-control" id="lieferantenName" name="lieferanten.name"
               [(ngModel)]="lieferant.name" required maxlength="255"
               ngbTooltip="Die Eingabe eines eindeutigen Namens ist erforderlich." tooltipClass="errortooltip"
               [disableTooltip]="!dialogForm.form.controls['lieferanten.name']?.errors || !dialogForm.form.controls['lieferanten.name']?.touched">
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary btn-sm dialog-button">OK</button>
      <button type="button" class="btn btn-outline-primary btn-sm dialog-button" (click)="onCancel()">Abbrechen
      </button>
    </div>
  </div>
</form>



