<div class="detailarea sticky-top" [hidden]="!isModuleUrl">
  <nav class="navbar navbar-expand detailtoolbar">
    <div class="navbar-collapse collapse">
      <ul class="navbar-nav me-auto">
        <li class="nav-item me-1" *ngIf="hasWritePermission">
          <a class="nav-link navlink-hover" (click)="new()">
            <img src="./assets/images/New.svg"/><span>Neu</span>
          </a>
        </li>
        <li class="nav-item me-1" *ngIf="hasWritePermission">
          <a class="nav-link navlink-hover" (click)="edit()">
            <img src="./assets/images/Edit.svg"/><span>Bearbeiten</span>
          </a>
        </li>
        <li class="nav-item me-1" *ngIf="hasWritePermission">
          <a class="nav-link navlink-hover" (click)="delete()">
            <img src="./assets/images/Delete.svg"/><span>Löschen</span>
          </a>
        </li>
        <li class="nav-item me-1">
          <a class="nav-link navlink-hover" (click)="export()">
            <img src="./assets/images/ExcelExport.svg"/><span>Exportieren</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link navlink-hover" (click)="refresh()">
            <img src="./assets/images/Refresh.svg"/><span>Aktualisieren</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link navlink-hover" (click)="reset()">
            <img src="./assets/images/Reset.svg"/><span>Zurücksetzen</span>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</div>

<div class="d-flex flex-column flex-grow-1 overflow-hidden" [hidden]="!isModuleUrl"
     [ngClass]="!isModuleUrl ? 'display-none-important' : ''">
  <dx-data-grid #grid class="d-flex flex-column h-100 vh-100-safari w-100 mw-100" style="height: 100px"
                [dataSource]="dataSource"
                [allowColumnReordering]="true"
                [allowColumnResizing]="true"
                columnResizingMode="widget"
                [showBorders]="true"
                [showColumnLines]="true"
                [showRowLines]="true"
                [remoteOperations]="true"
                [wordWrapEnabled]="true"
                [hoverStateEnabled]="true"
                [errorRowEnabled]="true"
                (onExporting)="onGridExporting($event)"
                (onToolbarPreparing)="onGridToolbarPreparing($event)"
                (onRowDblClick)="onDblClick($event)"
                (onKeyDown)="onKeyDown($event)">

    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-builder-popup></dxo-filter-builder-popup>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-group-panel [visible]="false"></dxo-group-panel>
    <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
    <dxo-selection mode="single"></dxo-selection>
    <dxo-grouping #expand [autoExpandAll]="false"></dxo-grouping>
    <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
    <dxo-load-panel [showPane]="false"></dxo-load-panel>
    <dxo-export [enabled]="true" [fileName]="fileName()" [allowExportSelectedData]="false"></dxo-export>

    <dxi-column dataField="id" caption="FS Partner Id" alignment="left" minWidth="100" dataType="number"
                [visible]="false" [allowFiltering]="false" [allowGrouping]="false"></dxi-column>
    <dxi-column dataField="bbn" caption="BBN" alignment="left" width="75" dataType="number"  [filterOperations]="filterOperations"></dxi-column>
    <dxi-column dataField="anschlusshaus" caption="Anschlusshaus" alignment="left" minWidth="100"
                dataType="string"></dxi-column>

    <dxo-summary>
      <dxi-total-item column="anschlusshaus" summaryType="count" alignment="right">
      </dxi-total-item>
    </dxo-summary>
  </dx-data-grid>
</div>

<ng-container *ngIf="!isModuleUrl">
  <router-outlet></router-outlet>
</ng-container>
