import { AuthConfig } from 'angular-oauth2-oidc';

export const authConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: 'https://adfs.fuersie-it.de/adfs',
  // The SPA's id. The SPA is registered with this id at the auth-server
  clientId: null,

  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin,
  postLogoutRedirectUri: window.location.origin,

  scope: 'openid profile email offline_access',
  showDebugInformation: true,
  disablePKCE:false,
  responseType : 'code'

  //Wird automatisch durch den Server konfiguriert
  //loginUrl: 'https://adfs.fuersie-it.de/adfs/oauth2/authorize',
  //logoutUrl: 'https://adfs.fuersie-it.de/adfs/oauth2/logout',
  //tokenEndpoint: 'https://adfs.fuersie-it.de/adfs/oauth2/token/',
  //userinfoEndpoint: 'https://adfs.fuersie-it.de/adfs/userinfo',
}
