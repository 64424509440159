<div class="detailarea sticky-top">
  <nav class="navbar navbar-expand detailtoolbar">
    <div class="navbar-collapse collapse">
      <ul class="navbar-nav me-auto">
        <li class="nav-item me-1">
          <a class="nav-link navlink-hover" (click)="back()">
            <img src="./assets/images/Back.svg"/><span>Zurück</span>
          </a>
        </li>
        <li class="nav-item me-1" *ngIf="hasWritePermission && (yearselectionService.zeitraumActive || userManagerService.isUserAdmin())">
          <a class="nav-link navlink-hover" (click)="add()">
            <img src="./assets/images/New.svg"/><span>Hinzufügen</span>
          </a>
        </li>
        <li class="nav-item me-1" *ngIf="hasWritePermission && (yearselectionService.zeitraumActive || userManagerService.isUserAdmin())">
          <a class="nav-link navlink-hover" (click)="delete()">
            <img src="./assets/images/Delete.svg"/><span>Löschen</span>
          </a>
        </li>
        <li class="nav-item me-1">
          <a class="nav-link navlink-hover" (click)="export()">
            <img src="./assets/images/ExcelExport.svg"/><span>Exportieren</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link navlink-hover" (click)="refresh()">
            <img src="./assets/images/Refresh.svg"/><span>Aktualisieren</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link navlink-hover" (click)="reset()">
            <img src="./assets/images/Reset.svg"/><span>Zurücksetzen</span>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</div>

<div class="d-flex flex-column flex-grow-1 overflow-hidden">
  <as-split direction="horizontal" class="vh-100-safari" [gutterSize]="splitterSize"
            (gutterClick)="splitterClick($event)" (dragEnd)="splitterDragEnd($event)">
    <as-split-area [size]="sizeMainPanel">
      <dx-data-grid #grid class="d-flex flex-column h-100 vh-100-safari w-100 mw-100" style="height: 100px"
                    [dataSource]="dataSource"
                    [allowColumnReordering]="true"
                    [allowColumnResizing]="true"
                    columnResizingMode="widget"
                    [showBorders]="true"
                    [showColumnLines]="true"
                    [showRowLines]="true"
                    [remoteOperations]="true"
                    [wordWrapEnabled]="true"
                    [hoverStateEnabled]="true"
                    [errorRowEnabled]="true"
                    (onExporting)="onGridExporting($event)"
                    (onToolbarPreparing)="onGridToolbarPreparing($event)"
                    (onKeyDown)="onKeyDown($event)">

        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-filter-panel [visible]="true"></dxo-filter-panel>
        <dxo-filter-builder-popup></dxo-filter-builder-popup>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-group-panel [visible]="true"></dxo-group-panel>
        <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
        <dxo-selection mode="multiple"></dxo-selection>
        <dxo-grouping #expand [autoExpandAll]="false"></dxo-grouping>
        <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
        <dxo-load-panel [showPane]="false"></dxo-load-panel>
        <dxo-export [enabled]="true" [fileName]="fileName()" [allowExportSelectedData]="false"></dxo-export>

        <dxi-column dataField="partnerkondition.lieferantenkonditionId" caption="Lieferantenkondition Id" alignment="left" minWidth="100"
                    dataType="number"
                    [visible]="false" [allowFiltering]="false" [allowGrouping]="false"></dxi-column>
        <dxi-column dataField="lieferantenkonditionView.wawinr" caption="WAWI-NR." alignment="left"
                    width="100"
                    dataType="string"></dxi-column>
        <dxi-column dataField="lieferantenkonditionView.vlnr" caption="VL-Nr." alignment="left" width="100"
                    dataType="number" [filterOperations]="filterOperations"></dxi-column>
        <dxi-column dataField="lieferantenkonditionView.name" caption="Name" alignment="left" minWidth="100"
                    dataType="string"></dxi-column>
        <dxi-column dataField="lieferantenkonditionView.gegenleistungsart" caption="Gegenleistungsart" alignment="left"
                    minWidth="100"
                    dataType="string"></dxi-column>
        <dxi-column dataField="lieferantenkonditionView.ksnr" caption="KS-Nr." alignment="left" minWidth="65" width="65"
                    dataType="number" [filterOperations]="filterOperations"></dxi-column>
        <dxi-column dataField="lieferantenkonditionView.ksbezeichnung" caption="KS-Bezeichnung" alignment="left"
                    minWidth="100"
                    dataType="string"></dxi-column>
        <dxi-column dataField="lieferantenkonditionView.anzahlGegenleistungen" caption="Anzahl Gegenleistungen"
                    alignment="left" minWidth="100" dataType="number"></dxi-column>
        <dxi-column dataField="lieferantenkonditionView.anzahlArtikel" caption="Anzahl Artikel" alignment="left"
                    minWidth="100" dataType="number"></dxi-column>
        <dxi-column dataField="lieferantenkonditionView.summe" caption="Summe" alignment="left" minWidth="100"
                    dataType="number"></dxi-column>
        <dxi-column dataField="lieferantenkonditionView.wkz" caption="% / Fix - WKZ" alignment="left" minWidth="100"
                    dataType="string"></dxi-column>
        <dxi-column dataField="lieferantenkonditionView.bemerkungExtern" caption="Bemerkung Extern" alignment="left"
                    minWidth="100"
                    dataType="string"></dxi-column>
        <dxi-column dataField="lieferantenkonditionView.bemerkungIntern" caption="Bemerkung Intern" alignment="left"
                    minWidth="100"
                    dataType="string"></dxi-column>

        <dxo-summary>
          <dxi-total-item column="lieferantenkonditionView.bemerkungIntern" summaryType="count" alignment="right">
          </dxi-total-item>
        </dxo-summary>
      </dx-data-grid>
    </as-split-area>
    <as-split-area [size]="sizeDetailPanel" class="d-flex flex-column flex-grow-1">
      <div class="h-100 container-fluid detailpanel">
        <form class="p-3">
          <!-- Partnerdaten -->
          <div class="row">
            <div class="card col-12 p-0">
              <div class="card-header p-2 detailpanel-cardheader">Partnerdaten</div>
              <div class="card-body pb-1">
                <div class="form-group row mb-3">
                  <label class="form-control-sm col-form-label ms-2 detailpanel-label">BBN:</label>
                  <div class="col">
                    <input type="text" class="form-control form-control-sm" name="rootObject.fsPartnerId"
                           [(ngModel)]="rootObject.bbn" readonly>
                  </div>
                </div>
                <div class="form-group row mb-3">
                  <label class="form-control-sm col-form-label ms-2 detailpanel-label">Anschlusshaus:</label>
                  <div class="col">
                    <input type="text" class="form-control form-control-sm" name="rootObject.name"
                           [(ngModel)]="rootObject.anschlusshaus" readonly>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </as-split-area>
  </as-split>
</div>

<ng-container>
  <router-outlet></router-outlet>
</ng-container>
