import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {FunctionWindow} from "../../../../../framework/functionwindow/function-window";
import {UserManagerService} from "../../../../../usermanager/usermanager.service";
import {ActivatedRoute, Router} from "@angular/router";
import {WaitCursor, WaitCursorService} from "../../../../../framework/wait-cursor/wait-cursor.service";
import {DxDataGridComponent} from "devextreme-angular";
import {InteractionController} from "../../../../../framework/interaction-controller/interaction.controller";
import {PartnerkonditionClient} from "../../../../../webapi/webapi.service";
import {ZeitraumChangedEvent} from "../../../../../shared/events/zeitraum-changed-event";
import {EventAggregator} from "../../../../../framework/event-aggregator/event.aggregator";
import {YearselectionService} from "../../../../../shared/services/yearselection.service";
import {PartnerkonditionenFunctionModule} from "../../../functionmodules/partnerkonditionen-function-module";
import {
  PartnerkonditionenDialogComponent,
  PartnerkonditionenDialogParameter
} from "../partnerkonditionen-dialog/partnerkonditionen-dialog.component";
import {CustomBreadcrumbEvent} from "../../../../../shared/events/custom-breadcrumb-event";
import {IBreadcrumb} from "../../../../../shared/interfaces/ibreadcrumb";
import {Using} from "../../../../../framework/using/using";

@Component({
  selector: 'app-partnerkonditionen',
  templateUrl: './partnerkonditionen.component.html',
  host: {class: 'container-fluid d-flex h-100 flex-column p-0 bg-detailtoolbar'}
})
export class PartnerkonditionenComponent extends FunctionWindow implements OnInit, OnDestroy {
  @ViewChild('grid') dataGrid: DxDataGridComponent;
  gridKey: string[] = ["id"];
  loadParams;
  partnerId: number;
  rootObject: any = {};
  subscribeLambda = year => {
    this.setYear(year);
  };

  get hasWritePermission(): boolean {
    return this.userManagerService.hasWritePermission(this.functionModule.contractName);
  }

  constructor(@Inject('BASE_URL') baseUrl: string,
              @Inject(PLATFORM_ID) protected platformId: any,
              public userManagerService: UserManagerService,
              private client: PartnerkonditionClient,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected waitCursorService: WaitCursorService,
              protected functionModule: PartnerkonditionenFunctionModule,
              public yearselectionService: YearselectionService,
              private eventAggregator: EventAggregator) {
    super(platformId, userManagerService, router, waitCursorService, functionModule);
    this.eventAggregator.getEvent(ZeitraumChangedEvent).subscribe(this.subscribeLambda);

    if (this.activatedRoute.snapshot.params.id !== undefined) {
      this.partnerId = this.activatedRoute.snapshot.params.id;
    }

    if (window.history.state.rootObjectOffer !== undefined) {
      this.rootObject = window.history.state.rootObjectOffer;
    }

    this.initDetailPanelSettings();
    this.setDetailPanelValues();

    this.loadParams = {year: yearselectionService.year, partnerId: this.partnerId};
    this.loadUrl = baseUrl + 'api/partnerkondition/queryallpartnerkonditionbyid';
    this.createDataSource(this.loadParams);
  }

  async ngOnInit() {
    try {
      await Using.using(new WaitCursor(this.waitCursorService), async () => {
        super.ngOnInit();
        if ((Object.keys(this.rootObject).length === 0)) {
          let partner = await this.client.getPartner(this.partnerId).toPromise();
          if (partner != null) {
            this.rootObject.bbn = partner.bbn;
            this.rootObject.anschlusshaus = partner.anschlusshaus;
          } else {
            await InteractionController.MessageBox.showWarning("Keine Partnerdaten", "Partnerdaten konnten nicht abgerufen werden. Möglicherweise existiert der Partner nicht.");
            this.router.navigate([`${this.functionModule.routerPath}`]);
          }
        }
        if ((Object.keys(this.rootObject).length !== 0)) {
          this.publishCustomBreadCrumb();
        }
      });
    } catch (e) {
      await InteractionController.MessageBox.showApplicationException(e);
      this.router.navigate(['.'], {relativeTo: this.activatedRoute.parent});
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.eventAggregator.getEvent(ZeitraumChangedEvent).unsubscribe(this.subscribeLambda);
  }

  private publishCustomBreadCrumb() {
    let breadcrumbs: IBreadcrumb[] = [];

    let breadcrumb1: IBreadcrumb = {
      breadcrumbGroup: "",
      breadcrumbLabel: `Partner: ${this.rootObject.bbn}`,
      params: {},
      url: this.functionModule.routerPath,
      state: {id: this.partnerId}
    };
    breadcrumbs.push(breadcrumb1);

    let breadcrumb2: IBreadcrumb = {
      breadcrumbGroup: "",
      breadcrumbLabel: this.functionModule.moduleRoutes[0].breadcrumbLabel,
      params: {},
      url: this.functionModule.moduleRoutes[0].path,
      state: {}
    };
    breadcrumbs.push(breadcrumb2);

    this.eventAggregator.getEvent(CustomBreadcrumbEvent).publish(breadcrumbs);
  }


  setYear(year: number) {
    this.loadParams = {year: year, partnerId: this.partnerId};
    this.createDataSource(this.loadParams);
  }

  async add() {
    if (this.hasWritePermission) {
      let parameter: PartnerkonditionenDialogParameter = new PartnerkonditionenDialogParameter();
      parameter.partnerId = this.partnerId;
      if (await InteractionController.showDialog(PartnerkonditionenDialogComponent, parameter, {size: 'xl'})) {
        this.dataGrid.instance.refresh();
      }
    }
  }

  async edit() {
  }

  async delete() {
    try {
      if (this.dataGrid.selectedRowKeys.length > 0) {
        if (await InteractionController.MessageBox.showInfoConfirmation("Partnerkondition löschen",
          "Die Verknüpfung der Lieferantenkondition zum Partner wird unwiderruflich gelöscht.<br>\n" +
          "Falls Nachweise mit der gewählten Partnerkondition verbunden sind werden diese mit gelöscht.<br>\n" +
          "Wollen Sie die ausgewählte Partnerkondition wirklich löschen?")) {

          let konditionIdList: number[] = [];
          let selectedRowsData = this.dataGrid.instance.getSelectedRowsData();
          for (let i = 0; i < selectedRowsData.length; i++) {
            konditionIdList.push(selectedRowsData[i].partnerkondition.lieferantenkonditionId);
          }

          let retValue = await this.client.delete(this.partnerId, konditionIdList).toPromise();
          this.refresh();
        }
      } else {
        InteractionController.MessageBox.showWarning("Keine Auswahl", "Bitte selektieren Sie einen Eintrag aus der Liste und führen Sie die Operation erneut aus.");
      }
    } catch (e) {
      InteractionController.MessageBox.showApplicationException(e);
    }
  }

  back() {
    this.router.navigate(['.'], {relativeTo: this.activatedRoute.parent, state: {id: this.partnerId}});
  }
}
