<div
  class="d-flex flex-column flex-grow-1 h-100 align-items-center container-padding container-overflow bg-home">
  <div class="container-fluid cardcontainer">
    <div class="row  flex-sm-wrap flex-md-nowrap flex-lg-nowrap flex-xl-nowrap ">
      <ng-container *ngFor="let group of this.functionMenuService.functionGroups">
        <div class="tileColumn me-md-1 me-lg-1 me-xl-1" *ngIf="group.visible">
          <div class="tile-row-label">{{group.itemName}}</div>
          <ng-template #menuTemplateRef let-group>
            <ng-container *ngFor="let function of group.menuItems">
              <ng-container *ngIf="function.menuItems.length != 0;else elseBlock">
                <ng-container *ngTemplateOutlet="menuTemplateRef; context:{ $implicit: function }">
                </ng-container>
              </ng-container>
              <ng-template #elseBlock>
                <div class="row tile-padding"
                     *ngIf="function.functionModule.homeTileVisible === true && function.visible">
                  <div [class]="function.functionModule.homeTileClass" [class.tile]="true"
                       (click)="navigateToModuleComponent(function.functionModule.routerPath, function.functionModule.externalURLPath)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-4">
                          <img class="card-img" [src]="function.functionModule.homeTileImageSource"/>
                        </div>
                        <div class="col-8 mt-2">
                          <ng-template [ngIf]="function.functionModule.homeTileSubImageSource != undefined" [ngIfElse]="noLogo">
                            <img class="card-img card-logo card-title align-middle" [src]="function.functionModule.homeTileSubImageSource" />
                          </ng-template>

                          <ng-template #noLogo>
                            <span class="card-title align-middle">
                              {{function.functionModule.functionName}}
                            </span>
                          </ng-template>
                        </div>
                      </div>
                      <div class="row justify-content-center ">
                        <span class="card-subtitle text-center">{{function.functionModule.homeTileSubTitle}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ng-container>
          </ng-template>
          <ng-container
            *ngTemplateOutlet="menuTemplateRef; context:{ $implicit: group }"></ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</div>
