import { OnDestroy, OnInit, Directive } from "@angular/core";

declare var DetailToolbarHelper: any;
declare var NavLinkHoverHelper: any;

@Directive()
export abstract class RecordWindow implements OnInit, OnDestroy {
  protected constructor() {

  }

  ngOnInit(): void {
    this.registerVisualJsEvents();
  }

  ngOnDestroy(): void {
    this.unregisterVisualJsEvents();
  }

  async canChangeDate():  Promise<boolean>{
    return true;
  }

  protected registerVisualJsEvents() {
    DetailToolbarHelper.registerOnDropdownShow();
    DetailToolbarHelper.registerOnDropdownHide();
    NavLinkHoverHelper.registerOnNavLinkTouch();
  }

  protected unregisterVisualJsEvents() {
    DetailToolbarHelper.unregisterOnDropdownShow();
    DetailToolbarHelper.unregisterOnDropdownHide();
    NavLinkHoverHelper.unregisterOnNavLinkTouch();
  }
}
