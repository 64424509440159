import {MessageBoxService} from "../message-box/message-box.service";
import {Injectable, Injector} from "@angular/core";
import {NgbModal, NgbModalOptions, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {TaskDialogSimpleResult} from "../message-box/taskdialog/task-dialog-simple-result";
import {TaskDialogButtons} from "../message-box/taskdialog/task-dialog-buttons";

@Injectable({
  providedIn: 'root'
})
export class InteractionController {
  public static MessageBox: MessageBoxService;
  private static _modalService: NgbModal;
  private static _injector: Injector;

  constructor(private messsageBoxService: MessageBoxService, modalService: NgbModal, injector: Injector) {
    InteractionController.MessageBox = messsageBoxService;
    InteractionController._modalService = modalService;
    InteractionController._injector = injector;
  }

  public static async showDialog(dialogComponent: any, parameter: any = null, options: NgbModalOptions = null): Promise<boolean> {
    return await InteractionController.processShowDialog(dialogComponent, parameter, options) == TaskDialogSimpleResult.Ok;
  }

  private static async processShowDialog(dialogComponent: any, parameter: any, options: NgbModalOptions): Promise<TaskDialogSimpleResult> {
    // create modal options
    if (options == null)
      options = {
        centered: true, beforeDismiss: () => {
          return false;
        }
      };
    else {
      options.centered = true;
      options.beforeDismiss = () => {
        return false;
      };
    }
    if (parameter != null) {
      options.injector = Injector.create([{provide: parameter.constructor, useValue: parameter}]);
    }

    // open modal
    const modal: NgbModalRef = InteractionController._modalService.open(dialogComponent, options);

    // register onclick callbacks and return
    let resolveFunc: (value?: TaskDialogSimpleResult) => void;

    const retValue = new Promise<TaskDialogSimpleResult>(resolve => {
      resolveFunc = resolve;
    });

    modal.componentInstance.onButtonPressed.on((button) => {
      let retValue: TaskDialogSimpleResult;
      switch (button) {
        case TaskDialogButtons.Cancel:
          retValue = TaskDialogSimpleResult.Cancel;
          break;
        case TaskDialogButtons.OK:
          retValue = TaskDialogSimpleResult.Ok;
          break;
      }

      resolveFunc(retValue);
    });

    return retValue;
  }
}

