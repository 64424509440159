import {Injectable} from "@angular/core";
import {FunctionModuleBase} from "../../../framework/functionmodule/function-module-base";
import {UserManagerService} from "../../../usermanager/usermanager.service";
import {Router} from "@angular/router";
import {PartneruebersichtComponent} from "../konditionen/partner/partner-uebersicht/partneruebersicht.component";
import {PartnerkonditionenComponent} from "../konditionen/partner/partnerkonditionen/partnerkonditionen.component";
import {ModuleRoute} from "../../../framework/functionmodule/module-route";
import {UserPermissionSuffix} from "../../../usermanager/model/user-permission-suffix";

@Injectable({
  providedIn: 'root'
})
export class PartnerkonditionenFunctionModule extends FunctionModuleBase {
  contractName = "Partnerkonditionen";
  functionName = "Partnerkonditionen";
  groupName = "Konditionen";
  routerPath = "partnerkonditionen";
  component = PartneruebersichtComponent;
  breadcrumbGroup = "Konditionen";
  homeTileClass = "tileGroupClass2";
  homeTileImageSource = './assets/images/Home.svg';
  homeTileSubTitle = "Übersicht der Partnerkonditionen";

  constructor(protected userManagerService: UserManagerService, protected router: Router) {
    super(userManagerService, router);
    this.configureFunctionModule();
  }

  configureModuleRoutes() {
    super.configureModuleRoutes();
    this.moduleRoutes.push(
      new ModuleRoute('partner/:id', 'Konditionen', PartnerkonditionenComponent, `${this.contractName}${UserPermissionSuffix.ReadPermission}`)
    );
  }
}
