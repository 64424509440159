import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MessageBoxComponent} from "./message-box.component";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  imports: [
    CommonModule,
    NgbModule
  ],
  declarations: [
    MessageBoxComponent
  ],
})
export class MessageBoxModule {
}
