import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {FunctionWindow} from "../../../../framework/functionwindow/function-window";
import {UserManagerService} from "../../../../usermanager/usermanager.service";
import {ActivatedRoute, Router} from "@angular/router";
import {WaitCursorService} from "../../../../framework/wait-cursor/wait-cursor.service";
import {DxDataGridComponent} from "devextreme-angular";
import {InteractionController} from "../../../../framework/interaction-controller/interaction.controller";
import {ZeitraumdatenFunctionModule} from "../../functionmodules/zeitraumdaten-function-module";
import {ZeitraumDialogComponent, ZeitraumDialogParameter} from "../zeitraum-dialog/zeitraum-dialog.component";
import {ZeitraumClient} from "../../../../webapi/webapi.service";
import {ZeitraumlistChangedEvent} from "../../../../shared/events/zeitraumlist-changed-event";
import {EventAggregator} from "../../../../framework/event-aggregator/event.aggregator";

@Component({
  selector: 'app-zeitraum',
  templateUrl: './zeitraumdaten.component.html',
  host: {class: 'container-fluid d-flex h-100 flex-column p-0 bg-detailtoolbar'}
})
export class ZeitraumdatenComponent extends FunctionWindow implements OnInit, OnDestroy {
  @ViewChild('grid') dataGrid: DxDataGridComponent;
  gridKey: string[] = ["id"];
  get hasWritePermission(): boolean {
    return this.userManagerService.hasWritePermission(this.functionModule.contractName);
  }

  statusLookupData = {
    store: {
      type: 'array',
      data: [
        {status: false, name: 'Inaktiv'},
        {status: true, name: 'Aktiv'}
      ],
      key: "status"
    }
  };

  constructor(@Inject('BASE_URL') baseUrl: string,
              @Inject(PLATFORM_ID) protected platformId: any,
              private eventAggregator: EventAggregator,
              protected userManagerService: UserManagerService,
              private client: ZeitraumClient,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected waitCursorService: WaitCursorService,
              protected functionModule: ZeitraumdatenFunctionModule) {
    super(platformId, userManagerService, router, waitCursorService, functionModule);

    this.loadUrl = baseUrl + 'api/zeitraum/query';
    this.createDataSource();
  }

  onMainModuleNavigation(state: any) {
    super.onMainModuleNavigation(state);

    // we navigated back from client
    if (window.history.state.id !== undefined && window.history.state.id != 0) {
      if (this.dataGrid !== undefined) {
        this.refresh();
        this.dataGrid.selectedRowKeys = [{id: +window.history.state.id}];
      }
    }
  }

  async new() {
    if (this.hasWritePermission) {
      let parameter: ZeitraumDialogParameter = new ZeitraumDialogParameter();
      parameter.zeitraumId = 0;
      if (await InteractionController.showDialog(ZeitraumDialogComponent, parameter)) {
        this.refresh();
        this.dataGrid.selectedRowKeys = [{id: parameter.zeitraumId}];
      }
    }
  }

  async edit() {
    if (this.hasWritePermission) {
      if (this.dataGrid.selectedRowKeys.length > 0) {
        let parameter: ZeitraumDialogParameter = new ZeitraumDialogParameter();
        parameter.zeitraumId = this.dataGrid.selectedRowKeys[0].id;
        if (await InteractionController.showDialog(ZeitraumDialogComponent, parameter)) {
          this.refresh();
          this.dataGrid.selectedRowKeys = [{id: parameter.zeitraumId}];
        }
      } else
        InteractionController.MessageBox.showWarning("Keine Auswahl", "Bitte selektieren Sie einen Eintrag aus der Liste und führen Sie die Operation erneut aus.");
    }
  }

  async delete() {
    try {
      if (this.dataGrid.selectedRowKeys.length > 0) {
        if (await InteractionController.MessageBox.showInfoConfirmation("Zeitraum löschen",
          "Der Zeitraum wird unwiderruflich gelöscht. Wollen Sie den ausgewählten Zeitraum wirklich löschen?")) {
          let retValue = await this.client.deleteZeitraum(this.dataGrid.selectedRowKeys[0].id).toPromise();
          this.refresh();
        }
      } else {
        InteractionController.MessageBox.showWarning("Keine Auswahl", "Bitte selektieren Sie einen Eintrag aus der Liste und führen Sie die Operation erneut aus.");
      }
    } catch (e) {
      InteractionController.MessageBox.showApplicationException(e);
    }
  }

  refresh() {
    super.refresh();
    this.eventAggregator.getEvent(ZeitraumlistChangedEvent).publish(this.dataSource);
  }
}
