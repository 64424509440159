import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {FunctionWindow} from "../../../../framework/functionwindow/function-window";
import {UserManagerService} from "../../../../usermanager/usermanager.service";
import {ActivatedRoute, Router} from "@angular/router";
import {WaitCursor, WaitCursorService} from "../../../../framework/wait-cursor/wait-cursor.service";
import {DxDataGridComponent} from "devextreme-angular";
import {LieferantsichtFunctionModule} from "../../functionsmodules/lieferantsicht-function-module.service";
import {Using} from "../../../../framework/using/using";
import {InteractionController} from "../../../../framework/interaction-controller/interaction.controller";

@Component({
  selector: 'app-lieferantensicht',
  templateUrl: './lieferantensicht-uebersicht.component.html',
  host: {class: 'container-fluid d-flex h-100 flex-column p-0 bg-detailtoolbar'}
})
export class LieferantensichtUebersichtComponent extends FunctionWindow implements OnInit, OnDestroy {
  @ViewChild('grid') dataGrid: DxDataGridComponent;
  gridKey: string[] = ["id"];

  get hasWritePermission(): boolean {
    return this.userManagerService.hasWritePermission(this.functionModule.contractName);
  }

  constructor(@Inject('BASE_URL') baseUrl: string,
              @Inject(PLATFORM_ID) protected platformId: any,
              protected userManagerService: UserManagerService,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected waitCursorService: WaitCursorService,
              protected functionModule: LieferantsichtFunctionModule) {
    super(platformId, userManagerService, router, waitCursorService, functionModule);

    this.loadUrl = baseUrl + 'api/lieferantensicht/querylieferantenoverview';
    this.createDataSource();
  }

  async ngOnInit() {
    try {
      await Using.using(new WaitCursor(this.waitCursorService), async () => {
        super.ngOnInit();
        if (this.userManagerService.userLieferanten.length == 1 && this.userManagerService.benutzerArt != 0) {
          this.router.navigate([`lieferant/${this.userManagerService.userLieferanten[0].lieferantId}`], {relativeTo: this.activatedRoute});
        }
      });
    } catch (e) {
      await InteractionController.MessageBox.showApplicationException(e);
      this.router.navigate(['.'], {relativeTo: this.activatedRoute.parent});
    }
  }

  onMainModuleNavigation(state: any) {
    super.onMainModuleNavigation(state);
    if (this.userManagerService.userLieferanten.length == 1 && this.userManagerService.benutzerArt != 0) {
      this.router.navigate([`lieferant/${this.userManagerService.userLieferanten[0].lieferantId}`], {relativeTo: this.activatedRoute});
    }
    // we navigated back from client
    if (window.history.state.id !== undefined && window.history.state.id != 0) {
      if (this.dataGrid !== undefined) {
        this.dataGrid.selectedRowKeys = [{id: +window.history.state.id}];
      }
    }
  }

  navigateToLieferant(rowData: any) {
    this.router.navigate([`lieferant/${rowData.id}`], {
      relativeTo: this.activatedRoute,
      state: {rootObjectOffer: rowData}
    });
  }
}
