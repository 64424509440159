import {Disposable} from "./disposable";
import {Component} from '@angular/core';

export module Using {
  type UsingObject = Disposable.Disposable | { close(): void; } | { unsubscribe(): void; };
  type AsyncUsingObject = Disposable.AsyncDisposable | { close(): Promise<void>; } | { unsubscribe(): Promise<void>; };

  export function using<TDisposable extends UsingObject | AsyncUsingObject, TResult = void>(
    resource: TDisposable,
    func: (resource: TDisposable) => Promise<TResult>
  ): Promise<TResult>;
  export function using<TDisposable extends UsingObject, UIteratorItem>(
    resource: TDisposable,
    func: (resource: TDisposable) => IterableIterator<UIteratorItem>
  ): IterableIterator<UIteratorItem>;
  export function using<TDisposable extends AsyncUsingObject, TResult = void>(
    resource: TDisposable,
    func: (resource: TDisposable) => TResult
  ): Promise<TResult>;
  export function using<TDisposable extends UsingObject, TResult = void>(
    resource: TDisposable,
    func: (resource: TDisposable) => TResult
  ): TResult;
  export function using<TDisposable extends UsingObject | AsyncUsingObject, TIteratorItem, TResult>(
    resource: TDisposable,
    func: (resource: TDisposable) => TResult | Promise<TResult> | IterableIterator<TIteratorItem>
  ): TResult | Promise<TResult> | IterableIterator<TIteratorItem> {
    let shouldDispose = true;
    let result: TResult | Promise<TResult> | IterableIterator<TIteratorItem> | undefined = undefined;
    try {
      result = func(resource);

      // dispose it asynchronously if it returns a promise
      if (isPromise<TResult>(result)) {
        const capturedResult = result;
        shouldDispose = false;
        return result.finally(() => dispose(resource)).then(() => capturedResult);
      } else if (isIterator(result)) {
        shouldDispose = false;
        const originalNext = result.next!;
        result.next = function () {
          let shouldDispose = false;
          try {
            const args = Array.from(arguments);
            const iterationResult = originalNext.apply(this, args as any);
            if (iterationResult.done)
              shouldDispose = true;
            return iterationResult;
          } catch (err) {
            shouldDispose = true;
            throw err;
          } finally {
            if (shouldDispose)
              dispose(resource);
          }
        };
      }
    } finally {
      if (shouldDispose) {
        const disposeResult = dispose(resource);
        if (isPromise<TResult>(disposeResult)) {
          let finalPromise = result == null ? undefined : Promise.resolve(result as TResult);
          if (finalPromise == null)
            result = disposeResult;
          else
            result = disposeResult.then(() => finalPromise!);
        }
      }
    }

    return result!;
  }

  const funcNames = ["dispose", "close", "unsubscribe"];

  function dispose(obj: UsingObject | undefined): void | Promise<void> {
    if (obj == null)
      return;

    for (const funcName of funcNames) {
      if (typeof (obj as any)[funcName] === "function")
        return (obj as any)[funcName]();
    }

    throw new Error("Object provided to using did not have a dispose method.");
  }

  function isPromise<TResult>(obj: unknown): obj is Promise<TResult> {
    return obj != null
      && typeof (obj as any).then === "function"
      && typeof (obj as any).finally === "function";
  }

  function isIterator(obj: unknown): obj is Iterator<unknown> {
    return obj != null
      && typeof (obj as any).next === "function";
  }
}
