import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {RecordWindow} from "../../../framework/functionwindow/record-window";
import {NgForm} from "@angular/forms";
import {
  ReturnCodesSave,
  UserManagerClient,
  UserManagerRoles,
  UserManagerRolesPermission,
  UserPermission,
  UserRoles,
  Users
} from "../../../webapi/webapi.service";
import {ActivatedRoute, Router} from "@angular/router";
import {WaitCursor, WaitCursorService} from "../../../framework/wait-cursor/wait-cursor.service";
import {UserManagerService} from "../../usermanager.service";
import {Using} from "../../../framework/using/using";
import {InteractionController} from "../../../framework/interaction-controller/interaction.controller";
import {nameof} from "../../../framework/tools/nameof";
import {RoleDefinition} from "../user-record-window/user-record-window.component";

@Component({
  selector: 'app-role-record-window',
  templateUrl: './role-record-window.component.html',
  host: {class: 'container-fluid d-flex h-100 flex-column p-0 bg-detailtoolbar'}
})
export class RoleRecordWindowComponent extends RecordWindow implements OnInit, OnDestroy {
  @ViewChild('dialogForm') dialogForm: NgForm;
  role: UserManagerRoles = new UserManagerRoles();
  userPermissions: UserPermission[];
  roleId: number = 0;

  constructor(protected router: Router,
              protected activatedRoute: ActivatedRoute,
              public waitCursorService: WaitCursorService,
              public userManagerService: UserManagerService,
              private  userManagerClient: UserManagerClient) {
    super();

    if (this.activatedRoute.snapshot.params.id !== undefined)
      this.roleId = this.activatedRoute.snapshot.params.id;

    // prepare permissions array
    this.userPermissions = [];
    for (let perm of userManagerService.permissions) {
      let userPermission: UserPermission = new UserPermission();
      userPermission.permissionKey = perm.permissionKey;
      userPermission.permission = false;
      this.userPermissions.push(userPermission);
    }
  }

  async ngOnInit() {
    try {
      await Using.using(new WaitCursor(this.waitCursorService), async () => {
        super.ngOnInit();

        // create or load user
        if (this.roleId == 0) {
          this.role = new UserManagerRoles();
          this.role.roleId = 0;
        } else {
          let role = await this.userManagerClient.getRole(this.roleId).toPromise();
          if (role != null) {
            this.role = role;
            // permissions
            for (let perm of this.role.userManagerRolesPermission) {
              let userPermission = this.userPermissions.find(x => x.permissionKey == perm.permissionKey);
              if (userPermission !== undefined)
                userPermission.permission = perm.permission;
            }
          } else {
            await InteractionController.MessageBox.showWarning("Keine Rollendaten", "Rollendaten konnten nicht abgerufen werden. Möglicherweise existiert die Rolle nicht.");
            this.router.navigate(['.'], {relativeTo: this.activatedRoute.parent});
          }
        }
      });
    } catch (e) {
      await InteractionController.MessageBox.showApplicationException(e);
      this.router.navigate(['.'], {relativeTo: this.activatedRoute.parent});
    }
  }

  async save() {
    try {
      await Using.using(new WaitCursor(this.waitCursorService), async () => {
        if (this.dialogForm.valid) {
          let roleId: number = 0;
          if (this.dialogForm.dirty) {
            // apply user permissions
            this.role.userManagerRolesPermission = [];
            for (let perm of this.userPermissions) {
              if (perm.permission) {
                let userPermission = new UserManagerRolesPermission();
                userPermission.permission = perm.permission;
                userPermission.permissionKey = perm.permissionKey;
                userPermission.roleId = this.role.roleId;

                this.role.userManagerRolesPermission.push(userPermission);
              }
            }

            // save
            let retValue = await this.userManagerClient.saveRole(this.role).toPromise();
            roleId = retValue.id;

            // check return value
            if (retValue.returnCode == ReturnCodesSave.Duplicate) {
              InteractionController.MessageBox.showWarning("Rollenname", "Der eingegebene Rollenname existiert bereits.");
              this.dialogForm.form.controls[`${nameof<RoleRecordWindowComponent>(u => u.role)}.${nameof<UserManagerRoles>(c => c.roleName)}`].setErrors({});
              this.dialogForm.form.controls[`${nameof<RoleRecordWindowComponent>(u => u.role)}.${nameof<UserManagerRoles>(c => c.roleName)}`].markAsTouched();
              return;
            }
          }

          this.router.navigate(['.'], {relativeTo: this.activatedRoute.parent, state: {id: roleId}});
        } else {
          this.dialogForm.form.controls['role.roleName'].markAsTouched();
          InteractionController.MessageBox.showWarning("Validierungsfehler",
            "Ein oder mehrere Validierungsfehler sind aufgetreten. Die fehlerhaften Eingaben sind rot markiert. Bitte korrigieren Sie Ihre Eingaben und versuchen Sie erneut den Datensatz zu speichern.");
        }
      });
    } catch (e) {
      InteractionController.MessageBox.showApplicationException(e);
    }
  }

  cancel() {
    this.router.navigate(['.'], {relativeTo: this.activatedRoute.parent});
  }

  selectAllRigths(select: boolean) {
    for (let perm of this.userPermissions) {
      perm.permission = select;
    }
    this.dialogForm.form.markAsDirty();
  }
}
