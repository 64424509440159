import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptor} from './auth.interceptor';
import {HttpClientModule} from '@angular/common/http';
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {UserFunctionWindowComponent} from './user-management/user-function-window/user-function-window.component';
import {DxDataGridModule} from "devextreme-angular";
import {FunctionModuleBase} from "../framework/functionmodule/function-module-base";
import {UserFunctionModule} from "./user-management/functionmodules/user-function-module";
import {UserRecordWindowComponent} from './user-management/user-record-window/user-record-window.component';
import {RoleFunctionWindowComponent} from './user-management/role-function-window/role-function-window.component';
import {RoleRecordWindowComponent} from './user-management/role-record-window/role-record-window.component';
import {RoleFunctionModule} from "./user-management/functionmodules/role-function-module";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {RouterModule} from "@angular/router";

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbTooltipModule,
    DxDataGridModule,
    RouterModule
  ],
  declarations: [
    UserFunctionWindowComponent,
    UserRecordWindowComponent,
    RoleFunctionWindowComponent,
    RoleRecordWindowComponent
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: FunctionModuleBase, useClass: UserFunctionModule, multi: true},
    {provide: FunctionModuleBase, useClass: RoleFunctionModule, multi: true}
  ]
})
export class UserManagerModule {
}
