import {isPlatformBrowser} from "@angular/common";

interface GridSettings {
  settingsName: string;
  columnSettings: GridColumnSettings[];
  isFilterEnabled;
  filterExpression;
}

interface GridColumnSettings {
  columnName;
  sortDirection;
  sortIndex;
  groupIndex;
  width;
  visibleIndex;
  isVisible;
  filterString;
  filterType;
  filterOperation
}

export class DxGridSettings {

  public static saveDataGridSettings(state, gridSettingsName, platformId, withFilter = true) {
    if (isPlatformBrowser(platformId)) {
      let data: GridSettings;
      let dataColumn: Array<GridColumnSettings> = new Array<GridColumnSettings>();

      if (state.columns !== undefined) {
        for (let column of state.columns) {
          if (withFilter) {
            dataColumn.push(
              {
                columnName: column.dataField,
                sortDirection: column.sortOrder,
                sortIndex: column.sortIndex,
                groupIndex: column.groupIndex,
                width: column.width,
                visibleIndex: column.visibleIndex,
                isVisible: column.visible,
                filterString: (withFilter ? column.filterValue : undefined),
                filterType: (withFilter ? column.filterType : undefined),
                filterOperation: (withFilter ? column.selectedFilterOperation : undefined)
              }
            );
          }
        }
      }

      data = {
        settingsName: gridSettingsName,
        columnSettings: dataColumn,
        isFilterEnabled: state.filterPanel !== undefined ? state.filterPanel.filterEnabled : undefined,
        filterExpression: state.filterValue
      };

      // dont save in case of error
      if (dataColumn.length > 0)
        localStorage.setItem(gridSettingsName, JSON.stringify(data));
    }
  }

  public static loadDataGridSettings(datagrid, gridSettingsName, platformId) {
    let state: any = {};

    if (isPlatformBrowser(platformId)) {
      let data: GridSettings = JSON.parse(localStorage.getItem(gridSettingsName));

      if (data != null) {
        state.columns = [];
        if (data.columnSettings !== undefined && datagrid.columns !== undefined) {
          for (let columnSetting of data.columnSettings) {
            if (datagrid.columns.find(c => c.dataField == columnSetting.columnName) !== undefined) {
              let column: any = {};
              column.dataField = columnSetting.columnName;
              if (columnSetting.sortDirection !== undefined)
                column.sortOrder = columnSetting.sortDirection;
              if (columnSetting.sortIndex !== undefined)
                column.sortIndex = columnSetting.sortIndex;
              if (columnSetting.groupIndex !== undefined)
                column.groupIndex = columnSetting.groupIndex;
              if (columnSetting.width !== undefined)
                column.width = columnSetting.width;
              if (columnSetting.visibleIndex !== undefined)
                column.visibleIndex = columnSetting.visibleIndex;
              if (columnSetting.isVisible !== undefined)
                column.visible = columnSetting.isVisible;
              if (columnSetting.filterType !== undefined)
                column.filterType = columnSetting.filterType;
              if (columnSetting.filterOperation !== undefined)
                column.selectedFilterOperation = columnSetting.filterOperation;
              if (columnSetting.filterString !== undefined)
                column.filterValue = columnSetting.filterString;

              state.columns.push(column);
            }
          }
        }

        if (data.isFilterEnabled !== undefined) {
          state.filterPanel = {};
          state.filterPanel.filterEnabled = data.isFilterEnabled;
        }

        if (data.filterExpression !== undefined)
          state.filterValue = data.filterExpression;

      }
    }

    return state;
  }
}
