import {Injectable} from "@angular/core";
import {FunctionModuleBase} from "../../../framework/functionmodule/function-module-base";
import {UserManagerService} from "../../../usermanager/usermanager.service";
import {Router} from "@angular/router";
import {GesamtsichtUebersichtComponent} from "../gesamt/gesamtsicht-uebersicht/gesamtsicht-uebersicht.component";
import {GesamtsichtKonditionComponent} from "../gesamt/gesamtsicht-kondition/gesamtsicht-kondition.component";
import {UserPermissionSuffix} from "../../../usermanager/model/user-permission-suffix";
import {ModuleRoute} from "../../../framework/functionmodule/module-route";

@Injectable({
  providedIn: 'root'
})
export class GesamtansichtFunctionModule extends FunctionModuleBase {
  contractName = "Gesamtansicht";
  functionName = "Gesamtansicht";
  groupName = "Funktionen";
  routerPath = "gesamtansicht";
  component = GesamtsichtUebersichtComponent;
  homeTileClass = "tileGroupClass1";
  homeTileImageSource = './assets/images/Gesamtansicht.svg';
  homeTileSubTitle = "Gesamtansicht";

  constructor(protected userManagerService: UserManagerService, protected router: Router) {
    super(userManagerService, router);
    this.configureFunctionModule();
  }

  configureModuleRoutes() {
    super.configureModuleRoutes();
    this.moduleRoutes.push(
      new ModuleRoute('kondition/:id', 'Nachweis', GesamtsichtKonditionComponent, `${this.contractName}${UserPermissionSuffix.ReadPermission}`)
    );
  }
}
