import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from "@angular/forms";
import {DevExtremeModule, DxDataGridModule} from "devextreme-angular";
import {RouterModule} from "@angular/router";
import {AngularSplitModule} from "../../framework/splitter/module";
import {FunctionModuleBase} from "../../framework/functionmodule/function-module-base";
import {GesamtansichtFunctionModule} from "./functionsmodules/gesamtansicht-function-module";
import {LieferantsichtFunctionModule} from "./functionsmodules/lieferantsicht-function-module.service";
import {PartnersichtFunctionModule} from "./functionsmodules/partnersicht-function-module.service";
import {LieferantensichtUebersichtComponent} from "./lieferanten/lieferantensicht-uebersicht/lieferantensicht-uebersicht.component";
import {PartnersichtPartnerUebersichtComponent} from "./partner/partnersicht-partner-uebersicht/partnersicht-partner-uebersicht.component";
import {LieferantensichtPartnerComponent} from "./lieferanten/lieferantensicht-partner/lieferantensicht-partner.component";
import {LieferantensichtNachweisComponent} from "./lieferanten/lieferantensicht-nachweis/lieferantensicht-nachweis.component";
import {PartnersichtKonditionComponent} from "./partner/partnersicht-kondition/partnersicht-kondition.component";
import {PartnersichtChangeStatusComponent} from "./partner/partnersicht-change-status/partnersicht-change-status.component";
import {PartnersichtGegenleistungComponent} from "./partner/partnersicht-gegenleistung/partnersicht-gegenleistung.component";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {PartnersichtGegenleistungUebersichtComponent} from "./partner/partnersicht-gegenleistung-uebersicht/partnersicht-gegenleistung-uebersicht.component";
import {PartnersichtNachweisDialogComponent} from "./partner/partnersicht-nachweis-dialog/partnersicht-nachweis-dialog.component";
import {PartnersichtNachweisDeleteDialogComponent} from "./partner/partnersicht-nachweis-delete-dialog/partnersicht-nachweis-delete-dialog.component";
import {GesamtsichtUebersichtComponent} from "./gesamt/gesamtsicht-uebersicht/gesamtsicht-uebersicht.component";
import {GesamtsichtKonditionComponent} from "./gesamt/gesamtsicht-kondition/gesamtsicht-kondition.component";
import {NachweiseFunctionModule} from "./functionsmodules/nachweise-function-module";
import {NachweiseUebersichtComponent} from "./nachweise/nachweise-uebersicht.component";
import {Lieferantsicht_kompaktFunctionModule} from "./functionsmodules/kompakt-lieferantsicht-function-module";


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DxDataGridModule,
    RouterModule,
    AngularSplitModule,
    DevExtremeModule,
    NgbModule
  ],
  declarations: [
    LieferantensichtUebersichtComponent,
    LieferantensichtPartnerComponent,
    LieferantensichtNachweisComponent,
    PartnersichtPartnerUebersichtComponent,
    PartnersichtKonditionComponent,
    PartnersichtChangeStatusComponent,
    PartnersichtGegenleistungComponent,
    PartnersichtGegenleistungUebersichtComponent,
    PartnersichtNachweisDialogComponent,
    PartnersichtNachweisDeleteDialogComponent,
    GesamtsichtUebersichtComponent,
    GesamtsichtKonditionComponent,
    NachweiseUebersichtComponent
  ],
  providers: [
    {provide: FunctionModuleBase, useClass: LieferantsichtFunctionModule, multi: true},
    {provide: FunctionModuleBase, useClass: PartnersichtFunctionModule, multi: true},
    {provide: FunctionModuleBase, useClass: GesamtansichtFunctionModule, multi: true},
    {provide: FunctionModuleBase, useClass: NachweiseFunctionModule, multi: true},
    {provide: FunctionModuleBase, useClass: Lieferantsicht_kompaktFunctionModule, multi: true}
  ]
})
export class FunktionModule {
}
