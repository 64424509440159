<wait-cursor></wait-cursor>
<div class="container-fluid d-flex h-100 flex-column bg-mainwindow p-0">

  <app-nav-menu></app-nav-menu>
  <app-breadcrumb class="breadcrumb-marging-ie"></app-breadcrumb>

  <div class="d-flex flex-column flex-grow-1 h-100 bg-white" style="overflow-y:auto;overflow-x:hidden"
       [@routeAnimation]="getAnimationData(routerOutlet)">
    <router-outlet #routerOutlet="outlet"></router-outlet>
  </div>
</div>
