import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {FunctionWindow} from "../../../../framework/functionwindow/function-window";
import {UserManagerService} from "../../../../usermanager/usermanager.service";
import {ActivatedRoute, Router} from "@angular/router";
import {WaitCursor, WaitCursorService} from "../../../../framework/wait-cursor/wait-cursor.service";
import {DxDataGridComponent} from "devextreme-angular";
import {Using} from "../../../../framework/using/using";
import {InteractionController} from "../../../../framework/interaction-controller/interaction.controller";
import {FileData, GesamtsichtClient} from "../../../../webapi/webapi.service";
import {FileTool} from "../../../../framework/tools/FileTool";
import {GesamtansichtFunctionModule} from "../../functionsmodules/gesamtansicht-function-module";

@Component({
  selector: 'app-gesamtsicht-kondition',
  templateUrl: './gesamtsicht-kondition.component.html',
  host: {class: 'container-fluid d-flex h-100 flex-column p-0 bg-detailtoolbar'}
})
export class GesamtsichtKonditionComponent extends FunctionWindow implements OnInit, OnDestroy {
  @ViewChild('grid') dataGrid: DxDataGridComponent;
  gridKey: string[] = ["id"];
  loadParams;
  partnerKonditionId: number;
  rootObject: any = {};

  get hasWritePermission(): boolean {
    return this.userManagerService.hasWritePermission(this.functionModule.contractName);
  }

  get isModuleUrl(): boolean {
    return true;
  }

  constructor(@Inject('BASE_URL') baseUrl: string,
              @Inject(PLATFORM_ID) protected platformId: any,
              protected userManagerService: UserManagerService,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected waitCursorService: WaitCursorService,
              protected functionModule: GesamtansichtFunctionModule,
              private client: GesamtsichtClient) {
    super(platformId, userManagerService, router, waitCursorService, functionModule);

    if (this.activatedRoute.snapshot.params.id !== undefined) {
      this.partnerKonditionId = this.activatedRoute.snapshot.params.id;
    }

    if (window.history.state.rootObjectOffer !== undefined) {
      this.rootObject = window.history.state.rootObjectOffer;
    }

    this.initDetailPanelSettings();
    this.setDetailPanelValues();

    this.loadParams = {partnerKonditionId: this.partnerKonditionId};
    this.loadUrl = baseUrl + 'api/gesamtsicht/querynachweisdetailbyid';
    this.createDataSource(this.loadParams);
  }

  async ngOnInit() {
    try {
      await Using.using(new WaitCursor(this.waitCursorService), async () => {
        super.ngOnInit();

        if ((Object.keys(this.rootObject).length === 0)) {
          let offer = await this.client.getKonditionData(this.partnerKonditionId).toPromise();
          if (offer != null) {
            this.rootObject = offer;
          } else {
            await InteractionController.MessageBox.showWarning("Keine Konditionsdaten", "Konditionsdaten konnten nicht abgerufen werden. Möglicherweise existiert die Kondition nicht.");
            this.router.navigate(['.'], {relativeTo: this.activatedRoute.parent});
          }
        }
      });
    } catch (e) {
      await InteractionController.MessageBox.showApplicationException(e);
      this.router.navigate(['.'], {relativeTo: this.activatedRoute.parent});
    }
  }

  statusLookupData = {
    store: {
      type: 'array',
      data: [
        {monat: 1, name: 'Januar'},
        {monat: 2, name: 'Februar'},
        {monat: 3, name: 'März'},
        {monat: 4, name: 'April'},
        {monat: 5, name: 'Mai'},
        {monat: 6, name: 'Juni'},
        {monat: 7, name: 'Juli'},
        {monat: 8, name: 'August'},
        {monat: 9, name: 'September'},
        {monat: 10, name: 'Oktober'},
        {monat: 11, name: 'November'},
        {monat: 12, name: 'Dezember'}
      ],
      key: "monat"
    }
  };

  refresh() {
    super.refresh();
  }

  back() {
    this.router.navigate([`.`], {
      relativeTo: this.activatedRoute.parent,
      state: {id: this.partnerKonditionId}
    });
  }

  public resolveSettingsName() {
    return super.resolveSettingsName() + "_Nachweis";
  }

  async navigateToDatei(rowData: any) {
    let entry:FileData = null;
    try {
      await Using.using(new WaitCursor(this.waitCursorService), async () => {
        entry = await this.client.getFile(rowData.datei.id).toPromise();
      });
    } catch (e) {
      console.log(e);
      await InteractionController.MessageBox.showWarning("Fehler beim Abrufen der Datei", "Die gewählte Datei konnte nicht abgerufen werden. Möglicherweise existiert die Datei nicht.");
    }
    if(entry != null){
      try {
        FileTool.download(entry.file, entry.type, entry.fileName);
      } catch (e) {
        console.log(e);
        await InteractionController.MessageBox.showWarning("Fehler beim Öffnen der Datei", "Die gewählte Datei konnte nicht geöffnet werden.");
      }
    }
  }
}
