import { Router, RouterStateSnapshot, ActivatedRouteSnapshot, NavigationExtras } from '@angular/router';
import {UserManagerService} from './usermanager.service';
import {Injectable, Injector} from '@angular/core';
import {Observable, from} from 'rxjs';
import {FunctionModuleBase} from "../framework/functionmodule/function-module-base";
import {TokenService} from "../authentication/token.service";

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {

  constructor(
    public userManagerService: UserManagerService,
    public router: Router,
    private injector: Injector,
    private tokenService: TokenService
    ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return from(this.handleCanActivate(route, state, null));
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return from(this.handleCanActivate(null, state, childRoute));
  }

  async handleCanActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot, childRoute: ActivatedRouteSnapshot) {
    if (!await this.userManagerService.isUserLoggedIn) {
      this.navigateToLogin(state);
      return false;
    } else {
      // generate module url
      let url: string = state.url;

      if (childRoute != null) {
        if (childRoute.pathFromRoot.length >= 2)
          url = "/" + childRoute.pathFromRoot[1].url.toString();
      }

      // check module permissions on the client side
      let functionModules: FunctionModuleBase[] = this.injector.get(FunctionModuleBase) as any;
      for (let module of functionModules) {
        if (url == `/${module.routerPath}`) {
          if (module.hasUserRoutePermission(url)) {
            if (childRoute != null) {
              // ask module if child route is allowed
              if (module.hasUserRoutePermission(childRoute.url.join('/')))
                return true;
              else {
                this.router.navigate(['/']);
                return false;
              }
            }

            return true;
          } else {
            this.router.navigate(['/']);
            return false;
          }
        }
      }
    }
    return true;
  }

  navigateToLogin(state: RouterStateSnapshot) {
    this.userManagerService.login();
  }
}
