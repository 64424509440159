import {Injectable} from "@angular/core";
import {FunctionModuleBase} from "../../../framework/functionmodule/function-module-base";
import {UserManagerService} from "../../../usermanager/usermanager.service";
import {Router} from "@angular/router";
import {NachweiseUebersichtComponent} from "../nachweise/nachweise-uebersicht.component";

@Injectable({
  providedIn: 'root'
})
export class NachweiseFunctionModule extends FunctionModuleBase {
  contractName = "Nachweise";
  functionName = "Nachweise";
  groupName = "Funktionen";
  routerPath = "nachweise";
  component = NachweiseUebersichtComponent;
  homeTileClass = "tileGroupClass1";
  homeTileImageSource = './assets/images/Gesamtansicht.svg';
  homeTileSubTitle = "Nachweise Übersicht";

  constructor(protected userManagerService: UserManagerService, protected router: Router) {
    super(userManagerService, router);
    this.configureFunctionModule();
  }

  configureModuleRoutes() {
    super.configureModuleRoutes();
  }
}
