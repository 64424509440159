import {Component, Inject, OnInit, ViewChild, PLATFORM_ID} from '@angular/core';
import {DialogBase} from "../../../../../framework/dialog/dialog.base";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {WaitCursor, WaitCursorService} from "../../../../../framework/wait-cursor/wait-cursor.service";
import {UserManagerService} from "../../../../../usermanager/usermanager.service";
import {NgForm} from "@angular/forms";
import {Using} from "../../../../../framework/using/using";
import {TaskDialogButtons} from "../../../../../framework/message-box/taskdialog/task-dialog-buttons";
import {InteractionController} from "../../../../../framework/interaction-controller/interaction.controller";
import {Partner, PartnerkonditionClient} from "../../../../../webapi/webapi.service";
import {GridHelper} from "../../../../../framework/grid/gridhelper";
import {YearselectionService} from "../../../../../shared/services/yearselection.service";
import {DxDataGridComponent} from "devextreme-angular";
import {Router} from "@angular/router";
import DevExpress from "devextreme";
import {DxGridSettings} from "../../../../../framework/grid/dx-grid-settings";

export class PartnerkonditionenDialogParameter {
  partnerId: number;
}

@Component({
  selector: 'app-partnerkonditionen-dialog',
  templateUrl: './partnerkonditionen-dialog.component.html'
})
export class PartnerkonditionenDialogComponent extends DialogBase implements OnInit {
  @ViewChild('dialogForm') dialogForm: NgForm;
  @ViewChild('grid') dataGrid: DxDataGridComponent;
  gridKey: string[] = ["id", "gegenleistungsart", "zeitraum", "ksnr", "wkz"];
  title: string = 'Lieferantenkonditionen';
  loadUrl: string;
  loadParams;
  dataSource: DevExpress.data.CustomStore;
  filterOperations: any = [ "contains", "=", "<>", "<", ">", "<=", ">=", "between" ];

  constructor(@Inject('BASE_URL') baseUrl: string,
              @Inject(PLATFORM_ID) protected platformId: any,
              public activeModal: NgbActiveModal,
              public waitCursorService: WaitCursorService,
              private _userManagerService: UserManagerService,
              private yearselectionService: YearselectionService,
              protected userManagerService: UserManagerService,
              protected router: Router,
              private dialogParameter: PartnerkonditionenDialogParameter, private client: PartnerkonditionClient) {
    super(activeModal, waitCursorService);
    this.loadUrl = baseUrl + 'api/partnerkondition/queryalllieferantenkonditionen';
  }

  async ngOnInit() {
    this.loadParams = {year: this.yearselectionService.year, partnerId: this.dialogParameter.partnerId};
    this.dataSource = GridHelper.createGridDataSource(this.gridKey, this.loadUrl, this.router, this.userManagerService, null, this.loadParams);
    this.refresh();
  }

  refresh() {
    if (this.dataGrid !== undefined)
      this.dataGrid.instance.refresh();
  }

  public async onOK() {
    try {
      await Using.using(new WaitCursor(this.waitCursorService), async () => {
        if (this.dataGrid.selectedRowKeys.length > 0) {
          let konditionIdList: number[] = [];
          let selectedRowsData = this.dataGrid.instance.getSelectedRowsData();
          for (let i = 0; i < selectedRowsData.length; i++) {
            konditionIdList.push(selectedRowsData[i].lieferantenkonditionId);
          }
          await this.client.save(this.dialogParameter.partnerId, konditionIdList).toPromise();

          this.close();
          this.onButtonPressed.emit(TaskDialogButtons.OK);
        } else {
          InteractionController.MessageBox.showWarning("Keine Auswahl", "Bitte selektieren Sie einen Eintrag aus der Liste und führen Sie die Operation erneut aus.");
        }

      });
    } catch (e) {
      InteractionController.MessageBox.showApplicationException(e);
    }
  }

  loadState = () => {
    return DxGridSettings.loadDataGridSettings(this.dataGrid, "PartnerkonditionenDialog", this.platformId);
  };

  saveState = (state) => {
    DxGridSettings.saveDataGridSettings(state, "PartnerkonditionenDialog", this.platformId);
  }
}
