import {Injectable} from "@angular/core";
import {FunctionModuleBase} from "../../../framework/functionmodule/function-module-base";
import {UserManagerService} from "../../../usermanager/usermanager.service";
import {Router} from "@angular/router";
import {LieferantensichtUebersichtComponent} from "../lieferanten/lieferantensicht-uebersicht/lieferantensicht-uebersicht.component";
import {ModuleRoute} from "../../../framework/functionmodule/module-route";
import {PartnerkonditionenComponent} from "../../stammdaten/konditionen/partner/partnerkonditionen/partnerkonditionen.component";
import {UserPermissionSuffix} from "../../../usermanager/model/user-permission-suffix";
import {LieferantensichtPartnerComponent} from "../lieferanten/lieferantensicht-partner/lieferantensicht-partner.component";
import {LieferantensichtNachweisComponent} from "../lieferanten/lieferantensicht-nachweis/lieferantensicht-nachweis.component";
import {PartnersichtKonditionComponent} from "../partner/partnersicht-kondition/partnersicht-kondition.component";
import {PartnersichtGegenleistungComponent} from "../partner/partnersicht-gegenleistung/partnersicht-gegenleistung.component";
import {PartnersichtGegenleistungUebersichtComponent} from "../partner/partnersicht-gegenleistung-uebersicht/partnersicht-gegenleistung-uebersicht.component";

@Injectable({
  providedIn: 'root'
})
export class LieferantsichtFunctionModule extends FunctionModuleBase {
  contractName = "Lieferantensicht";
  functionName = "Lieferantensicht";
  groupName = "Funktionen";
  routerPath = "lieferantensicht";
  component = LieferantensichtUebersichtComponent;
  homeTileClass = "tileGroupClass1";
  homeTileImageSource = './assets/images/Liste.svg';
  homeTileSubTitle = "Lieferantensicht";

  constructor(protected userManagerService: UserManagerService, protected router: Router) {
    super(userManagerService, router);
    this.configureFunctionModule();
  }

  configureModuleRoutes() {
    super.configureModuleRoutes();
    let offerRoute = new ModuleRoute('lieferant/:id', 'Konditionen', LieferantensichtPartnerComponent, `${this.contractName}${UserPermissionSuffix.ReadPermission}`);
    offerRoute.moduleRoutes.push(new ModuleRoute('nachweis/:nachweisid', 'Nachweis', LieferantensichtNachweisComponent, `${this.contractName}${UserPermissionSuffix.ReadPermission}`));

    this.moduleRoutes.push(
      offerRoute
    );

    // this.moduleRoutes.push(
    //   new ModuleRoute('lieferant/:id', 'Konditionen', LieferantensichtPartnerComponent, `${this.contractName}${UserPermissionSuffix.ReadPermission}`),
    //   new ModuleRoute('lieferant/:id/nachweis/:nachweisid', 'Nachweis', LieferantensichtNachweisComponent, `${this.contractName}${UserPermissionSuffix.ReadPermission}`)
    // );
  }
}
