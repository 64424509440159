import {Injectable} from "@angular/core";
import {FunctionModuleBase} from "../../../framework/functionmodule/function-module-base";
import {UserManagerService} from "../../../usermanager/usermanager.service";
import {Router} from "@angular/router";
import {ZeitraumdatenComponent} from "../zeitraum/zeitraumdaten/zeitraumdaten.component";

@Injectable({
  providedIn: 'root'
})
export class ZeitraumdatenFunctionModule extends FunctionModuleBase {
  contractName = "Zeitraumdaten";
  functionName = "Zeiträume";
  groupName = "Stammdaten";
  routerPath = "zeitraum";
  component = ZeitraumdatenComponent;
  homeTileClass = "tileGroupClass3";
  homeTileImageSource = './assets/images/Zeitraum.svg';
  homeTileSubTitle = "Zeitraumübersicht";

  constructor(protected userManagerService: UserManagerService, protected router: Router) {
    super(userManagerService, router);
    this.configureFunctionModule();
  }

  configureModuleRoutes() {
    super.configureModuleRoutes();
  }
}
