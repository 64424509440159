<div class="modal-header">
  <div class="modal-title" id="modal-basic-title">{{title}}</div>
  <button type="button" class="btn-close" aria-label="Close" (click)="onClose()">

  </button>
</div>

<form #dialogForm="ngForm" (ngSubmit)="onOK()">
  <div class="modal-body">
    <div class="modal-center">
      <div class="form-group">
        <div style="min-height: 515px; height:515px; max-height: 515px;">
          <dx-data-grid #grid class="d-flex flex-column h-100 vh-100-safari w-100 mw-100" style="height: 100px"
                        [dataSource]="dataSource"
                        [allowColumnReordering]="true"
                        [allowColumnResizing]="true"
                        columnResizingMode="widget"
                        [showBorders]="true"
                        [showColumnLines]="true"
                        [showRowLines]="true"
                        [remoteOperations]="true"
                        [wordWrapEnabled]="true"
                        [hoverStateEnabled]="true"
                        [errorRowEnabled]="true"
          >
            <dxo-sorting mode="multiple"></dxo-sorting>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-filter-panel [visible]="true"></dxo-filter-panel>
            <dxo-filter-builder-popup></dxo-filter-builder-popup>
            <dxo-header-filter [visible]="true"></dxo-header-filter>
            <dxo-group-panel [visible]="false"></dxo-group-panel>
            <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
            <dxo-selection mode="multiple"></dxo-selection>
            <dxo-grouping #expand [autoExpandAll]="false"></dxo-grouping>
            <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
            <dxo-load-panel [showPane]="false"></dxo-load-panel>

            <dxi-column dataField="id" caption="Jahr Id" alignment="left" minWidth="100" dataType="number"
                        [visible]="false" [allowFiltering]="false" [allowGrouping]="false"></dxi-column>
            <dxi-column dataField="wawinr" caption="Wawi Nr." alignment="left" minWidth="50"
                        dataType="string"></dxi-column>
            <dxi-column dataField="vlnr" caption="VL-Nr." alignment="left" minWidth="50"
                        dataType="number" [filterOperations]="filterOperations"></dxi-column>
            <dxi-column dataField="name" caption="Name" alignment="left" minWidth="100"
                        dataType="string"></dxi-column>
            <dxi-column dataField="gegenleistungsart" caption="Gegenleistungsart" alignment="left" minWidth="100"
                        dataType="string"></dxi-column>
            <dxi-column dataField="ksnr" caption="KS-Nr." alignment="left" minWidth="65" width="65" dataType="number" [filterOperations]="filterOperations">
              <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="ksbezeichnung" caption="KS-Bezeichnung" alignment="left" minWidth="100"
                        dataType="string"></dxi-column>
            <dxi-column dataField="anzahlGegenleistungen" caption="Anzahl Gegenleistungen" alignment="left"
                        minWidth="100"
                        dataType="number">
              <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="anzahlArtikel" caption="Anzahl Artikel" alignment="left" minWidth="100"
                        dataType="number">
              <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="summe" caption="Summe" alignment="left" minWidth="100"
                        dataType="number"></dxi-column>
            <dxi-column dataField="wkz" caption="% / Fix - WKZ" alignment="left" minWidth="100"
                        dataType="string"></dxi-column>
            <dxi-column dataField="bemerkungExtern" caption="Bemerkung Extern" alignment="left" minWidth="100"
                        dataType="string"></dxi-column>
            <dxi-column dataField="bemerkungIntern" caption="Bemerkung Intern" alignment="left" minWidth="100"
                        dataType="string"></dxi-column>

            <dxo-summary>
              <dxi-total-item column="status" summaryType="count" alignment="right">
              </dxi-total-item>
            </dxo-summary>
          </dx-data-grid>
        </div>

        <div class="modal-footer">
          <button ngbAutofocus type="submit" class="btn btn-primary btn-sm dialog-button">OK</button>
          <button type="button" class="btn btn-outline-primary btn-sm dialog-button" (click)="onCancel()">Abbrechen
          </button>
        </div>
      </div>
    </div>
  </div>
</form>



