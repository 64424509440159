import {TaskDialogIcons} from './task-dialog-icons';
import {TaskDialogCommonButtons} from './task-dialog-common-buttons';

export class TaskDialogOptions {
  public title: string;
  public mainIcon: TaskDialogIcons;
  public mainInstruction: string;
  public content: string;
  public expandedInfo: string = '';
  public expandedByDefault: boolean;
  public commonButtons: TaskDialogCommonButtons;
  public defaultButton: TaskDialogCommonButtons;

  public static createDefault(): TaskDialogOptions {
    return new TaskDialogOptions();
  }
}
