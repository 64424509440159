import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {FunctionWindow} from "../../../../framework/functionwindow/function-window";
import {UserManagerService} from "../../../../usermanager/usermanager.service";
import {ActivatedRoute, Router} from "@angular/router";
import {WaitCursorService} from "../../../../framework/wait-cursor/wait-cursor.service";
import {DxDataGridComponent} from "devextreme-angular";
import {InteractionController} from "../../../../framework/interaction-controller/interaction.controller";
import {LieferantendatenFunctionModule} from "../../functionmodules/lieferantendaten-function-module";
import {
  LieferantenDialogComponent,
  LieferantenDialogParameter
} from "../lieferanten-dialog/lieferanten-dialog.component";
import {LieferantenClient} from "../../../../webapi/webapi.service";

@Component({
  selector: 'app-lieferanten',
  templateUrl: './lieferantendaten.component.html',
  host: {class: 'container-fluid d-flex h-100 flex-column p-0 bg-detailtoolbar'}
})
export class LieferantendatenComponent extends FunctionWindow implements OnInit, OnDestroy {
  @ViewChild('grid') dataGrid: DxDataGridComponent;
  gridKey: string[] = ["id"];

  get hasWritePermission(): boolean {
    return this.userManagerService.hasWritePermission(this.functionModule.contractName);
  }

  constructor(@Inject('BASE_URL') baseUrl: string,
              @Inject(PLATFORM_ID) protected platformId: any,
              protected userManagerService: UserManagerService,
              private client: LieferantenClient,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected waitCursorService: WaitCursorService,
              protected functionModule: LieferantendatenFunctionModule) {
    super(platformId, userManagerService, router, waitCursorService, functionModule);

    this.loadUrl = baseUrl + 'api/lieferanten/lieferantenlist';
    this.createDataSource();
  }

  async new() {
    if (this.hasWritePermission) {
      let parameter: LieferantenDialogParameter = new LieferantenDialogParameter();
      parameter.lieferantId = 0;
      if (await InteractionController.showDialog(LieferantenDialogComponent, parameter)) {
        this.dataGrid.instance.refresh();
        this.dataGrid.selectedRowKeys = [{id: parameter.lieferantId}];
      }
    }
  }

  async edit() {
    if (this.hasWritePermission) {
      if (this.dataGrid.selectedRowKeys.length > 0) {
        let parameter: LieferantenDialogParameter = new LieferantenDialogParameter();
        parameter.lieferantId = this.dataGrid.selectedRowKeys[0].id;
        if (await InteractionController.showDialog(LieferantenDialogComponent, parameter)) {
          this.dataGrid.instance.refresh();
          this.dataGrid.selectedRowKeys = [{id: parameter.lieferantId}];
        }
      } else
        InteractionController.MessageBox.showWarning("Keine Auswahl", "Bitte selektieren Sie einen Eintrag aus der Liste und führen Sie die Operation erneut aus.");
    }
  }

  async delete() {
    try {
      if (this.dataGrid.selectedRowKeys.length > 0) {
        if (await InteractionController.MessageBox.showInfoConfirmation("Lieferant löschen",
          "Der Lieferant wird unwiderruflich gelöscht. Wollen Sie den ausgewählten Lieferant wirklich löschen?")) {
          let retValue = await this.client.deleteLieferanten(this.dataGrid.selectedRowKeys[0].id).toPromise();
          this.refresh();
        }
      } else {
        InteractionController.MessageBox.showWarning("Keine Auswahl", "Bitte selektieren Sie einen Eintrag aus der Liste und führen Sie die Operation erneut aus.");
      }
    } catch (e) {
      InteractionController.MessageBox.showApplicationException(e);
    }
  }
}
