import {Component, ViewChild} from '@angular/core';
import {DialogBase} from "../../../../framework/dialog/dialog.base";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {WaitCursor, WaitCursorService} from "../../../../framework/wait-cursor/wait-cursor.service";
import {UserManagerService} from "../../../../usermanager/usermanager.service";
import {NgForm} from "@angular/forms";
import {Using} from "../../../../framework/using/using";
import {TaskDialogButtons} from "../../../../framework/message-box/taskdialog/task-dialog-buttons";
import {InteractionController} from "../../../../framework/interaction-controller/interaction.controller";
import {PartnersichtClient} from "../../../../webapi/webapi.service";

export class PartnersichtNachweisDeleteDialogParameter {
  konditionNachweisDateiId: number;
  konditionNachweisDetailId: number;
}

@Component({
  selector: 'app-partnersichtnachweisdelete-dialog',
  templateUrl: './partnersicht-nachweis-delete-dialog.component.html',
  styleUrls: ['./partnersicht-nachweis-delete-dialog.component.scss']
})
export class PartnersichtNachweisDeleteDialogComponent extends DialogBase {
  @ViewChild('dialogForm') dialogForm: NgForm;
  title: string = 'Frage';
  message: string = "Nachweis löschen";
  details: string = "Möchten Sie nur den ausgewählten Nachweis oder alle Nachweise für die ausgewählte Datei löschen?";

  constructor(public activeModal: NgbActiveModal,
              public waitCursorService: WaitCursorService,
              private _userManagerService: UserManagerService,
              private dialogParameter: PartnersichtNachweisDeleteDialogParameter,
              private client: PartnersichtClient) {
    super(activeModal, waitCursorService);
  }

  public async onDelete() {
    try {
      await Using.using(new WaitCursor(this.waitCursorService), async () => {
        await this.client.deleteNachweisdetail(this.dialogParameter.konditionNachweisDetailId).toPromise();
        this.close();
        this.onButtonPressed.emit(TaskDialogButtons.OK);
      });
    } catch (e) {
      InteractionController.MessageBox.showApplicationException(e);
    }
  }

  public async onDeleteAll() {
    try {
      await Using.using(new WaitCursor(this.waitCursorService), async () => {
        await this.client.deleteNachweisDatei(this.dialogParameter.konditionNachweisDateiId).toPromise();
        this.close();
        this.onButtonPressed.emit(TaskDialogButtons.OK);
      });
    } catch (e) {
      InteractionController.MessageBox.showApplicationException(e);
    }
  }
}
