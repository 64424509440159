import {BrowserModule} from '@angular/platform-browser';
import { APP_ID, NgModule } from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';

import {DxDataGridModule} from 'devextreme-angular';

import {AppComponent} from './app.component';
import {NavMenuComponent} from './nav-menu/nav-menu.component';
import {HomeComponent} from './home/home.component';

import {UserManagerModule} from './usermanager/usermanager.module';
import {AppRoutingModule} from './app-routing.module';
import {EventAggregatorModule} from "./framework/event-aggregator/event.aggregator.module";
import {WaitCursorModule} from "./framework/wait-cursor/wait-cursor.module";
import {MessageBoxModule} from "./framework/message-box/message-box.module";
import {BreadcrumbComponent} from "./breadcrumb/breadcrumb.component";
import {StammdatenModule} from "./appmodules/stammdaten/stammdaten.module";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AngularSplitModule} from "./framework/splitter/module";
import {FunktionModule} from "./appmodules/funktion/funktion.module";
import {SharedModule} from "./shared/shared.module";
import {OAuthModule} from 'angular-oauth2-oidc';
import { APP_INITIALIZER } from '@angular/core';
import {ConfigurationService} from "./configuration/configuration.service";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";

import { CacheInterceptor } from './http-interceptors/cacheinterceptor';

 export function initializeApp(appConfig: ConfigurationService) {
   return () => appConfig.load();
 }

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    BreadcrumbComponent,
    HomeComponent
  ],
  imports: [
    OAuthModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    EventAggregatorModule,
    DxDataGridModule,
    WaitCursorModule,
    MessageBoxModule,
    FunktionModule,
    StammdatenModule,
    SharedModule,
    UserManagerModule,
    AppRoutingModule,
    AngularSplitModule,
    NgbTooltipModule
  ],
  providers: [
     { provide: APP_ID, useValue: 'ng-cli-universal' },
     { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [ConfigurationService], multi: true },
     { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
