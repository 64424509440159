import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {FunctionWindow} from "../../../../../framework/functionwindow/function-window";
import {UserManagerService} from "../../../../../usermanager/usermanager.service";
import {ActivatedRoute, Router} from "@angular/router";
import {WaitCursorService} from "../../../../../framework/wait-cursor/wait-cursor.service";
import {DxDataGridComponent} from "devextreme-angular";
import {InteractionController} from "../../../../../framework/interaction-controller/interaction.controller";
import {LieferantendatenFunctionModule} from "../../../functionmodules/lieferantendaten-function-module";

import {LieferantenClient} from "../../../../../webapi/webapi.service";
import {LieferantenkonditionenpflegeFunctionModule} from "../../../functionmodules/lieferantenkonditionenpflege-function-module";
import {ZeitraumChangedEvent} from "../../../../../shared/events/zeitraum-changed-event";
import {YearselectionService} from "../../../../../shared/services/yearselection.service";
import {EventAggregator} from "../../../../../framework/event-aggregator/event.aggregator";

@Component({
  selector: 'app-lieferantenkonditionenpflege-uebersicht',
  templateUrl: './lieferantenkonditionenpflege-uebersicht.component.html',
  host: {class: 'container-fluid d-flex h-100 flex-column p-0 bg-detailtoolbar'}
})
export class LieferantenkonditionenpflegeUebersichtComponent extends FunctionWindow implements OnInit, OnDestroy {
  @ViewChild('grid') dataGrid: DxDataGridComponent;
  gridKey: string[] = ["id"];
  loadParams;

  subscribeLambda = year => {
    this.setYear(year);
  };
  get hasWritePermission(): boolean {
    return this.userManagerService.hasWritePermission(this.functionModule.contractName);
  }

  constructor(@Inject('BASE_URL') baseUrl: string,
              @Inject(PLATFORM_ID) protected platformId: any,
              protected userManagerService: UserManagerService,
              private client: LieferantenClient,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected waitCursorService: WaitCursorService,
              protected functionModule: LieferantenkonditionenpflegeFunctionModule,
              public yearselectionService: YearselectionService,
              private eventAggregator: EventAggregator) {
    super(platformId, userManagerService, router, waitCursorService, functionModule);

    this.eventAggregator.getEvent(ZeitraumChangedEvent).subscribe(this.subscribeLambda);

    this.loadParams = {year: yearselectionService.year};
    this.loadUrl = baseUrl + 'api/lieferantenkonditionOverview/lieferantenkonditionOverviewList';
    this.createDataSource(this.loadParams);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.eventAggregator.getEvent(ZeitraumChangedEvent).unsubscribe(this.subscribeLambda);
  }

  setYear(year: number) {
    this.loadParams = {year: year};
    this.createDataSource(this.loadParams);
  }

  navigateToLieferantenkonditionen(rowData: any) {
    this.router.navigate([`lieferantenkonditionen/${rowData.id}`], {
      relativeTo: this.activatedRoute,
      state: {rootObjectOffer: rowData}
    });
  }
}
