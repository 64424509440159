<div class="detailarea sticky-top" [hidden]="!isModuleUrl">
  <nav class="navbar navbar-expand detailtoolbar">
    <div class="navbar-collapse collapse">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item mr-1">
          <a class="nav-link navlink-hover" (click)="save()">
            <img src="./assets/images/Save.svg"/><span>Speichern</span>
          </a>
        </li>
        <li class="nav-item mr-1">
          <a class="nav-link navlink-hover" (click)="cancel()">
            <img src="./assets/images/Cancel.svg"/><span>Abbrechen</span>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</div>
<div class="d-flex flex-column flex-grow-1 container-fluid detail-area" [hidden]="!isModuleUrl">
  <form #dialogForm="ngForm" class="p-3">
    <div class="row">
      <div class="card col-xl-12 p-0">
        <div class="card-header p-2">Nachweisdaten</div>
        <div class="card-body col-xl-6">
          <div class="form-group row">
            <label for="customFile" class="col-form-label col-sm-3">Nachweisdatei:</label>
            <div class="col p-0">
              <input accept=".pdf, .bmp, .png, .jpg, .gif" type="file" class="form-control custom-file-input"
                     id="customFile" (change)="handleFileInput($event)" required>
            </div>
          </div>
          <div class="form-group row mt-3">
            <label for="monat" class="col-form-label col-sm-3">Monat:</label>
            <div class="col p-0">
              <select class="form-control form-select" id="monat" name="monat" [(ngModel)]="monat">
                <option value="1">Januar</option>
                <option value="2">Februar</option>
                <option value="3">März</option>
                <option value="4">April</option>
                <option value="5">Mai</option>
                <option value="6">Juni</option>
                <option value="7">Juli</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">Oktober</option>
                <option value="11">November</option>
                <option value="12">Dezember</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="card col-xl-12 p-0">
        <div class="card-header p-2">Konditionen</div>
        <div class="card-body">
          <label class="infolabel col-xl-12">Bitte tragen Sie für die ausgewählte Datei hinter dem jeweiligen
            Lieferanten die <b>Anzahl</b> der beworbenen Artikel sowie die <b>Seitennummern</b>, auf denen diese zu
            finden sind, ein.<br>
            Bitte trennen Sie die Seitennummern mit einem Semikolon und verwenden Sie dabei keine Leerzeichen. Beispiel: 3;5;10 </label>
          <dx-data-grid #grid class="mt-3" style="height: 100px"
                        [dataSource]="dataSourceList"
                        [allowColumnReordering]="true"
                        [allowColumnResizing]="true"
                        columnResizingMode="widget"
                        [showBorders]="true"
                        [showColumnLines]="true"
                        [showRowLines]="true"
                        [remoteOperations]="false"
                        [wordWrapEnabled]="true"
                        [hoverStateEnabled]="true"
                        [errorRowEnabled]="true"
                        (onRowValidating)="onRowValidating($event)"
                        height="565px"
                        (onToolbarPreparing)="onGridToolbarPreparing($event)"
                        (onCellPrepared)="onCellPrepared($event)"
          >
            <dxo-editing mode="batch"
                         [allowUpdating]="(yearselectionService.zeitraumActive || userManagerService.isUserAdmin())"
                         selectTextOnEditStart="true"
                         startEditAction="click">
            </dxo-editing>
            <dxo-sorting mode="multiple"></dxo-sorting>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-filter-panel [visible]="true"></dxo-filter-panel>
            <dxo-filter-builder-popup></dxo-filter-builder-popup>
            <dxo-header-filter [visible]="true"></dxo-header-filter>
            <dxo-group-panel [visible]="false"></dxo-group-panel>
            <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
            <dxo-selection mode="single"></dxo-selection>
            <dxo-grouping #expand [autoExpandAll]="false"></dxo-grouping>
            <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
            <dxo-load-panel [showPane]="false"></dxo-load-panel>
            <dxo-export [enabled]="false" [fileName]="exportFileName()" [allowExportSelectedData]="false"></dxo-export>

            <dxi-column dataField="partnerkonditionId" caption="Partnerkondition Id" alignment="left" dataType="number"
                        [visible]="false" [allowFiltering]="false" [allowGrouping]="false"></dxi-column>
            <dxi-column dataField="vlnr" caption="VL-Nr." alignment="left" width="75" dataType="number"
                        [allowEditing]="false" [filterOperations]="filterOperations"></dxi-column>
            <dxi-column dataField="name" caption="Name" alignment="left" minWidth="100" dataType="string"
                        [allowEditing]="false"></dxi-column>
            <dxi-column dataField="gegenleistungsart" caption="Gegenleistungsart" alignment="left" width="150"
                        dataType="string" [allowEditing]="false"></dxi-column>
            <dxi-column dataField="ksnr" caption="KS-Nr." alignment="left" minWidth="65" width="65"
                        dataType="number" [allowEditing]="false"></dxi-column>
            <dxi-column dataField="ksbezeichnung" caption="KS-Bezeichnung" alignment="left" minWidth="100"
                        dataType="string" [allowEditing]="false"></dxi-column>
            <dxi-column dataField="anzahlGegenleistungen" caption="Anzahl Gegenleistungen" alignment="left"
                        width="75"
                        dataType="number" [allowEditing]="false">
            </dxi-column>
            <dxi-column dataField="anzahlArtikel" caption="Anzahl Artikel" alignment="left" width="75"
                        dataType="number" [allowEditing]="false">
            </dxi-column>
            <dxi-column dataField="summe" caption="Summe" alignment="left" width="100" dataType="number"
                        [allowEditing]="false"></dxi-column>
            <dxi-column dataField="bemerkungExtern" caption="Bemerkung" alignment="left" minWidth="100"
                        dataType="string" [allowEditing]="false"></dxi-column>

            <dxi-column dataField="erfuellungsgrad" caption="Erfüllungsgrad in %" alignment="left" minWidth="100"
                        dataType="number" cellTemplate="percentTemplate" [allowEditing]="false"></dxi-column>
            <dxi-column dataField="anzahl" caption="Anzahl beworbener Artikel" alignment="left" minWidth="100" dataType="number">
<!--              <dxi-validation-rule type="required"></dxi-validation-rule>-->
              <dxi-validation-rule type="custom"
                                   message="Anzahl beworbener Artikel ist ein Pflichtfeld"
                                   [validationCallback]="anzahlCallback"></dxi-validation-rule>
              <dxi-validation-rule type="range" min="1" max="2147483647" message="Der Wert muss zwichen '1' und '2147483647' liegen"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="seitennummern" caption="Seitennummern (Bsp: 3;5;10)" alignment="left" minWidth="150"
                        dataType="string">
<!--              <dxi-validation-rule type="required"></dxi-validation-rule>-->
              <dxi-validation-rule type="custom"
                                    message="Seitennummern ist ein Pflichtfeld"
                                    [validationCallback]="seitennummernCallback"></dxi-validation-rule>
              <dxi-validation-rule type="pattern" [pattern]="seitennummernPattern"
                                   message="Zahlen mit Semikolons trennen"></dxi-validation-rule>
            </dxi-column>

            <div *dxTemplate="let data of 'percentTemplate'">
              <div class="progress position-relative grid-progress esoes-progress" role="progressbar">
                <div class="progress-bar bg-success esoes-progress-bar"
                     [style.width]="getErfuellunsgrad(data.value)+'%'"></div>
                <span
                  class="justify-content-center d-flex position-absolute w-100 grid-progress-text esoes-progress-span">{{getErfuellunsgrad(data.value)}}
                  %</span>
              </div>
            </div>

            <dxo-summary>
              <dxi-total-item column="seitennummern" summaryType="count" alignment="right">
              </dxi-total-item>
            </dxo-summary>
          </dx-data-grid>
        </div>
      </div>
    </div>
  </form>
</div>
