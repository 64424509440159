import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TypedEvent} from "./typed.event";
import {TaskDialogButtons} from "./taskdialog/task-dialog-buttons";
import {WaitCursorService} from "../wait-cursor/wait-cursor.service";

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss']
})
export class MessageBoxComponent implements OnInit {
  private readonly showDetailsText: string = "Details einblenden";
  private readonly hideDetailsText: string = "Details ausblenden";

  public title: string = 'Der Titel';
  public message: string = 'Die Nachricht';
  public details: string = 'Die Details';
  public isBtnOkVisible: boolean = false;
  public isBtnYesVisible: boolean = false;
  public isBtnNoVisible: boolean = false;
  public isBtnCancelVisible: boolean = false;
  public isBtnCloseVisible: boolean = false;
  public isBtnRetryVisible: boolean = false;
  public iconSrc: string;
  public iconClass: string = "";
  public onButtonPressed = new TypedEvent<TaskDialogButtons>();
  public stacktraceString: string = null;
  public stacktraceLinkText = this.showDetailsText;
  public stacktraceVisible = false;

  constructor(public activeModal: NgbActiveModal, private waitCursorService: WaitCursorService) {
    this.waitCursorService.hideWaitCursor();
  }

  ngOnInit() {

  }

  public toggleStacktrace(): void {
    this.stacktraceVisible = !this.stacktraceVisible;
    this.stacktraceLinkText = this.stacktraceVisible ? this.hideDetailsText : this.showDetailsText;
  }

  public hasStacktrace(): boolean {
    return this.stacktraceString.length > 0;
  }

  public onBtnOk(): void {
    this.close();
    this.onButtonPressed.emit(TaskDialogButtons.OK);
  }

  public onBtnYes(): void {
    this.close();
    this.onButtonPressed.emit(TaskDialogButtons.Yes);
  }

  public onBtnNo(): void {
    this.close();
    this.onButtonPressed.emit(TaskDialogButtons.No);
  }

  public onBtnCancel(): void {
    this.close();
    this.onButtonPressed.emit(TaskDialogButtons.Cancel);
  }

  public onBtnClose(): void {
    this.close();
    this.onButtonPressed.emit(TaskDialogButtons.Close);
  }

  public onBtnRetry(): void {
    this.close();
    this.onButtonPressed.emit(TaskDialogButtons.Retry);
  }

  public close(): void {
    this.activeModal.close();
  }


}
