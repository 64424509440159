import {Injectable, Injector} from "@angular/core";
import {HttpHandler, HttpEvent, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {UserManagerService} from "./usermanager.service";
import {Observable, from} from "rxjs";
import {Router, NavigationExtras} from "@angular/router";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private retrying: boolean = false;

  constructor(private injector: Injector, private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handle(request, next));
  }

  async handle(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    if(request.url.includes('adfs') || request.url.includes('assets/')){
      return await next.handle(request).toPromise();
    }

    try {
      var tokenItem = await this.injector.get(UserManagerService).getTokenItem();
      if (tokenItem) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${tokenItem}`
          }
        });
      }

      return await next.handle(request).toPromise();
    } catch (error) {		// HttpErrorResponse
      if (error.status == 401) {
        let success: boolean = false;
        //Unauthorized. Try to refresh token
        if (tokenItem && !this.retrying) {
          try {
            this.retrying = true;
            await this.injector.get(UserManagerService).login(true);
            success = true;
          } catch (innerError) {
          }
          this.retrying = false;
        }
        if (!success) {
          // if unauthorized, then probably expiration time is reached. redirect to login page.
          this.navigateToLogin();
        } else {
          // otherwise retry request after token refresh
          return this.handle(request, next);
        }

      } else if (error.status == 403) {
        // resource not found, probably the user does not have the permission for this resource. redirect to home.
        this.navigateToHome();
      }

      throw error;
    }
  }

  private navigateToHome() {
    this.router.navigate(['/']);
  }

  private navigateToLogin() {
    this.injector.get(UserManagerService).login();
  }
}
