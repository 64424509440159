<div class="modal-header">
  <div class="modal-title" id="modal-basic-title">{{title}}</div>
  <button type="button" class="btn-close" aria-label="Close" (click)="onClose()">

  </button>
</div>
<div class="modal-body">
  <div class="divTable modal-center">
    <div class="divTableBody">
      <div class="divTableRow">
        <div style="width: 1em;" class="divTableCell">
          <img class="icon " [src]="'/assets/images/question.svg'"/>
        </div>
        <div class="divTableCell">
          <h5 [innerHtml]="message"></h5>
          <div [innerHtml]="details">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" style="width: 165px" ngbAutofocus class="btn btn-outline-primary btn-sm dialog-button"
            (click)="onDeleteAll()">Alle Nachweise löschen
    </button>
    <button type="button" style="width: 130px" class="btn btn-outline-primary btn-sm dialog-button"
            (click)="onDelete()">Nachweis löschen
    </button>
    <button type="button" class="btn btn-outline-primary btn-sm dialog-button" (click)="onCancel()">Abbrechen
    </button>
  </div>
</div>
