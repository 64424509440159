import {Inject, Injectable} from '@angular/core';
import {FunctionModuleBase} from "./function-module-base";
import {MenuItem} from "../../nav-menu/menu-item";

@Injectable({
  providedIn: 'root'
})
export class FunctionMenuService {
  public readonly functionMenuText: string = 'Funktionen';
  functionGroups: MenuItem[] = [];

  constructor(@Inject(FunctionModuleBase) private functionModules: FunctionModuleBase[]) {
    this.buildMenu();
  }

  public buildMenu() {
    for (let functionModule of this.functionModules) {
      let functionGroupName = functionModule.groupName;
      if (functionGroupName == '' || functionGroupName == null)
        functionGroupName = this.functionMenuText;

      let groupItems = functionGroupName.split("\\");

      // create path of function groups
      let menu = this.functionGroups;
      for (let i = 0; i < groupItems.length; ++i) {
        // function group
        var functionMenuGroup = menu.find((f) => f.itemName == groupItems[i] && f.functionModule == null);
        if (functionMenuGroup === undefined) {
          functionMenuGroup = new MenuItem();
          functionMenuGroup.itemName = groupItems[i];

          if (i == 0 && (functionModule.groupName == '' || functionModule.groupName == null))  // main menu "Funktionen" at first position
            menu.unshift(functionMenuGroup);
          else
            menu.push(functionMenuGroup);
        }

        menu = functionMenuGroup.menuItems;
      }

      // add menu
      let menuItem = new MenuItem();
      menuItem.itemName = functionModule.functionName;
      menuItem.functionModule = functionModule;

      functionMenuGroup.menuItems.push(menuItem);
    }
  }
}
