import {Component} from '@angular/core';
import {UserManagerService} from '../usermanager/usermanager.service';
import {EventAggregator} from "../framework/event-aggregator/event.aggregator";
import {CurrentUserChangedEvent} from "../shared/events/current-user-changed-event";
import {MenuItem} from "./menu-item";
import {FunctionMenuService} from "../framework/functionmodule/function-menu.service";
import {YearselectionService} from "../shared/services/yearselection.service";
import {Using} from "../framework/using/using";
import {WaitCursor, WaitCursorService} from "../framework/wait-cursor/wait-cursor.service";
import {FileTool} from "../framework/tools/FileTool";
import {InteractionController} from "../framework/interaction-controller/interaction.controller";
import {FileData, HelperClient, UserManagerClient} from "../webapi/webapi.service";
import {ConfigurationService} from "../configuration/configuration.service";

declare var DropdownSubmenuHelper: any;
declare var NavLinkHoverHelper: any;

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
})
export class NavMenuComponent {
  public readonly functionMenuText: string = "Funktionen";
  public emailstring: string = null; //xyz@example.com?Subject=Hello&body=links:  %0D http://link1.com  %0D http://link1.com
  public infoText: string = null;
  public isNotMobile: boolean = true;

  constructor(public userManagerService: UserManagerService,
    private configurationService: ConfigurationService,
    public yearselectionService: YearselectionService,
    private eventAggregator: EventAggregator,
    protected waitCursorService: WaitCursorService,
    protected client: HelperClient,
    public functionMenuService: FunctionMenuService) {
    this.eventAggregator.getEvent(CurrentUserChangedEvent).subscribe(user => {
      if (user != null) {
        this.processMenuVisibility(this.functionMenuService.functionGroups);
        DropdownSubmenuHelper.registerOnClickHandler();
        NavLinkHoverHelper.registerOnNavLinkTouch();

        if (window.screen.width <= 576) {
          this.isNotMobile = false;
        }

        if (configurationService.infoText != null || configurationService.infoText != "") {
          this.infoText = configurationService.infoText;
        }

        if (configurationService.contactMail != null || configurationService.contactMail != "") {
          this.emailstring = "mailto:" + configurationService.contactMail;
        }
      }
    });
  }

  logout() {
    this.userManagerService.logout();
  }

  private processMenuVisibility(menuItems: MenuItem[]) {
    this.showHideMenu(menuItems);

    // show or hide main menu function groups
    for (let menuItem of menuItems) {
      menuItem.visible = this.isFunctionGroupVisible(menuItem.menuItems);
    }
  }

  async openPdf() {
    let entry:FileData = null;
    try {
      await Using.using(new WaitCursor(this.waitCursorService), async () => {
        entry = await this.client.getHelpPdfFile().toPromise();
      });
    } catch (e) {
      console.log(e);
      await InteractionController.MessageBox.showWarning("Fehler beim Abrufen der Datei", "Die gewählte Datei konnte nicht abgerufen werden. Möglicherweise existiert die Datei nicht.");
    }
    if(entry != null){
      try {
        FileTool.download(entry.file, entry.type, entry.fileName);
      } catch (e) {
        console.log(e);
        await InteractionController.MessageBox.showWarning("Fehler beim Öffnen der Datei", "Die gewählte Datei konnte nicht geöffnet werden.");
      }
    }
  }

  private showHideMenu(menuItems: MenuItem[]) {
    for (let menuItem of menuItems) {
      // process submenus
      if (menuItem.menuItems.length != 0) {
        this.showHideMenu(menuItem.menuItems);

        // show or hide function group
        menuItem.visible = this.isFunctionGroupVisible(menuItem.menuItems);
      }

      // show or hide menu item
      if (menuItem.functionModule != null)
        menuItem.visible = menuItem.functionModule.hasUserPermission();
    }
  }

  private isFunctionGroupVisible(menuItems: MenuItem[]): boolean {
    let visible: boolean = false;

    for (let menu of menuItems) {
      if (menu.visible) {
        visible = true;
        break;
      }
    }
    return visible;
  }

	openExternalURL(externalURLPath) {
		window.open(externalURLPath, '_blank');
	}
}
