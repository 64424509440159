import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {FunctionWindow} from "../../../../../framework/functionwindow/function-window";
import {UserManagerService} from "../../../../../usermanager/usermanager.service";
import {ActivatedRoute, Router} from "@angular/router";
import {WaitCursorService} from "../../../../../framework/wait-cursor/wait-cursor.service";
import {DxDataGridComponent} from "devextreme-angular";
import {PartnerClient} from "../../../../../webapi/webapi.service";
import {ZeitraumChangedEvent} from "../../../../../shared/events/zeitraum-changed-event";
import {EventAggregator} from "../../../../../framework/event-aggregator/event.aggregator";
import {YearselectionService} from "../../../../../shared/services/yearselection.service";
import {PartnerkonditionenFunctionModule} from "../../../functionmodules/partnerkonditionen-function-module";

@Component({
  selector: 'app-partneruebersicht',
  templateUrl: './partneruebersicht.component.html',
  host: {class: 'container-fluid d-flex h-100 flex-column p-0 bg-detailtoolbar'}
})
export class PartneruebersichtComponent extends FunctionWindow implements OnInit, OnDestroy {
  @ViewChild('grid') dataGrid: DxDataGridComponent;
  gridKey: string[] = ["id"];
  loadParams;
  subscribeLambda = year => {
    this.setYear(year);
  };

  get hasWritePermission(): boolean {
    return this.userManagerService.hasWritePermission(this.functionModule.contractName);
  }

  constructor(@Inject('BASE_URL') baseUrl: string,
              @Inject(PLATFORM_ID) protected platformId: any,
              protected userManagerService: UserManagerService,
              private client: PartnerClient,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected waitCursorService: WaitCursorService,
              protected functionModule: PartnerkonditionenFunctionModule,
              private yearselectionService: YearselectionService,
              private eventAggregator: EventAggregator) {
    super(platformId, userManagerService, router, waitCursorService, functionModule);
    this.eventAggregator.getEvent(ZeitraumChangedEvent).subscribe(this.subscribeLambda);

    this.loadParams = {year: yearselectionService.year};
    this.loadUrl = baseUrl + 'api/partnerkondition/queryallpartner';
    this.createDataSource(this.loadParams);
  }

  onMainModuleNavigation(state: any) {
    super.onMainModuleNavigation(state);

    // we navigated back from client
    if (window.history.state.id !== undefined && window.history.state.id != 0) {
      if (this.dataGrid !== undefined) {
        this.dataGrid.selectedRowKeys = [{id: +window.history.state.id}];
      }
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.eventAggregator.getEvent(ZeitraumChangedEvent).unsubscribe(this.subscribeLambda);
  }

  setYear(year: number) {
    this.loadParams = {year: year};
    this.createDataSource(this.loadParams);
  }

  navigateToPartner(rowData: any) {
    this.router.navigate([`partner/${rowData.id}`], {
      relativeTo: this.activatedRoute,
      state: {rootObjectOffer: rowData}
    });
  }
}
