import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {FunctionWindow} from "../../../../framework/functionwindow/function-window";
import {UserManagerService} from "../../../../usermanager/usermanager.service";
import {ActivatedRoute, Router} from "@angular/router";
import {WaitCursorService} from "../../../../framework/wait-cursor/wait-cursor.service";
import {DxDataGridComponent} from "devextreme-angular";
import {PartnersichtFunctionModule} from "../../functionsmodules/partnersicht-function-module.service";

@Component({
  selector: 'app-partnersicht-partner-uebersicht',
  templateUrl: './partnersicht-partner-uebersicht.component.html',
  host: {class: 'container-fluid d-flex h-100 flex-column p-0 bg-detailtoolbar'}
})
export class PartnersichtPartnerUebersichtComponent extends FunctionWindow implements OnInit, OnDestroy {
  @ViewChild('gridOverview') dataGrid: DxDataGridComponent;
  gridKey: string[] = ["id"];
  loadParams;

  get hasWritePermission(): boolean {
    return this.userManagerService.hasWritePermission(this.functionModule.contractName);
  }

  constructor(@Inject('BASE_URL') baseUrl: string,
              @Inject(PLATFORM_ID) protected platformId: any,
              protected userManagerService: UserManagerService,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected waitCursorService: WaitCursorService,
              protected functionModule: PartnersichtFunctionModule) {
    super(platformId, userManagerService, router, waitCursorService, functionModule);

    this.loadUrl = baseUrl + 'api/partnersicht/querypartneroverview';
    this.createDataSource();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.refresh();
    if (this.userManagerService.userPartner.length == 1 && this.userManagerService.benutzerArt != 0) {
      this.router.navigate([`partner/${this.userManagerService.userPartner[0].partnerId}`], {relativeTo: this.activatedRoute});
    }
  }

  onMainModuleNavigation(state: any) {
    super.onMainModuleNavigation(state);
    if (window.history.state.id !== undefined && window.history.state.id != 0) {
      if (this.dataGrid !== undefined) {
        this.dataGrid.selectedRowKeys = [{id: +window.history.state.id}];
      }
    }
    if (this.userManagerService.userPartner.length == 1 && this.userManagerService.benutzerArt != 0) {
      this.router.navigate([`partner/${this.userManagerService.userPartner[0].partnerId}`], {relativeTo: this.activatedRoute});
    }
  }

  navigateToPartner(rowData: any) {
    this.router.navigate([`partner/${rowData.id}`], {
      relativeTo: this.activatedRoute,
      state: {rootObjectOffer: rowData}
    });
  }
}
