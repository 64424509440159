import { UserPermissionDefinition } from "../../usermanager/model/user-permission-definition";
import { UserPermissionSuffix, UserPermissionSuffixText } from "../../usermanager/model/user-permission-suffix";
import { UserManagerService } from "../../usermanager/usermanager.service";
import { ModuleRoute } from "./module-route";
import { mapToCanActivate, mapToCanActivateChild, mapToCanDeactivate, Route, Routes, Router, RouterModule } from "@angular/router";
import { AuthGuard } from "../../usermanager/auth.guard";
import { CanDeactivateGuard } from "../../can-deactivate.guard";

export abstract class FunctionModuleBase {
  contractName?: string | null;
  functionName?: string | null;
  groupName?: string | null;
  routerPath?: string | null;
  externalURLPath?: string | null;
  component?: any | null;
  userPermissions: UserPermissionDefinition[] = [];
  moduleRoutes?: ModuleRoute[] = [];
  registerRoutes: boolean = true;
  breadcrumbGroup?: string = null;
  breadcrumbLabel?: string = null;
  homeTileClass?: string = null;
  homeTileSubTitle?: string = null;
  homeTileImageSource?: string = null;
  homeTileSubImageSource?: string = null;
  homeTileVisible: boolean = true;

  protected constructor(protected userManagerService: UserManagerService, protected router: Router) {

  }

  configureFunctionModule() {
    this.userPermissions.push(new UserPermissionDefinition(`${this.functionName} ${UserPermissionSuffixText.ReadPermission}`, `${this.contractName}${UserPermissionSuffix.ReadPermission}`));
    this.userPermissions.push(new UserPermissionDefinition(`${this.functionName} ${UserPermissionSuffixText.WritePermission}`, `${this.contractName}${UserPermissionSuffix.WritePermission}`));

    this.configureBreadcrumb();
    this.configureModuleRoutes();
    this.registerModuleRoutes();
  }

  configureBreadcrumb() {
    if (this.breadcrumbGroup == null)
      this.breadcrumbGroup = this.groupName;
    if (this.breadcrumbLabel == null)
      this.breadcrumbLabel = this.functionName;
  }

  configureModuleRoutes() {
  }

  registerModuleRoutes() {
    if (this.registerRoutes) {
      let route: Route = {
        path: this.routerPath,
        component: this.component,
        canActivate: mapToCanActivate([AuthGuard]),
        canActivateChild: mapToCanActivateChild([AuthGuard]),
        canDeactivate: mapToCanDeactivate([CanDeactivateGuard]),
        data: { breadcrumbGroup: this.breadcrumbGroup, breadcrumbLabel: this.breadcrumbLabel }
      };

      this.registerChildrenRoutes(route, this.moduleRoutes);

      this.router.config.unshift(route);
    }
  }

  private registerChildrenRoutes(route: Route, moduleRoutes: ModuleRoute[]) {
    if (moduleRoutes.length > 0) {
      route.children = [];
      moduleRoutes.forEach(moduleRoute => {
        let childRoute: Route = {
          path: moduleRoute.path,
          component: moduleRoute.component,
          canDeactivate: mapToCanDeactivate([CanDeactivateGuard]),
          data: { breadcrumbLabel: moduleRoute.breadcrumbLabel }
        };
        route.children.push(childRoute);

        this.registerChildrenRoutes(childRoute, moduleRoute.moduleRoutes);
      });
    }
  }

  hasUserPermission(): boolean {
    let isAllowed: boolean = true;

    if (this.userPermissions != null) {
      let allowedPermissionFound: boolean = false;
      for (let perm of this.userPermissions) {
        if (this.userManagerService.hasPermission(perm.permissionKey)) {
          allowedPermissionFound = true;
          break;
        }
      }

      isAllowed = allowedPermissionFound;
    }

    return isAllowed;
  }

  hasUserRoutePermission(url: string): boolean {
    if (url == `/${this.routerPath}`)
      return this.hasUserPermission();
    else {
      for (let route of this.moduleRoutes) {
        if (this.matchesPath(route.path, url)) {
          if (this.userManagerService.hasPermission(route.permissionKey) || this.userManagerService.hasPermission(`${this.contractName}${UserPermissionSuffix.ReadPermission}`)
            || this.userManagerService.hasPermission(`${this.contractName}${UserPermissionSuffix.WritePermission}`))
            return true;
          else
            return false;
        }
      }
    }

    return true;
  }

  private matchesPath(routePath: string, url: string): boolean {
    let retValue = false;
    let routeSegments: string[] = routePath.split("/");
    let urlSegments: string[] = url.split("/");
    if (routeSegments.length == urlSegments.length) {
      retValue = true;
      for (let i = 0; i < routeSegments.length; ++i) {
        if (!routeSegments[i].startsWith(":") && routeSegments[i] != urlSegments[i]) {
          retValue = false;
          break;
        }
      }
    }
    return retValue;
  }
}
