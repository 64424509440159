import {Injectable} from '@angular/core';
import {EventBase} from './event.base';

@Injectable({
  providedIn: 'root',
})
export class EventAggregator {

  getEvent<T extends EventBase>(type: { new(): T; }): T {
    let instance: T;
    let paramInstance: T;

    paramInstance = new type();
    let name = paramInstance.getName();

    //todo: lock access to event Array
    //console.log("get Event : " + type +  `(name = '${name}')`);
    //console.log(`(name = '${name}')`);

    let index = this.events.findIndex(item => item[0] == name);

    if (index > -1) {
      const eventBase: EventBase = this.events[index][1];
      instance = eventBase as T;
    } else {
      instance = new type();
      this.events.push([name, instance]);
    }
    //console.log("Num Events : " + this.events.length);
    //console.log("Returning name : " + instance.getName());

    return instance;
  }

  private activator<T>(type: { new(): T; }): T {
    return new type();
  }

  private events: Array<[string, EventBase]> = [];
}
