import { Injectable } from '@angular/core';
import {LoginOptions, OAuthService} from 'angular-oauth2-oidc';
import {authConfig} from "../../environments/fsauthconfig";
import {ConfigurationService} from "../configuration/configuration.service";
import {MessageBoxService} from "../framework/message-box/message-box.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private currentLocation : string;
  private _stateLocation : string;

  constructor(
    private configurationService: ConfigurationService,
    private oauthService: OAuthService,
    private router: Router,
    private messageBoxService: MessageBoxService
  ) {
  }

  public resolveFullName() {
    let retValue = '';

    let identityClaims : any = this.oauthService.getIdentityClaims();
    if(identityClaims != null){
      retValue = identityClaims.unique_name;
    }
    return retValue;
  }


  public async acquireToken() : Promise<string> {
    let retValue = null;
    if(this.oauthService.hasValidAccessToken()){
      retValue = this.oauthService.getAccessToken();
    }
    else {
      await this.renewToken();
      if(this.oauthService.hasValidAccessToken()){
        retValue = this.oauthService.getAccessToken();
      } else {
        await this.logout();
        throw "No valid accesstoken. Logging out.";
      }

    }
    //Token wird direkt beim Login geholt
    // if(retValue == null){
    //   this.login();
    // }
    return retValue;
  }

  public async renewToken() {
    //Wird von der Bibliothek automatisch gemacht
    await this.oauthService.refreshToken();
  }

  async logout() {
    await this.oauthService.logOut();
  }

  private codeFlowStarted  : boolean = false;

  login() {
    //Login wird beim Initialen navigieren durchgeführt
    // if(!this.codeFlowStarted){
    //   this.codeFlowStarted = true;
    //   let currentUrl = this.currentLocation;
    //   console.log("Current URL" , currentUrl);
    // }
    // throw new Error("Redirecting. Stop all work now.");
  }

  async parseUrlTokens() {
    let currentUrl = this.currentLocation;
    console.log("Current URL" , currentUrl);
    let loginOptions : LoginOptions & { state?: string } = new LoginOptions();
    loginOptions.state = currentUrl;
    loginOptions.disableOAuth2StateCheck = true;
    let promise = this.loadDiscoveryDocumentAndLogin(loginOptions);
    promise.then(() => {
      if(this.oauthService.hasValidAccessToken()){
        if (!this.hadStateLocation) {
            this.router.initialNavigation()
        }
        else{
          this.navigateToState()
        }
      }
    });
  }

  public navigateToState() {
    if(this.stateLocation != null && this.stateLocation.length > 0){
      let forcedUrl = this.stateLocation;
      setTimeout(()=>{
        this.router.navigateByUrl(forcedUrl);
      }, 1);
      this.stateLocation = null;
    }
  }


  public async configureAuth() {
    authConfig.clientId = this.configurationService.clientId;
    authConfig.issuer = this.configurationService.issuer;
    if (authConfig.issuer == null) {
      throw "No issuer configured";
    }
    if(authConfig.clientId == null){
      throw "No client-ID configured";
    }
    this.oauthService.configure(authConfig);
    this.oauthService.events.subscribe((e)=> {
      let state = this.oauthService.state;
      if(state != null &&  state.length > 0)
      this.stateLocation = state;
      this.oauthService.state = null;
    });
    this.oauthService.setupAutomaticSilentRefresh();
  }

  setLocation(s: string) {
    this.currentLocation = s;
  }

  public getLocation() : string {
    return this.currentLocation;
  }

  get stateLocation(): string {
    return this._stateLocation;
  }

  private hadStateLocation = false;

  set stateLocation(value: string) {
    this._stateLocation = value;
    if(value != null && value.length > 0){
      this.hadStateLocation = true;
    }
  }

  /**
   * Convenience method that first calls `loadDiscoveryDocumentAndTryLogin(...)`
   * and if then chains to `initLoginFlow()`, but only if there is no valid
   * IdToken or no valid AccessToken.
   *
   * @param options LoginOptions to pass through to `tryLogin(...)`
   */
  private loadDiscoveryDocumentAndLogin(
    options: LoginOptions & { state?: string } = null
  ): Promise<boolean> {
    if (!options) {
      options = { state: '' };
    }
    return this.oauthService.loadDiscoveryDocumentAndTryLogin(options).then(_ => {
        if (!this.oauthService.hasValidIdToken() || !this.oauthService.hasValidAccessToken()) {
          if (this.oauthService.responseType === 'code') {
            this.oauthService.initCodeFlow(options.state);
          } else {
            this.oauthService.initImplicitFlow(options.state);
          }
          return false;
        } else {
          return true;
        }
      },
      _ => { this.messageBoxService.showException("Kommunikationsfehler", "Es ist ein Fehler bei der Kommunikation mit dem Authentifizierungsserver aufgetreten. Bitte laden Sie die Anwendung neu."); return null;}
      );
  }

}
