import {Injectable} from "@angular/core";
import {FunctionModuleBase} from "../../../framework/functionmodule/function-module-base";
import {UserManagerService} from "../../../usermanager/usermanager.service";
import {Router} from "@angular/router";
import {LieferantenkonditionenpflegeUebersichtComponent} from "../konditionen/lieferantenkonditionen/lieferantenkonditionpflege-uebersicht/lieferantenkonditionenpflege-uebersicht.component";
import {ModuleRoute} from "../../../framework/functionmodule/module-route";
import {PartnerkonditionenComponent} from "../konditionen/partner/partnerkonditionen/partnerkonditionen.component";
import {UserPermissionSuffix} from "../../../usermanager/model/user-permission-suffix";
import {LieferantenkonditionenpflegeComponent} from "../konditionen/lieferantenkonditionen/lieferantenkonditionpflege/lieferantenkonditionenpflege.component";

@Injectable({
  providedIn: 'root'
})
export class LieferantenkonditionenpflegeFunctionModule extends FunctionModuleBase {
  contractName = "Lieferantenkonditionenpflege";
  functionName = "Lieferantenkonditionen";
  groupName = "Konditionen";
  routerPath = "lieferantenkonditionenpflege";
  component = LieferantenkonditionenpflegeUebersichtComponent;
  homeTileClass = "tileGroupClass2";
  homeTileImageSource = './assets/images/Home.svg';
  homeTileSubTitle = "Übersicht der Lieferantenkonditionen";

  constructor(protected userManagerService: UserManagerService, protected router: Router) {
    super(userManagerService, router);
    this.configureFunctionModule();
  }

  configureModuleRoutes() {
    super.configureModuleRoutes();
    this.moduleRoutes.push(
      new ModuleRoute('lieferantenkonditionen/:id', 'Lieferantenkondition', LieferantenkonditionenpflegeComponent, `${this.contractName}${UserPermissionSuffix.ReadPermission}`)
    );
  }
}
