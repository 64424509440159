import {Component, OnInit, ViewChild} from '@angular/core';
import {DialogBase} from "../../../../framework/dialog/dialog.base";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {WaitCursor, WaitCursorService} from "../../../../framework/wait-cursor/wait-cursor.service";
import {UserManagerService} from "../../../../usermanager/usermanager.service";
import {NgForm} from "@angular/forms";
import {Using} from "../../../../framework/using/using";
import {TaskDialogButtons} from "../../../../framework/message-box/taskdialog/task-dialog-buttons";
import {InteractionController} from "../../../../framework/interaction-controller/interaction.controller";
import {
  ReturnCodesSaveGegenleistungsart,
  GegenleistungsartClient,
  Gegenleistungsart
} from "../../../../webapi/webapi.service";

export class GegenleistungsartDialogParameter {
  gegenleistungsartId: number;
}

@Component({
  selector: 'app-gegenleistungsart-dialog',
  templateUrl: './gegenleistungsart-dialog.component.html'
})
export class GegenleistungsartDialogComponent extends DialogBase implements OnInit {
  @ViewChild('dialogForm') dialogForm: NgForm;
  title: string = 'Gegenleistungsart';
  gegenleistungsart: Gegenleistungsart = new Gegenleistungsart();

  constructor(public activeModal: NgbActiveModal,
              public waitCursorService: WaitCursorService,
              private _userManagerService: UserManagerService,
              private dialogParameter: GegenleistungsartDialogParameter,
              private gegenleistungsartClient: GegenleistungsartClient) {
    super(activeModal, waitCursorService);
  }

  async ngOnInit() {
    try {
      await Using.using(new WaitCursor(this.waitCursorService), async () => {
        // create or load gegenleistungsart
        if (this.dialogParameter.gegenleistungsartId == 0) {
          this.gegenleistungsart = new Gegenleistungsart();
          this.gegenleistungsart.id = 0;
        } else {
          let gegenleistungsart = await this.gegenleistungsartClient.get(this.dialogParameter.gegenleistungsartId).toPromise();
          if (gegenleistungsart != null) {
            this.gegenleistungsart = gegenleistungsart;
          } else {
            await InteractionController.MessageBox.showWarning("Keine Gegenleistungsartdaten", "Gegenleistungsartdaten konnten nicht abgerufen werden. Möglicherweise existiert die Gegenleistungsart nicht.");
            this.close();
          }
        }
      });
    } catch (e) {
      await InteractionController.MessageBox.showApplicationException(e);
      this.close();
    }
  }

  public async onOK() {
    try {
      await Using.using(new WaitCursor(this.waitCursorService), async () => {
        if (this.dialogForm.valid) {
          if (this.dialogForm.dirty) {
            // save
            let retValue = await this.gegenleistungsartClient.save(this.gegenleistungsart).toPromise();
            this.dialogParameter.gegenleistungsartId = retValue.id;

            // check return value
            if (retValue.returnCode == ReturnCodesSaveGegenleistungsart.DuplicateName) {
              InteractionController.MessageBox.showWarning("Name", "Der eingegebene Name existiert bereits.");
              this.dialogForm.form.controls['gegenleistungsart.name'].setErrors({});
              this.dialogForm.form.controls['gegenleistungsart.name'].markAsTouched();
              return;
            }

            // close dialog with OK
            this.close();
            this.onButtonPressed.emit(TaskDialogButtons.OK);
          } else	// no changes, just close
            this.close();
        } else {
          this.dialogForm.form.controls['gegenleistungsart.name'].markAsTouched();
          InteractionController.MessageBox.showWarning("Validierungsfehler",
            "Ein oder mehrere Validierungsfehler sind aufgetreten. Die fehlerhaften Eingaben sind rot markiert. Bitte korrigieren Sie Ihre Eingaben und versuchen Sie erneut den Datensatz zu speichern.");
        }
      });
    } catch (e) {
      InteractionController.MessageBox.showApplicationException(e);
    }
  }
}
