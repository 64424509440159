import {Injectable, Injector} from '@angular/core';
import {EventAggregator} from "../../framework/event-aggregator/event.aggregator";
import {ZeitraumChangedEvent} from "../events/zeitraum-changed-event";
import {Router} from "@angular/router";
import {ZeitraumCanChangeEvent} from "../events/zeitraum-can-change-event";
import {UserManagerClient, Zeitraum} from "../../webapi/webapi.service";
import {CurrentUserChangedEvent} from "../events/current-user-changed-event";
import {UserManagerService} from "../../usermanager/usermanager.service";
import {ZeitraumlistChangedEvent} from "../events/zeitraumlist-changed-event";

@Injectable()
export class YearselectionService {
  private _year: number = 2000;
  private canChangeYear;
  public yearList: Zeitraum[];
  public zeitraumActive: boolean;

  constructor(public userManagerService: UserManagerService,
              private router: Router,
              protected client: UserManagerClient,
              private injector: Injector,
              private eventAggregator: EventAggregator) {
    this.eventAggregator.getEvent(ZeitraumCanChangeEvent).subscribe(canChangeYear => {
      this.canChangeYear = canChangeYear;
    });
    this.eventAggregator.getEvent(CurrentUserChangedEvent).subscribe(user => {
      if (user != null) {
        this.loadZeitraumList();
      }
    });
    this.eventAggregator.getEvent(ZeitraumlistChangedEvent).subscribe(zeitraumList => {
      this.loadZeitraumList();
    });
  }

  get year(): number {
    return this._year;
  }

  set year(value: number) {
    if (value != this._year) {
      let oldValue = this._year;
      this._year = value;
      this.selectYear(value, oldValue);
    }
  }

  async selectYear(newValue: number, oldValue: number) {
    if (this.canChangeYear != undefined) {
      if (await this.canChangeYear.canChangeDate()) {
        this._year = newValue;
        this.eventAggregator.getEvent(ZeitraumChangedEvent).publish(newValue);
      } else {
        this._year = oldValue;
      }
    } else {
      this._year = newValue;
      this.eventAggregator.getEvent(ZeitraumChangedEvent).publish(newValue);
    }
    this.checkZeitraumActiveState();
  }

  async loadZeitraumList() {
    if (this.userManagerService.benutzerArt == 0) {
      this.yearList = await this.client.getZeitraumList().toPromise();
    } else {
      this.yearList = await this.client.getLastThree().toPromise();
    }
    if (this.yearList.length > 0) {
      this.selectActualYear();
      this.checkZeitraumActiveState();
    }
  }

  checkZeitraumActiveState() {
    for (let item of this.yearList) {
      if (item.jahr == this._year) {
        this.zeitraumActive = item.status;
      }
    }
  }

  selectActualYear() {
    for (let item of this.yearList) {
      if (item.jahr > this.year && item.status == true) {
        this.year = item.jahr;
      }
    }
    this.eventAggregator.getEvent(ZeitraumChangedEvent).publish(this.year);
  }
}



