import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild} from "@angular/core";
import {FunctionWindow} from "../../../framework/functionwindow/function-window";
import {DxDataGridComponent} from "devextreme-angular";
import {UserManagerService} from "../../../usermanager/usermanager.service";
import {LieferantenClient} from "../../../webapi/webapi.service";
import {ActivatedRoute, Router} from "@angular/router";
import {WaitCursorService} from "../../../framework/wait-cursor/wait-cursor.service";
import {LieferantenkonditionenpflegeFunctionModule} from "../../stammdaten/functionmodules/lieferantenkonditionenpflege-function-module";
import {YearselectionService} from "../../../shared/services/yearselection.service";
import {EventAggregator} from "../../../framework/event-aggregator/event.aggregator";
import {ZeitraumChangedEvent} from "../../../shared/events/zeitraum-changed-event";
import {NachweiseFunctionModule} from "../functionsmodules/nachweise-function-module";

@Component({
  selector: 'app-nachweise-uebersicht',
  templateUrl: './nachweise-uebersicht.component.html',
  host: {class: 'container-fluid d-flex h-100 flex-column p-0 bg-detailtoolbar'}
})
export class NachweiseUebersichtComponent extends FunctionWindow implements OnInit, OnDestroy {
  @ViewChild('grid') dataGrid: DxDataGridComponent;
  gridKey: string[] = ["id"];
  loadParams;

  subscribeLambda = year => {
    this.setYear(year);
  };

  get hasWritePermission(): boolean {
    return this.userManagerService.hasWritePermission(this.functionModule.contractName);
  }

  constructor(@Inject('BASE_URL') baseUrl: string,
              @Inject(PLATFORM_ID) protected platformId: any,
              protected userManagerService: UserManagerService,
              private client: LieferantenClient,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected waitCursorService: WaitCursorService,
              protected functionModule: NachweiseFunctionModule,
              public yearselectionService: YearselectionService,
              private eventAggregator: EventAggregator) {
    super(platformId, userManagerService, router, waitCursorService, functionModule);
    this.eventAggregator.getEvent(ZeitraumChangedEvent).subscribe(this.subscribeLambda);
    this.loadParams = {year: yearselectionService.year};
    this.loadUrl = baseUrl + 'api/nachweise/nachweiseOverviewList';
    this.createDataSource(this.loadParams);
  }

  setYear(year: number) {
    this.loadParams = {year: year};
    this.createDataSource(this.loadParams);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.eventAggregator.getEvent(ZeitraumChangedEvent).unsubscribe(this.subscribeLambda);
  }
}
