<div class="modal-header">
  <div class="modal-title" id="modal-basic-title">{{title}}</div>
  <button type="button" class="btn-close" aria-label="Close" (click)="onClose()">

  </button>
</div>
<form #dialogForm="ngForm" (ngSubmit)="onOK()">
  <div class="modal-body">
    <div class="modal-center">
      <div class="form-group">
        <label for="status" class="col-form-label">An WKZ partizipieren:</label>
        <select class="form-control form-select" id="status" name="status" [(ngModel)]="status">
          <option value="-1"></option>
          <option value="0">Nein</option>
          <option value="1">Ja</option>
        </select>
      </div>
    </div>
    <div class="modal-footer">
      <button ngbAutofocus type="submit" class="btn btn-primary btn-sm dialog-button">OK</button>
      <button type="button" class="btn btn-outline-primary btn-sm dialog-button" (click)="onCancel()">Abbrechen
      </button>
    </div>
  </div>
</form>
