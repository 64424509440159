import {Injectable} from '@angular/core';
import {TaskDialogSimpleResult} from './taskdialog/task-dialog-simple-result';
import {TaskDialogOptions} from './taskdialog/task-dialog-options';
import {TaskDialogCommonButtons} from './taskdialog/task-dialog-common-buttons';
import {TaskDialogIcons} from './taskdialog/task-dialog-icons';
import {TaskDialogException} from './taskdialog/task-dialog-exception';
import {NgbModal, ModalDismissReasons, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {TaskDialogButtons} from './taskdialog/task-dialog-buttons';
import {MessageBoxComponent} from "./message-box.component";
import {ApiException} from "../../webapi/webapi.service";
import {HttpErrorResponse} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class MessageBoxService {
  private lastPromise: Promise<TaskDialogSimpleResult>;
  private resolveFunc: (value?: TaskDialogSimpleResult) => void;

  constructor(private modalService: NgbModal) {
  }

  public async show(options: TaskDialogOptions): Promise<TaskDialogSimpleResult> {
    const result = await this.showInternal(options);
    return result;
  }

  public showInternal(options: TaskDialogOptions): Promise<TaskDialogSimpleResult> {
    const modal: NgbModalRef = this.modalService.open(MessageBoxComponent, {
      centered: true, beforeDismiss: () => {
        return false;
      }
    });
    const component: MessageBoxComponent = modal.componentInstance;
    component.title = options.title;
    component.message = options.mainInstruction;
    component.details = options.content;
    component.stacktraceString = options.expandedInfo;

    switch (options.commonButtons) {
      case TaskDialogCommonButtons.Close:
        component.isBtnCloseVisible = true;
        break;
      case TaskDialogCommonButtons.OK:
        component.isBtnOkVisible = true;
        break;
      case TaskDialogCommonButtons.OKCancel:
        component.isBtnOkVisible = true;
        component.isBtnCancelVisible = true;
        break;
      case TaskDialogCommonButtons.RetryCancel:
        component.isBtnRetryVisible = true;
        component.isBtnCancelVisible = true;
        break;
      case TaskDialogCommonButtons.YesNo:
        component.isBtnYesVisible = true;
        component.isBtnNoVisible = true;
        break;
      case TaskDialogCommonButtons.YesNoCancel:
        component.isBtnYesVisible = true;
        component.isBtnNoVisible = true;
        component.isBtnCancelVisible = true;
        break;
    }

    switch (options.mainIcon) {
      case TaskDialogIcons.Information:
        component.iconSrc = '/assets/images/info.svg';
        break;
      case TaskDialogIcons.Error:
        component.iconSrc = '/assets/images/error.svg';
        break;
      case TaskDialogIcons.Question:
        component.iconSrc = '/assets/images/question.svg';
        break;
      case TaskDialogIcons.Warning:
        component.iconSrc = '/assets/images/warning.svg';
        break;
    }

    const retValue = new Promise<TaskDialogSimpleResult>((resolve, reject) => {
      this.resolveFunc = resolve;
    });
    component.onButtonPressed.on((button) => this.onButtonPressed(button));
    return retValue;
  }

  public onButtonPressed(button: TaskDialogButtons) {
    let retValue: TaskDialogSimpleResult;
    switch (button) {
      case TaskDialogButtons.Cancel:
        retValue = TaskDialogSimpleResult.Cancel;
        break;
      case TaskDialogButtons.Close:
        retValue = TaskDialogSimpleResult.Close;
        break;
      case TaskDialogButtons.No:
        retValue = TaskDialogSimpleResult.No;
        break;
      case TaskDialogButtons.None:
        retValue = TaskDialogSimpleResult.None;
        break;
      case TaskDialogButtons.OK:
        retValue = TaskDialogSimpleResult.Ok;
        break;
      case TaskDialogButtons.Retry:
        retValue = TaskDialogSimpleResult.Retry;
        break;
      case TaskDialogButtons.Yes:
        retValue = TaskDialogSimpleResult.Yes;
        break;
    }
    this.resolveFunc(retValue);
  }

  public async showMessage(messageText: string): Promise<TaskDialogSimpleResult> {
    const options: TaskDialogOptions = TaskDialogOptions.createDefault();
    options.content = messageText;
    options.commonButtons = TaskDialogCommonButtons.Close;
    options.title = 'Hinweis';
    return this.show(options);
  }

  public async showInfo(mainInstruction: string, content: string) {
    const config = new TaskDialogOptions();
    this.applyMainInstructionsAndContent(config, mainInstruction, content);
    config.commonButtons = TaskDialogCommonButtons.OK;
    config.mainIcon = TaskDialogIcons.Information;
    config.title = 'Hinweis';
    await this.show(config);
  }

  public async showInfoConfirmation(mainInstruction: string, content: string): Promise<boolean> {
    const config = new TaskDialogOptions();

    this.applyMainInstructionsAndContent(config, mainInstruction, content);
    config.commonButtons = TaskDialogCommonButtons.YesNo;
    config.mainIcon = TaskDialogIcons.Question;
    config.title = 'Frage';
    const result = await this.show(config);
    const retValue = result === TaskDialogSimpleResult.Yes;
    return retValue;
  }

  public async showWarning(mainInstruction: string, content: string) {
    const config = new TaskDialogOptions();

    this.applyMainInstructionsAndContent(config, mainInstruction, content);
    config.commonButtons = TaskDialogCommonButtons.OK;
    config.mainIcon = TaskDialogIcons.Warning;
    config.title = 'Warnung';
    await this.show(config);
  }

  public async showWarningConfirmation(mainInstruction: string, content: string): Promise<boolean> {
    const config = new TaskDialogOptions();

    this.applyMainInstructionsAndContent(config, mainInstruction, content);
    config.commonButtons = TaskDialogCommonButtons.YesNo;
    config.mainIcon = TaskDialogIcons.Warning;
    config.title = 'Warnung';

    const retValue = await this.show(config) === TaskDialogSimpleResult.Yes;
    return retValue;
  }

  public async showError(mainInstruction: string, content: string) {
    const config = new TaskDialogOptions();

    this.applyMainInstructionsAndContent(config, mainInstruction, content);
    config.commonButtons = TaskDialogCommonButtons.OK;
    config.mainIcon = TaskDialogIcons.Error;
    config.title = 'Fehler';
    await this.show(config);
  }

  public async showException(mainInstruction: string, content: string, expandedInfo: any = null) {
    const config = new TaskDialogOptions();

    this.applyMainInstructionsAndContent(config, mainInstruction, content);
    if (expandedInfo !== null) {
      let exception: TaskDialogException = this.prepareExceptionInfo(expandedInfo);
      config.expandedInfo = exception.message + '\n\nStacktrace:\n' + exception.stackTrace;
    }
    config.commonButtons = TaskDialogCommonButtons.Close;
    config.mainIcon = TaskDialogIcons.Error;
    config.title = 'Fehler';
    await this.show(config);
  }

  public async showApplicationException(exception: any, extendedMessage: string = "") {
    let taskDialogException: TaskDialogException = this.prepareExceptionInfo(exception);
    var content = "";
    if(extendedMessage !== ""){
      content += extendedMessage + "<br><br>";
    }
    let mailLink = '<a href="mailto:kundenservice@fuer-sie-eg.de">kundenservice@fuer-sie-eg.de</a>';
    content += "Bitte wenden Sie sich an unser Kundenservice-Postfach ("+ mailLink + ") oder an Ihren Ansprechpartner im Vertrieb.";
    await this.showException("Es ist ein Problem aufgetreten", content, exception);
  }

  public async showExceptionConfirmation(mainInstruction: string, content: string, expandedInfo: any = null): Promise<boolean> {
    const config = new TaskDialogOptions();

    this.applyMainInstructionsAndContent(config, mainInstruction, content);
    if (expandedInfo != null) {
      let exception: TaskDialogException = this.prepareExceptionInfo(expandedInfo);
      config.expandedInfo = exception.message + '\n\nStacktrace:\n' + exception.stackTrace;
    }
    config.commonButtons = TaskDialogCommonButtons.YesNo;
    config.mainIcon = TaskDialogIcons.Error;
    config.title = 'Fehler';
    const retValue = await this.show(config) === TaskDialogSimpleResult.Yes;
    return retValue;
  }


  private applyMainInstructionsAndContent(config: TaskDialogOptions, mainInstruction: string, content: string) {
    if (mainInstruction === '') {
      config.mainInstruction = content;
    } else {
      config.mainInstruction = mainInstruction;
      config.content = content;
    }
  }

  private prepareExceptionInfo(exception: any): TaskDialogException {
    let taskDialogException: TaskDialogException = new TaskDialogException();

    // process different exception types
    if (exception instanceof ApiException) {            // parse exception, use message and exception
      let remoteException = null;
      try {
        remoteException = JSON.parse(exception.response);
      } catch (e) {
        exception.stack += " " + exception.response;
      }
      if (remoteException != null) {
        if (remoteException.message !== undefined) {
          taskDialogException.message = remoteException.message;
          taskDialogException.stackTrace = remoteException.exception.StackTraceString;
        } else {
          taskDialogException.message = remoteException.title;
          taskDialogException.stackTrace = exception.response;
        }
      } else {
        this.handleDefaultException(taskDialogException, exception);
      }
    } else if (exception instanceof HttpErrorResponse) {    // exception is in error, use message and exception
      let remoteException = exception.error;
      taskDialogException.message = remoteException.message;
      taskDialogException.stackTrace = remoteException.exception.StackTraceString;
    } else {  // common typescript exception
      this.handleDefaultException(taskDialogException, exception);
    }

    return taskDialogException;
  }

  private handleDefaultException(taskDialogException: TaskDialogException, exception: any) {
    // common typescript exception
    taskDialogException.message = exception.message;
    taskDialogException.stackTrace = exception.stack;
  }
}
