import {Injectable} from "@angular/core";
import {FunctionModuleBase} from "../../../framework/functionmodule/function-module-base";
import {UserManagerService} from "../../../usermanager/usermanager.service";
import {Router} from "@angular/router";
import {ConfigurationService} from "../../../configuration/configuration.service";

@Injectable({
	providedIn: 'root'
})
export class Lieferantsicht_kompaktFunctionModule extends FunctionModuleBase {
	contractName = "Lieferantsicht_Kompakt";
	functionName = "Kompakt Lieferantensicht";
  groupName = "Funktionen";
	routerPath = "lieferantsicht-kompakt";
	externalURLPath = "https://kompaktportal.fuersie-it.de/lieferant";
  component = null;
  homeTileClass = "tileGroupClass4";
  homeTileImageSource = './assets/images/Liste.svg';
  homeTileSubImageSource = '/assets/images/Getraenkevermarktung_Kompakt_GmbH_CMYK.svg';
  homeTileSubTitle = "Weiterleitung zur Kompakt Lieferantenansicht";

	constructor(protected userManagerService: UserManagerService,
              private configurationService: ConfigurationService,
              protected router: Router) {
		super(userManagerService, router);
		this.configureFunctionModule();

		this.externalURLPath = configurationService.kompaktLieferantsicht;
	}

  configureModuleRoutes() {
    super.configureModuleRoutes();
  }
}
