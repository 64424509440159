import {isPlatformBrowser} from "@angular/common";

export class DetailPanelSettings {
  detailPanelWidth: number;
  detailPanelVisible: boolean;

  public static saveDetailPanelSettings(detailPanelSettings, settingsName, platformId) {
    if (isPlatformBrowser(platformId)) {
      localStorage.setItem(settingsName, JSON.stringify(detailPanelSettings));
    }
  }

  public static loadDetailPanelSettings(settingsName, platformId) {
    let detailPanelSettings: DetailPanelSettings = null;

    if (isPlatformBrowser(platformId)) {
      detailPanelSettings = JSON.parse(localStorage.getItem(settingsName));
    }

    return detailPanelSettings;
  }
}
