import {AfterViewInit, Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {RecordWindow} from "../../../../framework/functionwindow/record-window";
import {ActivatedRoute, Router} from "@angular/router";
import {WaitCursor, WaitCursorService} from "../../../../framework/wait-cursor/wait-cursor.service";
import {
  NachweisDateiEditData,
  PartnerkonditionEditData,
  PartnersichtClient
} from "../../../../webapi/webapi.service";
import {NgForm} from "@angular/forms";
import {InteractionController} from "../../../../framework/interaction-controller/interaction.controller";
import {Using} from "../../../../framework/using/using";
import {DxDataGridComponent} from "devextreme-angular";
import {UserManagerService} from "../../../../usermanager/usermanager.service";
import {YearselectionService} from "../../../../shared/services/yearselection.service";
import {Observable} from "rxjs";
import {IBreadcrumb} from "../../../../shared/interfaces/ibreadcrumb";
import {CustomBreadcrumbEvent} from "../../../../shared/events/custom-breadcrumb-event";
import {PartnersichtFunctionModule} from "../../functionsmodules/partnersicht-function-module.service";
import {EventAggregator} from "../../../../framework/event-aggregator/event.aggregator";
import {DxGridSettings} from "../../../../framework/grid/dx-grid-settings";
import {ZeitraumCanChangeEvent} from "../../../../shared/events/zeitraum-can-change-event";
import {ZeitraumChangedEvent} from "../../../../shared/events/zeitraum-changed-event";
import {ConfigurationService} from "../../../../configuration/configuration.service";

@Component({
  selector: 'app-partnersichtgegenleistung',
  templateUrl: './partnersicht-gegenleistung.component.html',
  host: {class: 'container-fluid d-flex h-100 flex-column p-0 bg-detailtoolbar'}
})
export class PartnersichtGegenleistungComponent extends RecordWindow implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('dialogForm') dialogForm: NgForm;
  @ViewChild('grid') dataGrid: DxDataGridComponent;

  seitennummernPattern: any = /^\d+(;\d+)*?$/;
  partnerId: number;
  dataSourceList: PartnerkonditionEditData[] = [];
  loadUrl: string;
  loadParams;
  rootObject: any = {};
  gridIsValid: boolean = true;
  fileData: any = null;
  uploadData: string;
  gridKey: string[] = ["partnerkonditionId"];
  selectedFile: File;
  monat: number = 1;
  isDateEvent: boolean = false;
  isSaveEvent: boolean = false;
  filterOperations: any = ["=", "contains", "<>", "<", ">", "<=", ">=", "between"];
  maxFileSize: number;
  maxFileSizeConverted: string;

  constructor(@Inject('BASE_URL') baseUrl: string,
              @Inject(PLATFORM_ID) protected platformId: any,
              protected router: Router,
              private configurationService: ConfigurationService,
              protected activatedRoute: ActivatedRoute,
              public waitCursorService: WaitCursorService,
              protected functionModule: PartnersichtFunctionModule,
              private eventAggregator: EventAggregator,
              public userManagerService: UserManagerService,
              public yearselectionService: YearselectionService,
              private  client: PartnersichtClient) {
    super();

    if (this.activatedRoute.snapshot.parent.params.id !== undefined) {
      this.partnerId = this.activatedRoute.snapshot.parent.params.id;
    }
    if (window.history.state.rootObjectOffer !== undefined) {
      this.rootObject = window.history.state.rootObjectOffer;
    }
    if (configurationService.infoText != null || configurationService.infoText != "") {
      this.maxFileSize = configurationService.maxFileSize;
      this.maxFileSizeConverted = this.formatBytes(this.maxFileSize)
    }
    this.publishCustomBreadCrumb();
    this.eventAggregator.getEvent(ZeitraumCanChangeEvent).publish(this);
  }

  get isModuleUrl(): boolean {
    return true;
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  async createDatasourceList() {
    try {
      await Using.using(new WaitCursor(this.waitCursorService), async () => {
        this.dataSourceList = await this.client.lieferantenkonditionById(this.yearselectionService.year, this.partnerId).toPromise();
      });
    } catch (e) {
      await InteractionController.MessageBox.showApplicationException(e);
      this.router.navigate(['.'], {relativeTo: this.activatedRoute.parent});
    }
  }

  loadState = () => {
    return DxGridSettings.loadDataGridSettings(this.dataGrid, "Partnersicht-GegenleistungAbgabe", this.platformId);
  };

  saveState = (state) => {
    DxGridSettings.saveDataGridSettings(state, "Partnersicht-GegenleistungAbgabe", this.platformId);
  };

  getErfuellunsgrad(data: number): number {
    let retValue: number = 0;
    if (data != null) {
      retValue = data;
    }
    return retValue;
  }

  async ngOnInit() {
    try {
      await Using.using(new WaitCursor(this.waitCursorService), async () => {
        super.ngOnInit();

        if ((Object.keys(this.rootObject).length === 0)) {
          let partner = await this.client.getPartner(this.partnerId).toPromise();
          if (partner != null) {
            this.rootObject.bbn = partner.bbn;
          } else {
            await InteractionController.MessageBox.showWarning("Keine Partnerdaten", "Partnerdaten konnten nicht abgerufen werden. Möglicherweise existiert der Partner nicht.");
            this.router.navigate([`${this.functionModule.routerPath}`]);
          }
        }
        if ((Object.keys(this.rootObject).length !== 0)) {
          this.publishCustomBreadCrumb();
        }

        await this.createDatasourceList();
      });
    } catch (e) {
      await InteractionController.MessageBox.showApplicationException(e);
      this.router.navigate(['.'], {relativeTo: this.activatedRoute.parent});
    }
  }

  async ngAfterViewInit() {
    this.refresh();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.eventAggregator.getEvent(ZeitraumCanChangeEvent).publish(undefined);
  }

  onRowValidating(e) {
    if (this.gridIsValid) {
      this.gridIsValid = !!e.isValid;
    }
  }

  onCellPrepared(e) {
    if (e.rowType == "data" && (e.column.dataField == "seitennummern" || e.column.dataField == "anzahl")) {
      e.cellElement.className += " cellEntry";
    }
  }

  async save() {
    try {
      await Using.using(new WaitCursor(this.waitCursorService), async () => {
        if (this.selectedFile != null) {
          if (this.hasDataEdited()) {
            this.isSaveEvent = true;
            if (this.gridIsValid) {
              let editData: NachweisDateiEditData = new NachweisDateiEditData();
              editData.partnerkonditionEditData = this.dataSourceList;
              let substring = ";base64,";
              let substringIndex = this.fileData.indexOf(substring);
              editData.data = this.fileData.substring(substringIndex + substring.length);
              editData.fileName = this.selectedFile.name;
              editData.monat = this.monat;
              editData.zeitraum = this.yearselectionService.year;
              editData.bbn = this.rootObject.bbn;
              this.uploadData = this.fileData.substring(substringIndex + substring.length);
              await this.client.saveNachweisDatei(editData).toPromise();
              await InteractionController.MessageBox.showInfo("Datei hochgeladen", "Die Datei ist erfolgreich hochgeladen.");
              this.gridIsValid = true;
              this.router.navigate([`.`], {
                relativeTo: this.activatedRoute.parent,
                state: {id: this.partnerId}
              });
            } else {
              InteractionController.MessageBox.showWarning("Validierungsfehler",
                "Ein oder mehrere Validierungsfehler sind aufgetreten. Die fehlerhaften Eingaben sind rot markiert. Bitte korrigieren Sie Ihre Eingaben und versuchen Sie erneut den Datensatz zu speichern.");
              this.gridIsValid = true;
            }
          } else {
            InteractionController.MessageBox.showWarning("Fehlende Angaben",
              "Bitte fügen Sie Informationen zu Anzahl und Seitennummern in der Konditionsliste hinzu und versuchen Sie erneut den Datensatz zu speichern.");
          }
        } else {
          InteractionController.MessageBox.showWarning("Datei auswählen", "Bitte wählen Sie eine Datei aus.");
        }
      });
    } catch (e) {
      await InteractionController.MessageBox.showApplicationException(e,
        "Versuchen Sie den Nachweis noch einmal abzuspeichern.<br>" +
        "Bleibt der Fehler weiterhin bestehen, wenden Sie sich an die nachfolgenden Instruktion.");
    }
  }

  private hasDataEdited(): boolean {
    this.dataGrid.instance.saveEditData();
    let retValue: boolean = false;

    const data = this.dataSourceList;
    for (const item of data) {
      if (item.anzahl !== null && (item.seitennummern !== null || item.seitennummern !== "")) {
        return true;
      }
    }

    return retValue;
  }

  cancel() {
    this.router.navigate([`.`], {relativeTo: this.activatedRoute.parent});
  }

  onGridToolbarPreparing(e) {
    let toolbarItems = e.toolbarOptions.items;
    toolbarItems.forEach((item) => {
      if (item.name == "saveButton" || item.name == "revertButton") {
        item.visible = false;
      }
    });
    e.toolbarOptions.visible = false;
  }

  async handleFileInput(event) {
    let files: FileList = event.target.files;
    let file = files[0];

    if (file !== undefined && file != null) {
      let fileParts: string[] = file.name.split(".");
      if (fileParts[fileParts.length - 1].toLowerCase() == "pdf" || fileParts[fileParts.length - 1].toLowerCase() == "bmp" || fileParts[fileParts.length - 1].toLowerCase() == "png" || fileParts[fileParts.length - 1].toLowerCase() == "jpg" || fileParts[fileParts.length - 1].toLowerCase() == "gif") {
        if (file.size > this.maxFileSize) {
          InteractionController.MessageBox.showWarning("Dateigröße", "Die ausgewählte Datei ist zu groß. Maximale Dateigröße ist auf " + this.maxFileSizeConverted + " beschränkt.");
        } else {
          this.selectedFile = file;
          const reader = new FileReader();
          reader.onload = () => {
            this.fileData = reader.result;
          };
          reader.readAsDataURL(this.selectedFile);
        }
      } else {
        InteractionController.MessageBox.showWarning("Dateityp", "Es können nur Dateien mit den Erweiterungen .pdf, .bmp, .png, .jpg, .gif importiert werden. Bitte wählen Sie eine entsprechende Datei aus.");
      }
    }
  }

  seitennummernCallback(e) {
    if (e.data.anzahl == null || e.data.anzahl == "") {
      return true;
    } else {
      if (e.value != null) {
        return e.value != "";
      } else {
        return false;
      }
    }
  }

  anzahlCallback(e) {
    if (e.data.seitennummern == null || e.data.seitennummern == "") {
      return true;
    } else {
      if (e.value != null) {
        return e.value != "";
      } else {
        return false;
      }
    }
  }

  get fileName(): string {
    if (this.selectedFile != null)
      return this.selectedFile.name;
    else
      return "Datei auswählen";
  }

  refresh() {
    if (this.dataGrid !== undefined)
      this.dataGrid.instance.refresh();
  }

  async canDeactivate(): Promise<Observable<boolean> | boolean> {
    if (this.hasDataEdited() && this.isDateEvent == false && this.isSaveEvent == false) {
      return await InteractionController.MessageBox.showInfoConfirmation("Änderungen verwerfen",
        "Sie haben noch nicht gespeicherte Änderungen. Wollen Sie die Änderungen verwerfen?");
    } else {
      this.isDateEvent = false;
      this.isSaveEvent = false;
      return true;
    }
  }

  async canChangeDate(): Promise<boolean> {
    if (await InteractionController.MessageBox.showInfoConfirmation("Änderungen verwerfen",
      "Möchten Sie das hinzufügen eines Nachweises abbrechen?")) {
      this.isDateEvent = true;
      this.router.navigate([`.`], {relativeTo: this.activatedRoute.parent});
      return true;
    } else {
      return false;
    }
  }

  private publishCustomBreadCrumb() {
    if (this.userManagerService.userPartner.length == 1 && this.userManagerService.benutzerArt != 0) {
      let breadcrumbs: IBreadcrumb[] = [];
      let breadcrumb1: IBreadcrumb = {
        breadcrumbGroup: "",
        breadcrumbLabel: `Partner: ${this.rootObject.bbn}`,
        params: {},
        url: this.functionModule.routerPath + `/partner/${this.partnerId}`,
        state: {}
      };
      breadcrumbs.push(breadcrumb1);

      let breadcrumb2: IBreadcrumb = {
        breadcrumbGroup: `Konditionen`,
        breadcrumbLabel: "Nachweis",
        params: {},
        url: "",
        state: {}
      };
      breadcrumbs.push(breadcrumb2);
      this.eventAggregator.getEvent(CustomBreadcrumbEvent).publish(breadcrumbs);
    } else {
      let breadcrumbs: IBreadcrumb[] = [];

      let breadcrumb1: IBreadcrumb = {
        breadcrumbGroup: "",
        breadcrumbLabel: `Partner: ${this.rootObject.bbn}`,
        params: {},
        url: this.functionModule.routerPath,
        state: {id: this.partnerId}
      };
      breadcrumbs.push(breadcrumb1);

      let breadcrumb2: IBreadcrumb = {
        breadcrumbGroup: "",
        breadcrumbLabel: `Konditionen`,
        params: {},
        url: this.functionModule.routerPath + `/partner/${this.partnerId}`,
        state: {}
      };
      breadcrumbs.push(breadcrumb2);

      let breadcrumb3: IBreadcrumb = {
        breadcrumbGroup: "",
        breadcrumbLabel: "Nachweis",
        params: {},
        url: "",
        state: {}
      };
      breadcrumbs.push(breadcrumb3);
      this.eventAggregator.getEvent(CustomBreadcrumbEvent).publish(breadcrumbs);
    }
  }

  exportFileName() {
    let fileDate: string = new Date().getFullYear().toString() + '-' + (new Date().getMonth() + 1).toString() + '-' + new Date().getDate().toString();
    return "ParntersichtGegenleistung" + '-' + fileDate;
  }
}
