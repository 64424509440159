<nav aria-label="breadcrumb" class="bg-mainwindow" [hidden]="userManagerService.loginName == ''">
  <ol class="breadcrumb d-inline-flex">
    <ng-container *ngFor="let breadcrumbItem of breadcrumbs; let i = index;">
      <ng-container *ngIf="i == 0">
        <!-- group from first item, it there is a group and it is just the main modul view -->
        <ng-container *ngIf="breadcrumbItem.breadcrumbGroup != '' && breadcrumbs.length < 2">
          <li class="breadcrumb-item">{{ breadcrumbItem.breadcrumbGroup }}</li>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="i == (breadcrumbs.length-1);else elseBlock2">
        <!-- last item is the active view -->
        <li class="breadcrumb-item active" aria-current="page">{{ breadcrumbItem.breadcrumbLabel }}</li>
      </ng-container>
      <ng-template #elseBlock2>
        <!-- items in the middle are links -->
        <li class="breadcrumb-item"><a href="javascript:void(0)"
                                       (click)="navigate(breadcrumbItem)">{{ breadcrumbItem.breadcrumbLabel }}</a></li>
      </ng-template>
    </ng-container>
  </ol>
</nav>
<div class="breadcrumb-line"></div>
