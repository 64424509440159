import {NgModule} from '@angular/core';
import {mapToCanActivate, RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {AuthGuard} from './usermanager/auth.guard';
import {CanDeactivateGuard} from "./can-deactivate.guard";


const appRoutes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
    canActivate: mapToCanActivate([AuthGuard]),
    data: {breadcrumbGroup: 'FS PoKuLi', breadcrumbLabel: 'Home', animation: 'home'}
  },
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes,
      {
    initialNavigation: 'disabled' // <- turn off the initial redirect, used for redirect or hash routing strategy
    // <- turn off the initial redirect, used for redirect or hash routing strategy
})
  ],
  exports: [
    RouterModule
  ],
  providers: [
    CanDeactivateGuard
  ]
})
export class AppRoutingModule {
}
