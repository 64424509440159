<div class="modal-header">
  <div class="modal-title" id="modal-basic-title">{{title}}</div>
  <button type="button" class="btn-close" aria-label="Close" (click)="onClose()">

  </button>
</div>
<form #dialogForm="ngForm" (ngSubmit)="onOK()">
  <div class="modal-body">
    <div class="modal-center">
      <div class="form-group">
        <label for="anzahl" class="col-form-label">Anzahl:</label>
        <input ngbAutofocus type="number" class="form-control" id="anzahl" name="anzahl"
               [(ngModel)]="konditionNachweisDetail.anzahl" required
               ngbTooltip="Die Eingabe einer eindeutigen Anzahl ist erforderlich." tooltipClass="errortooltip"
               [disableTooltip]="!dialogForm.form.controls['anzahl']?.errors || !dialogForm.form.controls['anzahl']?.touched">
      </div>
      <div class="form-group mt-3">
        <label for="seitennummern" class="col-form-label">Seitennummern (Bsp: 3;5;10):</label>
        <input type="text" class="form-control" id="seitennummern" name="seitennummern"
               [(ngModel)]="konditionNachweisDetail.seitennummern" required maxlength="255"
			   pattern="^\d+(;\d+)*?$"
               ngbTooltip="Die Eingabe von Seitennummern ist erforderlich. Zahlen mit Semikolons trennen." tooltipClass="errortooltip"
               [disableTooltip]="!dialogForm.form.controls['seitennummern']?.errors || !dialogForm.form.controls['seitennummern']?.touched">
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary btn-sm dialog-button">OK</button>
      <button type="button" class="btn btn-outline-primary btn-sm dialog-button" (click)="onCancel()">Abbrechen
      </button>
    </div>
  </div>
</form>



