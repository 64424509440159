<div class="modal-header">
  <div class="modal-title" id="modal-basic-title">{{title}}</div>
  <button type="button" class="btn-close" aria-label="Close" (click)="onClose()">

  </button>
</div>

<form #dialogForm="ngForm" (ngSubmit)="onOK()">
  <div class="modal-body">
    <div class="modal-center">
      <div class="form-group">
        <label for="zeitraumJahr" class="col-form-label">Jahr:</label>
        <input ngbAutofocus type="number" class="form-control" id="zeitraumJahr" name="zeitraum.jahr" [(ngModel)]="zeitraum.jahr"
               required
               ngbTooltip="Die Eingabe eines eindeutigen Jahres ist erforderlich." tooltipClass="errortooltip"
               [disableTooltip]="!dialogForm.form.controls['zeitraum.jahr']?.errors || !dialogForm.form.controls['zeitraum.jahr']?.touched">
      </div>
      <div class="form-group">
        <label for="status" class="col-form-label">Status:</label>
        <select class="form-control form-select" id="status" name="status" [(ngModel)]="zeitraumStatus">
          <option value="0">Inaktiv</option>
          <option value="1">Aktiv</option>
        </select>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary btn-sm dialog-button">OK</button>
      <button type="button" class="btn btn-outline-primary btn-sm dialog-button" (click)="onCancel()">Abbrechen
      </button>
    </div>
  </div>
</form>



