import {Component, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {DialogBase} from "../../../../../framework/dialog/dialog.base";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {WaitCursor, WaitCursorService} from "../../../../../framework/wait-cursor/wait-cursor.service";
import {UserManagerService} from "../../../../../usermanager/usermanager.service";
import {NgForm} from "@angular/forms";
import {Using} from "../../../../../framework/using/using";
import {TaskDialogButtons} from "../../../../../framework/message-box/taskdialog/task-dialog-buttons";
import {InteractionController} from "../../../../../framework/interaction-controller/interaction.controller";
import {
  Gegenleistungsart,
  GegenleistungsartClient,
  Lieferantenkondition,
  LieferantenkonditionClient, LieferantenkonditionOverviewClient,
  ReturnCodesSaveLieferantkondition
} from "../../../../../webapi/webapi.service";
import {DxDataGridComponent} from "devextreme-angular";
import DevExpress from "devextreme";
import {GridHelper} from "../../../../../framework/grid/gridhelper";
import {Router} from "@angular/router";
import {YearselectionService} from "../../../../../shared/services/yearselection.service";
import {DxGridSettings} from "../../../../../framework/grid/dx-grid-settings";
import {LieferantenkonditionenpflegeFunctionModule} from "../../../functionmodules/lieferantenkonditionenpflege-function-module";

export class LieferantenkonditionenpflegeDialogParameter {
  lieferantenkonditionId: number;
}

@Component({
  selector: 'app-lieferantenkonditionenpflege-dialog',
  templateUrl: './lieferantenkonditionenpflege-dialog.component.html'
})
export class LieferantenkonditionenpflegeDialogComponent extends DialogBase implements OnInit {
  @ViewChild('dialogForm') dialogForm: NgForm;
  @ViewChild('grid') dataGrid: DxDataGridComponent;
  gridKey: string[] = ["id"];
  dataSource: DevExpress.data.CustomStore;
  loadUrl: string;
  title: string = 'Anschlusshäuser';
  loadParams;
  selectedId: number;
  filterOperations: any = [ "=", "contains", "<>", "<", ">", "<=", ">=", "between" ];

  get hasWritePermission(): boolean {
    return this.userManagerService.hasWritePermission(this.functionModule.contractName);
  }

  constructor(@Inject('BASE_URL') baseUrl: string,
              @Inject(PLATFORM_ID) protected platformId: any,
              public activeModal: NgbActiveModal,
              protected router: Router,
              private yearselectionService: YearselectionService,
              protected userManagerService: UserManagerService,
              public waitCursorService: WaitCursorService,
              private _userManagerService: UserManagerService,
              private gegenleistungsartClient: GegenleistungsartClient,
              private dialogParameter: LieferantenkonditionenpflegeDialogParameter,
              private client: LieferantenkonditionOverviewClient,
              protected functionModule: LieferantenkonditionenpflegeFunctionModule,) {
    super(activeModal, waitCursorService);
    this.loadUrl = baseUrl + 'api/lieferantenkonditionOverview/getNotAssignedPartnerByLieferantenId';
    this.loadParams = {lieferantId: dialogParameter.lieferantenkonditionId};
    this.dataSource = GridHelper.createGridDataSource(this.gridKey, this.loadUrl, this.router, this.userManagerService, null, this.loadParams);
  }

  async ngOnInit() {
  }

  changeValue(e) {
  }

  public async onOK() {
    try {
      await Using.using(new WaitCursor(this.waitCursorService), async () => {
        if (this.hasWritePermission) {
          if (this.dataGrid.selectedRowKeys.length > 0) {
            for (const item of this.dataGrid.selectedRowKeys) {
              let partnerId = item.id;
              let lieferantenkonditionId = this.dialogParameter.lieferantenkonditionId;

              let result = await this.client.save(partnerId, lieferantenkonditionId).toPromise();
            }

            this.close();
            this.onButtonPressed.emit(TaskDialogButtons.OK);
          } else{
            InteractionController.MessageBox.showWarning("Keine Auswahl", "Bitte selektieren Sie einen Eintrag aus der Liste und führen Sie die Operation erneut aus.");
          }
        }
      });
    } catch (e) {
      InteractionController.MessageBox.showApplicationException(e);
    }
  }

  public onGridToolbarPreparing(e) {
    let toolbarItems = e.toolbarOptions.items;
    toolbarItems.forEach((item) => {
      item.visible = false;
    });
    e.toolbarOptions.visible = false;
  }

  loadState = () => {
    return DxGridSettings.loadDataGridSettings(this.dataGrid, "LieferantenkonditionenDialog", this.platformId);
  };

  saveState = (state) => {
    DxGridSettings.saveDataGridSettings(state, "LieferantenkonditionenDialog", this.platformId);
  }
}
