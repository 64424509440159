import {Component, OnInit, ViewChild} from '@angular/core';
import {DialogBase} from "../../../../framework/dialog/dialog.base";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {WaitCursor, WaitCursorService} from "../../../../framework/wait-cursor/wait-cursor.service";
import {UserManagerService} from "../../../../usermanager/usermanager.service";
import {NgForm} from "@angular/forms";
import {Using} from "../../../../framework/using/using";
import {TaskDialogButtons} from "../../../../framework/message-box/taskdialog/task-dialog-buttons";
import {InteractionController} from "../../../../framework/interaction-controller/interaction.controller";
import {Partner, PartnerClient, ReturnCodesSavePartner} from "../../../../webapi/webapi.service";

export class PartnerDialogParameter {
  partnerId: number;
}

@Component({
  selector: 'app-partner-dialog',
  templateUrl: './partner-dialog.component.html'
})
export class PartnerDialogComponent extends DialogBase implements OnInit {
  @ViewChild('dialogForm') dialogForm: NgForm;
  title: string = 'Partner';
  partner: Partner = new Partner();

  constructor(public activeModal: NgbActiveModal,
              public waitCursorService: WaitCursorService,
              private _userManagerService: UserManagerService,
              private dialogParameter: PartnerDialogParameter,
              private partnerClient: PartnerClient) {
    super(activeModal, waitCursorService);
  }

  async ngOnInit() {
    try {
      await Using.using(new WaitCursor(this.waitCursorService), async () => {
        // create or load partner
        if (this.dialogParameter.partnerId == 0) {
          this.partner = new Partner();
          this.partner.id = 0;
        } else {
          let partner = await this.partnerClient.getPartner(this.dialogParameter.partnerId).toPromise();
          if (partner != null) {
            this.partner = partner;
          } else {
            await InteractionController.MessageBox.showWarning("Keine Partnerdaten", "Partnerdaten konnten nicht abgerufen werden. Möglicherweise existiert der Partner nicht.");
            this.close();
          }
        }
      });
    } catch (e) {
      await InteractionController.MessageBox.showApplicationException(e);
      this.close();
    }
  }

  public async onOK() {
    try {
      await Using.using(new WaitCursor(this.waitCursorService), async () => {
        if (this.dialogForm.valid) {
          if (this.dialogForm.dirty) {
            if (this.partner.bbn <= 2147483647) {
              // save
              this.partner.deleted = false;
              let retValue = await this.partnerClient.savePartner(this.partner).toPromise();
              this.dialogParameter.partnerId = retValue.id;

              // check return value
              if (retValue.returnCode == ReturnCodesSavePartner.Duplicate) {
                InteractionController.MessageBox.showWarning("Anschlusshaus", "Das eingegebene Anschlusshaus existiert bereits.");
                this.dialogForm.form.controls['partner.anschlusshaus'].setErrors({});
                this.dialogForm.form.controls['partner.anschlusshaus'].markAsTouched();
                return;
              } else if (retValue.returnCode == ReturnCodesSavePartner.DuplicateName) {
                InteractionController.MessageBox.showWarning("BBN", "Die eingegebene BBN existiert bereits.");
                this.dialogForm.form.controls['partner.bbn'].setErrors({});
                this.dialogForm.form.controls['partner.bbn'].markAsTouched();
                return;
              }
            }else{
              InteractionController.MessageBox.showWarning("BBN", 'Die eingegebene BBN darf den Wert "2147483647" nicht überschreiten.');
              this.dialogForm.form.controls['partner.bbn'].setErrors({});
              this.dialogForm.form.controls['partner.bbn'].markAsTouched();
              return;
            }
            // close dialog with OK
            this.close();
            this.onButtonPressed.emit(TaskDialogButtons.OK);
          } else	// no changes, just close
            this.close();
        } else {
          this.dialogForm.form.controls['partner.bbn'].markAsTouched();
          this.dialogForm.form.controls['partner.anschlusshaus'].markAsTouched();
          InteractionController.MessageBox.showWarning("Validierungsfehler",
            "Ein oder mehrere Validierungsfehler sind aufgetreten. Die fehlerhaften Eingaben sind rot markiert. Bitte korrigieren Sie Ihre Eingaben und versuchen Sie erneut den Datensatz zu speichern.");
        }
      });
    } catch (e) {
      InteractionController.MessageBox.showApplicationException(e);
    }
  }
}
