import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders } from '@angular/common/http';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    req.headers.append('Cache-Control', 'no-cache, no-store, must-revalidate')
    req.headers.append('Pragma', 'no-cache')
    req.headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
    req.headers.append('If-Modified-Since', '0')

    return next.handle(req);
  }
}
