import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {FunctionWindow} from "../../../../framework/functionwindow/function-window";
import {UserManagerService} from "../../../../usermanager/usermanager.service";
import {ActivatedRoute, Router} from "@angular/router";
import {WaitCursor, WaitCursorService} from "../../../../framework/wait-cursor/wait-cursor.service";
import {DxDataGridComponent} from "devextreme-angular";
import {ZeitraumChangedEvent} from "../../../../shared/events/zeitraum-changed-event";
import {EventAggregator} from "../../../../framework/event-aggregator/event.aggregator";
import {YearselectionService} from "../../../../shared/services/yearselection.service";
import {
  ChangeStatusDialogParameter,
  PartnersichtChangeStatusComponent
} from "../partnersicht-change-status/partnersicht-change-status.component";
import {InteractionController} from "../../../../framework/interaction-controller/interaction.controller";
import {IBreadcrumb} from "../../../../shared/interfaces/ibreadcrumb";
import {CustomBreadcrumbEvent} from "../../../../shared/events/custom-breadcrumb-event";
import {PartnersichtFunctionModule} from "../../functionsmodules/partnersicht-function-module.service";
import {PartnersichtClient} from "../../../../webapi/webapi.service";
import {Using} from "../../../../framework/using/using";

@Component({
  selector: 'app-partnersichtkondition',
  templateUrl: './partnersicht-kondition.component.html',
  host: {class: 'container-fluid d-flex h-100 flex-column p-0 bg-detailtoolbar'}
})
export class PartnersichtKonditionComponent extends FunctionWindow implements OnInit, OnDestroy {
  @ViewChild('gridKondition') dataGrid: DxDataGridComponent;
  gridKey: string[] = ["partnerkonditionId"];
  loadParamsKondition;
  partnerId: number;
  rootObject: any = {};
  subscribeLambda = year => {
    this.setYear(year);
  };

  public resolveSettingsName() {
    return super.resolveSettingsName() + "_Kondition";
  }

  statusLookupData = {
    store: {
      type: 'array',
      data: [
        {status: null, name: ''},
        {status: false, name: 'Nein'},
        {status: true, name: 'Ja'}
      ],
      key: "status"
    }
  };

  customizeTooltip = (pointsInfo) => {
    return {text: parseInt(pointsInfo.originalValue) + "%"};
  };

  get hasWritePermission(): boolean {
    return this.userManagerService.hasWritePermission(this.functionModule.contractName);
  }

  get showBackButton(): boolean {
    return (this.userManagerService.userPartner.length != 1 || this.userManagerService.benutzerArt == 0);
  }

  constructor(@Inject('BASE_URL') baseUrl: string,
              @Inject(PLATFORM_ID) protected platformId: any,
              public userManagerService: UserManagerService,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected waitCursorService: WaitCursorService,
              protected functionModule: PartnersichtFunctionModule,
              public yearselectionService: YearselectionService,
              private client: PartnersichtClient,
              private eventAggregator: EventAggregator) {
    super(platformId, userManagerService, router, waitCursorService, functionModule);
    this.eventAggregator.getEvent(ZeitraumChangedEvent).subscribe(this.subscribeLambda);

    this.initDetailPanelSettings();
    this.setDetailPanelValues();

    if (this.activatedRoute.snapshot.params.id !== undefined) {
      this.partnerId = this.activatedRoute.snapshot.params.id;
    }
    if (window.history.state.rootObjectOffer !== undefined) {
      this.rootObject = window.history.state.rootObjectOffer;
    }

    this.loadParamsKondition = {year: yearselectionService.year, partnerId: this.partnerId};
    this.loadUrl = baseUrl + 'api/partnersicht/querylieferantenkonditionbyid';
    this.createDataSource(this.loadParamsKondition);
  }

  async ngOnInit() {
    try {
      await Using.using(new WaitCursor(this.waitCursorService), async () => {
        super.ngOnInit();
        if ((Object.keys(this.rootObject).length === 0)) {
          let partner = await this.client.getPartner(this.partnerId).toPromise();
          if (partner != null) {
            this.rootObject.bbn = partner.bbn;
            this.rootObject.anschlusshaus = partner.anschlusshaus;
          } else {
            await InteractionController.MessageBox.showWarning("Keine Partnerdaten", "Partnerdaten konnten nicht abgerufen werden. Möglicherweise existiert der Partner nicht.");
            this.router.navigate([`${this.functionModule.routerPath}`]);
          }
        }
        if ((Object.keys(this.rootObject).length !== 0)) {
          this.publishCustomBreadCrumb();
        }
      });
    } catch (e) {
      await InteractionController.MessageBox.showApplicationException(e);
      this.router.navigate(['.'], {relativeTo: this.activatedRoute.parent});
    }
  }

  private publishCustomBreadCrumb() {
    if (this.userManagerService.userPartner.length == 1 && this.userManagerService.benutzerArt != 0) {
      let breadcrumbs: IBreadcrumb[] = [];
      let breadcrumb1: IBreadcrumb = {
        breadcrumbGroup: `Partner: ${this.rootObject.bbn}`,
        breadcrumbLabel: "Konditionen",
        params: {},
        url: "",
        state: {}
      };
      breadcrumbs.push(breadcrumb1);
      this.eventAggregator.getEvent(CustomBreadcrumbEvent).publish(breadcrumbs);
    } else {
      let breadcrumbs: IBreadcrumb[] = [];
      let breadcrumb1: IBreadcrumb = {
        breadcrumbGroup: "",
        breadcrumbLabel: `Partner: ${this.rootObject.bbn}`,
        params: {},
        url: this.functionModule.routerPath,
        state: {id: this.partnerId}
      };
      breadcrumbs.push(breadcrumb1);

      let breadcrumb2: IBreadcrumb = {
        breadcrumbGroup: "",
        breadcrumbLabel: "Konditionen",
        params: {},
        url: "",
        state: {}
      };
      breadcrumbs.push(breadcrumb2);
      this.eventAggregator.getEvent(CustomBreadcrumbEvent).publish(breadcrumbs);
    }
  }

  onMainModuleNavigation(state: any) {
    super.onMainModuleNavigation(state);
    this.publishCustomBreadCrumb();
    // we navigated back from client
    if (window.history.state.id !== undefined && window.history.state.id != 0) {
      if (this.dataGrid !== undefined) {
        this.dataGrid.selectedRowKeys = [{id: +window.history.state.id}];
      }
    }
  }

  onRowPrepared(e) {
    if (e.rowType == "data" && e.data.erfuellung == null){
        e.rowElement.className += " cls";
    }
  }

  get isModuleUrl(): boolean {
    if (this.functionModule != null) {
      let url = this.router.url;
      url = url.replace(`/${this.functionModule.routerPath}`, "");
      let urlParts: string[] = url.split("/");
      return urlParts.length == 3;	// second navigation level
    } else
      return true;
  }

  refresh() {
    super.refresh();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.eventAggregator.getEvent(ZeitraumChangedEvent).unsubscribe(this.subscribeLambda);
  }

  setYear(year: number) {
    this.loadParamsKondition = {year: year, partnerId: this.partnerId};
    this.createDataSource(this.loadParamsKondition);
  }

  back() {
    this.router.navigate(['.'], {relativeTo: this.activatedRoute.parent, state: {id: this.partnerId}});
  }

  navigateToKondition(rowData: any) {
    rowData.bbn = this.rootObject.bbn;
    rowData.anschlusshaus = this.rootObject.anschlusshaus;
    this.router.navigate([`nachweis/${rowData.partnerkonditionId}`], {
      relativeTo: this.activatedRoute,
      state: {rootObjectOffer: rowData}
    });
  }

  async onDblClick(e) {
    this.changeStatus();
  }

  getErfuellunsgrad(data: number) : number {
    let retValue: number = 0;
    if (data != null){
      retValue = data;
    }
    return retValue;
  }

  async changeStatus() {
    if (this.hasWritePermission && (this.yearselectionService.zeitraumActive || this.userManagerService.isUserAdmin())) {
      if (this.dataGrid.selectedRowKeys.length > 0) {
        let parameter: ChangeStatusDialogParameter = new ChangeStatusDialogParameter();
        parameter.currentStatus = this.dataGrid.instance.getSelectedRowsData()[0].erfuellung;
        for (let row of this.dataGrid.selectedRowKeys) {
          parameter.ids.push(row.partnerkonditionId);
        }
        if (await InteractionController.showDialog(PartnersichtChangeStatusComponent, parameter)) {
          this.dataGrid.instance.refresh();
        }
      } else
        InteractionController.MessageBox.showWarning("Keine Auswahl", "Bitte selektieren Sie einen Eintrag aus der Liste und führen Sie die Operation erneut aus.");
    }
  }

  newGegenleistung() {
    this.router.navigate([`nachweis/`], {
      relativeTo: this.activatedRoute,
      state: {rootObjectOffer: this.rootObject}
    });
  }
}
