export class ModuleRoute {
  path: string;
  breadcrumbLabel: string;
  component: any;
  permissionKey: string;
  moduleRoutes?: ModuleRoute[] = [];

  constructor(path: string, breadcrumbLabel: string, component: any, permissionKey: string) {
    this.path = path;
    this.breadcrumbLabel = breadcrumbLabel;
    this.component = component;
    this.permissionKey = permissionKey;
  }
}
