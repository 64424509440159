<div class="detailarea sticky-top" [hidden]="!isModuleUrl">
  <nav class="navbar navbar-expand detailtoolbar">
    <div class="navbar-collapse collapse">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item mr-1" *ngIf="showBackButton">
          <a class="nav-link navlink-hover" (click)="back()">
            <img src="./assets/images/Back.svg"/><span>Zurück</span>
          </a>
        </li>
        <li class="nav-item mr-1">
          <a class="nav-link navlink-hover" (click)="export()">
            <img src="./assets/images/ExcelExport.svg"/><span>Exportieren</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link navlink-hover" (click)="refresh()">
            <img src="./assets/images/Refresh.svg"/><span>Aktualisieren</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link navlink-hover" (click)="reset()">
            <img src="./assets/images/Reset.svg"/><span>Zurücksetzen</span>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</div>

<div class="d-flex flex-column flex-grow-1 overflow-hidden" [hidden]="!isModuleUrl"
     [ngClass]="!isModuleUrl ? 'display-none-important' : ''">
  <as-split direction="horizontal" class="vh-100-safari" [gutterSize]="splitterSize"
            (gutterClick)="splitterClick($event)" (dragEnd)="splitterDragEnd($event)">
    <as-split-area [size]="sizeMainPanel">
      <dx-data-grid #grid class="d-flex flex-column h-100 vh-100-safari w-100 mw-100" style="height: 100px"
                    [dataSource]="dataSource"
                    [allowColumnReordering]="true"
                    [allowColumnResizing]="true"
                    columnResizingMode="widget"
                    [showBorders]="true"
                    [showColumnLines]="true"
                    [showRowLines]="true"
                    [remoteOperations]="true"
                    [wordWrapEnabled]="true"
                    [hoverStateEnabled]="true"
                    [errorRowEnabled]="true"
                    (onExporting)="onGridExporting($event)"
                    (onToolbarPreparing)="onGridToolbarPreparing($event)"
                    (onKeyDown)="onKeyDown($event)"
      >

        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-filter-panel [visible]="true"></dxo-filter-panel>
        <dxo-filter-builder-popup></dxo-filter-builder-popup>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-group-panel [visible]="false"></dxo-group-panel>
        <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
        <dxo-selection mode="single"></dxo-selection>
        <dxo-grouping #expand [autoExpandAll]="false"></dxo-grouping>
        <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
        <dxo-load-panel [showPane]="false"></dxo-load-panel>
        <dxo-export [enabled]="true" [fileName]="fileName()" [allowExportSelectedData]="false"></dxo-export>

        <dxi-column dataField="partnerId" caption="FS Partner Id" alignment="left" minWidth="100" dataType="number"
                    [visible]="false" [allowFiltering]="false" [allowGrouping]="false"></dxi-column>
        <dxi-column dataField="partnerkonditionId" caption="Partnerkondition Id" alignment="left" minWidth="100"
                    dataType="number"
                    [visible]="false" [allowFiltering]="false" [allowGrouping]="false"></dxi-column>
        <dxi-column dataField="bbn" caption="BBN" alignment="left" width="75" dataType="number" [filterOperations]="filterOperations"></dxi-column>
        <dxi-column dataField="anschlusshaus" caption="Anschlusshaus" alignment="left" minWidth="100"
                    dataType="string" cellTemplate="linkTemplate"></dxi-column>
        <dxi-column dataField="ksnr" caption="KS-Nr." alignment="left"minWidth="65" width="65"
                    dataType="number"></dxi-column>
        <dxi-column dataField="ksbezeichnung" caption="KS-Bezeichnung" alignment="left" minWidth="100"
                    dataType="string"></dxi-column>
        <dxi-column dataField="anzahlGegenleistungen" caption="Anzahl Gegenleistungen" alignment="left" width="100"
                    dataType="number"></dxi-column>
        <dxi-column dataField="anzahlArtikel" caption="Anzahl Artikel" alignment="left" width="100"
                    dataType="number"></dxi-column>
        <dxi-column dataField="summe" caption="Summe" alignment="left" width="100" dataType="number"></dxi-column>
        <dxi-column dataField="gegenleistungsart" caption="Gegenleistungsart" alignment="left" minWidth="100"
                    dataType="string"></dxi-column>
        <dxi-column dataField="bemerkungExtern" caption="Bemerkung" alignment="left" minWidth="100"
                    dataType="string"></dxi-column>
        <dxi-column dataField="erfuellung" caption="Nimmt an HZ-Vermarktung teil" alignment="left" minWidth="100"
                    dataType="boolean">
          <dxo-lookup [dataSource]="statusLookupData" valueExpr="status" displayExpr="name"></dxo-lookup>
        </dxi-column>
        <dxi-column dataField="erfuellungsgrad" caption="Erfüllungsgrad in %" alignment="left" minWidth="100"
                    dataType="number" cellTemplate="percentTemplate"></dxi-column>

        <div *dxTemplate="let data of 'percentTemplate'">
          <ng-container *ngIf="data.data.erfuellung == 1">
            <div class="progress position-relative grid-progress esoes-progress" role="progressbar">
              <div class="progress-bar bg-success esoes-progress-bar"
                   [style.width]="getErfuellunsgrad(data.value)+'%'">
              </div>
              <span class="position-absolute w-100 grid-progress-text esoes-progress-span">
                {{getErfuellunsgrad(data.value)}} %
              </span>
            </div>
          </ng-container>
        </div>
        <div *dxTemplate="let data of 'linkTemplate'">
          <a href="javascript:void(0)" (click)="navigateToNachweis(data.data)">{{data.value}}</a>
        </div>
        <dxo-summary>
          <dxi-total-item column="erfuellungsgrad" summaryType="count" alignment="right">
          </dxi-total-item>
        </dxo-summary>
      </dx-data-grid>
    </as-split-area>
    <as-split-area [size]="sizeDetailPanel" class="d-flex flex-column flex-grow-1">
      <div class="h-100 container-fluid detailpanel">
        <form class="p-3">
          <!-- Lieferantendaten -->
          <div class="row">
            <div class="card col-12 p-0">
              <div class="card-header p-2 detailpanel-cardheader">Lieferantendaten</div>
              <div class="card-body pb-1">
                <div class="form-group row mb-3">
                  <label class="form-control-sm col-form-label ms-2 detailpanel-label">WAWI-NR.:</label>
                  <div class="col">
                    <input type="text" class="form-control form-control-sm" name="rootObject.wawinr"
                           [(ngModel)]="rootObject.wawinr" readonly>
                  </div>
                </div>
                <div class="form-group row mb-3">
                  <label class="form-control-sm col-form-label ms-2 detailpanel-label">VL-Nr.:</label>
                  <div class="col">
                    <input type="text" class="form-control form-control-sm" name="rootObject.vlnr"
                           [(ngModel)]="rootObject.vlnr" readonly>
                  </div>
                </div>
                <div class="form-group row mb-3">
                  <label class="form-control-sm col-form-label ms-2 detailpanel-label">Name:</label>
                  <div class="col">
                    <input type="text" class="form-control form-control-sm" name="rootObject.name"
                           [(ngModel)]="rootObject.name" readonly>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </as-split-area>
  </as-split>
</div>

<ng-container *ngIf="!isModuleUrl">
  <router-outlet></router-outlet>
</ng-container>
