import {Injectable} from "@angular/core";
import {FunctionModuleBase} from "../../../framework/functionmodule/function-module-base";
import {UserManagerService} from "../../../usermanager/usermanager.service";
import {Router} from "@angular/router";
import {LieferantenkonditionenComponent} from "../konditionen/lieferanten/lieferantenkonditionen.component";

@Injectable({
  providedIn: 'root'
})
export class LieferantenkonditionenFunctionModule extends FunctionModuleBase {
  contractName = "Lieferantenkonditionen";
  functionName = "Konditionen";
  groupName = "Konditionen";
  routerPath = "lieferantenkonditionen";
  component = LieferantenkonditionenComponent;
  homeTileClass = "tileGroupClass2";
  homeTileImageSource = './assets/images/Home.svg';
  homeTileSubTitle = "Übersicht der Lieferantenkonditionen";

  constructor(protected userManagerService: UserManagerService, protected router: Router) {
    super(userManagerService, router);
    this.configureFunctionModule();
  }

  configureModuleRoutes() {
    super.configureModuleRoutes();
  }
}
