import {Injectable} from "@angular/core";
import {FunctionModuleBase} from "../../../framework/functionmodule/function-module-base";
import {UserManagerService} from "../../usermanager.service";
import {Router} from "@angular/router";
import {UserFunctionWindowComponent} from "../user-function-window/user-function-window.component";
import {UserPermissionDefinition} from "../../model/user-permission-definition";
import {ModuleRoute} from "../../../framework/functionmodule/module-route";
import {UserPermissionSuffix} from "../../model/user-permission-suffix";
import {UserRecordWindowComponent} from "../user-record-window/user-record-window.component";

@Injectable({
  providedIn: 'root'
})
export class UserFunctionModule extends FunctionModuleBase {
  contractName = UserManagerService.permissionUserManagerKey;
  functionName = "Benutzerverwaltung";
  groupName = "Administration";
  routerPath = "userfunction";
  component = UserFunctionWindowComponent;
  breadcrumbGroup = "Administration";
  homeTileClass = "tileGroupClass5";
  homeTileImageSource = './assets/images/UserManager.svg';
  homeTileSubTitle = "Benutzer verwalten";
  homeTileVisible = true;

  constructor(protected userManagerService: UserManagerService, protected router: Router) {
    super(userManagerService, router);
    this.configureFunctionModule();
  }

  configureFunctionModule() {
    this.userPermissions.push(new UserPermissionDefinition(UserManagerService.permissionUserManagerText, `${this.contractName}`));

    this.configureBreadcrumb();
    this.configureModuleRoutes();
    this.registerModuleRoutes();
  }

  configureModuleRoutes() {
    super.configureModuleRoutes();

    this.moduleRoutes.push(
      new ModuleRoute('user', 'Benutzer hinzufügen', UserRecordWindowComponent, `${this.contractName}`),
      new ModuleRoute('user/:id', 'Benutzer bearbeiten', UserRecordWindowComponent, `${this.contractName}`)
    );
  }
}
