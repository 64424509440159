import {AfterViewInit, Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {RecordWindow} from "../../../framework/functionwindow/record-window";
import {ActivatedRoute, Router} from "@angular/router";
import {WaitCursor, WaitCursorService} from "../../../framework/wait-cursor/wait-cursor.service";
import {
  ReturnCodesSave,
  UserLieferant,
  UserManagerClient, UserPartner,
  UserPermission,
  UserRoles,
  Users
} from "../../../webapi/webapi.service";
import {NgForm} from "@angular/forms";
import {UserManagerService} from "../../usermanager.service";
import {InteractionController} from "../../../framework/interaction-controller/interaction.controller";
import {nameof} from "../../../framework/tools/nameof";
import {Using} from "../../../framework/using/using";
import {TabHelper} from "../../../framework/tools/tabhelper";
import {DxDataGridComponent} from "devextreme-angular";
import DevExpress from "devextreme";
import {GridHelper} from "../../../framework/grid/gridhelper";
import {DxGridSettings} from "../../../framework/grid/dx-grid-settings";

export class RoleDefinition {
  roleId: number;
  roleName: string;
  permission: boolean;
}

@Component({
  selector: 'app-user-record-window',
  templateUrl: './user-record-window.component.html',
  host: {class: 'container-fluid d-flex h-100 flex-column p-0 bg-detailtoolbar'}
})
export class UserRecordWindowComponent extends RecordWindow implements OnInit, OnDestroy {
  @ViewChild('dialogForm') dialogForm: NgForm;
  @ViewChild('gridPartner') dataGridPartner: DxDataGridComponent;
  @ViewChild('gridLieferant') dataGridLieferant: DxDataGridComponent;

  user: Users = new Users();
  userPermissions: UserPermission[];
  rolePermissions: RoleDefinition[];
  userId: number = 0;
  dataSourcePartner: DevExpress.data.CustomStore;
  dataSourceLieferant: DevExpress.data.CustomStore;
  loadUrlPartner: string;
  loadUrlLieferant: string;
  gridKey: string[] = ["id"];

  constructor(@Inject('BASE_URL') baseUrl: string,
              @Inject(PLATFORM_ID) protected platformId: any,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              public waitCursorService: WaitCursorService,
              public userManagerService: UserManagerService,
              private  userManagerClient: UserManagerClient) {
    super();

    this.loadUrlLieferant = baseUrl + 'api/usermanager/lieferantlist';
    this.loadUrlPartner = baseUrl + 'api/usermanager/partnerlist';
    this.createDataSources();

    if (this.activatedRoute.snapshot.params.id !== undefined)
      this.userId = this.activatedRoute.snapshot.params.id;

    // prepare permissions array
    this.userPermissions = [];
    for (let perm of userManagerService.permissions) {
      let userPermission: UserPermission = new UserPermission();
      userPermission.permissionKey = perm.permissionKey;
      userPermission.permission = false;
      this.userPermissions.push(userPermission);
    }
  }

  createDataSources(loadParams: Object = undefined) {
    this.dataSourcePartner = GridHelper.createGridDataSource(this.gridKey, this.loadUrlPartner, this.router, this.userManagerService, null, loadParams);
    this.dataSourceLieferant = GridHelper.createGridDataSource(this.gridKey, this.loadUrlLieferant, this.router, this.userManagerService, null, loadParams);
  }

  async ngOnInit() {
    TabHelper.makeTabsSameHeight();

    try {
      await Using.using(new WaitCursor(this.waitCursorService), async () => {
        super.ngOnInit();

        // get roles
        let userManagerRoles = await this.userManagerClient.getRoles().toPromise();
        this.rolePermissions = [];
        for (let role of userManagerRoles) {
          let roleDefinition: RoleDefinition = new RoleDefinition();
          roleDefinition.roleId = role.roleId;
          roleDefinition.roleName = role.roleName;
          roleDefinition.permission = false;

          this.rolePermissions.push(roleDefinition);
        }

        // create or load user
        if (this.userId == 0) {
          this.user = new Users();
          this.user.userId = 0;
          this.user.benutzerart = 1;
        } else {
          let user = await this.userManagerClient.getUser(this.userId).toPromise();
          if (user != null) {
            this.user = user;
            // permissions
            for (let perm of this.user.userPermission) {
              let userPermission = this.userPermissions.find(x => x.permissionKey == perm.permissionKey);
              if (userPermission !== undefined)
                userPermission.permission = perm.permission;
            }

            // roles
            for (let role of this.user.userRoles) {
              let roleDefinition = this.rolePermissions.find(x => x.roleId == role.roleId);
              if (roleDefinition !== undefined)
                roleDefinition.permission = true;
            }

            if (this.user.userLieferant != null) {
              let lieferantIDs = this.user.userLieferant.map(function (p) {
                return {id: p.lieferantId};
              });
              // this.dataGridLieferant.selectedRowKeys = lieferantIDs;
              this.dataGridLieferant.instance.selectRows(lieferantIDs, false);
            }

            if (this.user.userPartner != null) {
              let partnerIds = this.user.userPartner.map(function (p) {
                return {id: p.partnerId};
              });
              // this.dataSourcePartner.selectedRowKeys = lieferantIDs;
              this.dataGridPartner.instance.selectRows(partnerIds, false);
            }
          } else {
            await InteractionController.MessageBox.showWarning("Keine Benutzerdaten", "Benutzerdaten konnten nicht abgerufen werden. Möglicherweise existiert der Benutzer nicht.");
            this.router.navigate(['.'], {relativeTo: this.activatedRoute.parent});
          }
        }
      });
    } catch (e) {
      await InteractionController.MessageBox.showApplicationException(e);
      this.router.navigate(['.'], {relativeTo: this.activatedRoute.parent});
    }
  }

  async save() {
    try {
      await Using.using(new WaitCursor(this.waitCursorService), async () => {
        if (this.dialogForm.valid) {
          let userId: number = 0;
          if (this.dialogForm.dirty) {
            // apply user permissions
            this.user.userPermission = [];
            for (let perm of this.userPermissions) {
              if (perm.permission) {
                let userPermission = new UserPermission();
                userPermission.permission = perm.permission;
                userPermission.permissionKey = perm.permissionKey;
                userPermission.userId = this.user.userId;

                this.user.userPermission.push(userPermission);
              }
            }

            // apply user roles
            this.user.userRoles = [];
            for (let role of this.rolePermissions) {
              if (role.permission) {
                let userRole = new UserRoles();
                userRole.roleId = role.roleId;
                userRole.userId = this.user.userId;

                this.user.userRoles.push(userRole);
              }
            }

            this.user.userLieferant = [];
            let selectedRowsDataLieferant = this.dataGridLieferant.instance.getSelectedRowsData();
            for (let i = 0; i < selectedRowsDataLieferant.length; i++) {
              let userLieferant = new UserLieferant();
              userLieferant.lieferantId = selectedRowsDataLieferant[i].id;
              userLieferant.userId = this.user.userId;

              this.user.userLieferant.push(userLieferant);
            }

            this.user.userPartner = [];
            let selectedRowsDataPartner = this.dataGridPartner.instance.getSelectedRowsData();
            for (let i = 0; i < selectedRowsDataPartner.length; i++) {
              let userPartner = new UserPartner();
              userPartner.partnerId = selectedRowsDataPartner[i].id;
              userPartner.userId = this.user.userId;

              this.user.userPartner.push(userPartner);
            }

            // save
            let retValue = await this.userManagerClient.saveUser(this.user).toPromise();
            userId = retValue.id;

            // check return value
            if (retValue.returnCode == ReturnCodesSave.Duplicate) {
              InteractionController.MessageBox.showWarning("Loginname", "Der eingegebene Loginname existiert bereits.");
              this.dialogForm.form.controls[`${nameof<UserRecordWindowComponent>(u => u.user)}.${nameof<Users>(c => c.loginName)}`].setErrors({});
              this.dialogForm.form.controls[`${nameof<UserRecordWindowComponent>(u => u.user)}.${nameof<Users>(c => c.loginName)}`].markAsTouched();
              return;
            } else if (retValue.returnCode == ReturnCodesSave.NoAdminRemains) {
              InteractionController.MessageBox.showWarning("Benutzerberechtigung",
                'Wenn die Berechtigung "Benutzer verwalten" weggenommen wird, dann existiert kein Benutzer im System, der diese Berechtigung enthält.');
              return;
            }
          }

          this.router.navigate(['.'], {relativeTo: this.activatedRoute.parent, state: {id: userId}});
        } else {
          this.dialogForm.form.controls['user.loginName'].markAsTouched();
          this.dialogForm.form.controls['user.partnerId'].markAsTouched();
          InteractionController.MessageBox.showWarning("Validierungsfehler",
            "Ein oder mehrere Validierungsfehler sind aufgetreten. Die fehlerhaften Eingaben sind rot markiert. Bitte korrigieren Sie Ihre Eingaben und versuchen Sie erneut den Datensatz zu speichern.");
        }
      });
    } catch (e) {
      InteractionController.MessageBox.showApplicationException(e);
    }
  }

  cancel() {
    this.router.navigate(['.'], {relativeTo: this.activatedRoute.parent});
  }

  selectAllRigths(select: boolean) {
    for (let perm of this.userPermissions) {
      perm.permission = select;
    }
    this.dialogForm.form.markAsDirty();
  }

  onSelectionChanged(e) {
    this.dialogForm.form.markAsDirty();
  }

  selectAllRoleRigths(select: boolean) {
    for (let perm of this.rolePermissions) {
      perm.permission = select;
    }
    this.dialogForm.form.markAsDirty();
  }

  lieferantTabActivated() {
    this.dataGridLieferant.instance.refresh();
  }

  partnerTabActivated() {
    this.dataGridPartner.instance.refresh();
  }

  loadStatePartner = () => {
    return DxGridSettings.loadDataGridSettings(this.dataGridPartner, "BenutzerdatenPartner", this.platformId);
  };

  saveStatePartner = (state) => {
    DxGridSettings.saveDataGridSettings(state, "BenutzerdatenPartner", this.platformId, false);
  };

  loadStateLieferanten = () => {
    return DxGridSettings.loadDataGridSettings(this.dataGridLieferant, "BenutzerdatenLieferanten", this.platformId);
  };

  saveStateLieferanten = (state) => {
    DxGridSettings.saveDataGridSettings(state, "BenutzerdatenLieferanten", this.platformId, false);
  }
}
