<div class="modal-header">
  <div class="modal-title" id="modal-basic-title">{{title}}</div>
  <button type="button" class="btn-close" aria-label="Close" (click)="onBtnClose()">

  </button>
</div>
<div class="modal-body">
  <div class="divTable modal-center">
    <div class="divTableBody">
      <div class="divTableRow">
        <div style="width: 1em;" class="divTableCell">
          <img class="icon {{iconClass}}" [src]="iconSrc"/>
        </div>
        <div class="divTableCell">
          <h5 [innerHtml]="message"></h5>
          <div [innerHtml]="details">
          </div>
          <ng-container *ngIf="hasStacktrace()">
            <div class="exceptionpane">
              <a (click)="toggleStacktrace()">{{stacktraceLinkText}}</a>
              <ng-container *ngIf="stacktraceVisible">
                <div class="innerstacktracepane">
                  <textarea readonly=true #stacktraceText class="exceptiontext">{{stacktraceString}}</textarea>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button *ngIf="isBtnOkVisible" ngbAutofocus type="button" class="btn btn-outline-primary btn-sm dialog-button"
            (click)="onBtnOk()">OK
    </button>
    <button *ngIf="isBtnYesVisible" ngbAutofocus type="button" class="btn btn-outline-primary btn-sm dialog-button"
            (click)="onBtnYes()">Ja
    </button>
    <button *ngIf="isBtnNoVisible" type="button" class="btn btn-outline-primary btn-sm dialog-button"
            (click)="onBtnNo()">Nein
    </button>
    <button *ngIf="isBtnRetryVisible" type="button" class="btn btn-outline-primary btn-sm dialog-button"
            (click)="onBtnRetry()">Wiederholen
    </button>
    <button *ngIf="isBtnCancelVisible" ngbAutofocus type="button" class="btn btn-outline-primary btn-sm dialog-button"
            (click)="onBtnCancel()">Abbrechen
    </button>
    <button *ngIf="isBtnCloseVisible" ngbAutofocus type="button" class="btn btn-outline-primary btn-sm dialog-button"
            (click)="onBtnClose()">Schließen
    </button>
  </div>
</div>
