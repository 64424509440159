<div class="modal-header">
  <div class="modal-title" id="modal-basic-title">{{title}}</div>
  <button type="button" class="btn-close" aria-label="Close" (click)="onClose()">

  </button>
</div>

<form #dialogForm="ngForm" (ngSubmit)="onOK()">
  <div class="modal-body">
    <div class="form-group">
      <div style="min-height: 300px; height:300px; max-height: 300px;">
        <div class="h-100 vh-100-safari w-100 mw-100 d-flex flex-column overflow-auto">
          <dx-data-grid #grid class="d-flex flex-column h-100 vh-100-safari w-100 mw-100" style="height: 100px"
                        [dataSource]="dataSource"
                        [allowColumnReordering]="true"
                        [allowColumnResizing]="true"
                        columnResizingMode="widget"
                        [showBorders]="true"
                        [showColumnLines]="true"
                        [showRowLines]="true"
                        [remoteOperations]="true"
                        [wordWrapEnabled]="true"
                        [hoverStateEnabled]="true"
                        [errorRowEnabled]="true"
                        (onToolbarPreparing)="onGridToolbarPreparing($event)">

            <dxo-sorting mode="multiple"></dxo-sorting>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-filter-panel [visible]="true"></dxo-filter-panel>
            <dxo-header-filter [visible]="true"></dxo-header-filter>
            <dxo-group-panel [visible]="false"></dxo-group-panel>
            <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
            <dxo-selection mode="multiple"></dxo-selection>
            <dxo-grouping #expand [autoExpandAll]="false"></dxo-grouping>
            <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
            <dxo-load-panel [showPane]="false"></dxo-load-panel>

            <dxi-column dataField="id" caption="FS Partner Id" alignment="left" minWidth="100" dataType="number"
                        [visible]="false" [allowFiltering]="false" [allowGrouping]="false"></dxi-column>
            <dxi-column dataField="bbn" caption="BBN" alignment="left" width="75" dataType="number" [filterOperations]="filterOperations"></dxi-column>
            <dxi-column dataField="anschlusshaus" caption="Anschlusshaus" alignment="left" minWidth="100"
                        dataType="string"></dxi-column>

          </dx-data-grid>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button ngbAutofocus type="submit" class="btn btn-primary btn-sm dialog-button">OK</button>
      <button type="button" class="btn btn-outline-primary btn-sm dialog-button" (click)="onCancel()">Abbrechen
      </button>
    </div>
  </div>
</form>



