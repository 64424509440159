import {Inject, Injectable, Injector, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {Router} from '@angular/router';
import {UserLieferant, UserManagerClient, UserPartner, UserRoles, Users} from '../webapi/webapi.service';
import {EventAggregator} from "../framework/event-aggregator/event.aggregator";
import {CurrentUserChangedEvent} from "../shared/events/current-user-changed-event";
import {UserPermissionDefinition} from "./model/user-permission-definition";
import {UserPermissionSuffix} from "./model/user-permission-suffix";
import {FunctionModuleBase} from "../framework/functionmodule/function-module-base";
import {InteractionController} from "../framework/interaction-controller/interaction.controller";
import {Using} from "../framework/using/using";
import {WaitCursor, WaitCursorService} from "../framework/wait-cursor/wait-cursor.service";
import {TokenService} from "../authentication/token.service";

@Injectable({
  providedIn: 'root',
})
export class UserManagerService {
  private readonly _authKey = 'authtoken';
  public static readonly permissionUserManagerKey: string = "UserAdministration";
  public static readonly permissionUserManagerText: string = "Benutzer verwalten";
  public static readonly administratorRoleName: string = "Administrator";

  private _token?: string = null;

  public get currentToken(): string {
    return this._token;
  }

  public async getTokenItem(): Promise<string> {
    if (this._token == null) {
      // try to restore token
      try {
        await Using.using(new WaitCursor(this.waitCursorService), async () => {
          this._token = await this.tokenService.acquireToken();
          if(this._token != null){
            // update user information after token recovery
            if (this._currentUser == null) {
              this._currentUser = await this.userManagerClient.getCurrentUser().toPromise();

              if (this._currentUser == null)	// the user is not registered for the application, show message box and abandone
                await InteractionController.MessageBox.showError("Benutzer nicht registriert",
                  "Ihr Benutzer ist für diese Anwendung nicht registriert. Sie werden automatisch abgemeldet.<br><br>Kontaktieren Sie Ihren Anwendungsadministrator, um sich für die Anwendung registrieren zu lassen.");

              this.eventAggregator.getEvent(CurrentUserChangedEvent).publish(this._currentUser);
            }
          }
        });
      } catch (e) {

      }
    }

    return this._token;
  }

  public setTokenItem(v: string) {
    this._token = v;
  }

  private _currentUser?: Users = null;
  public permissions: UserPermissionDefinition[] = [];

  public get isUserLoggedIn(): Promise<boolean> {
    return (async () => {
      await this.getTokenItem();   // wait, until the user is updated
      await this.resolveCurrentUser();
      return this._currentUser != null;
    })();
  }

  public async resolveCurrentUser() : Promise<Users>
  {
    if(this._currentUser == null && this._token != null){
      this._currentUser = await this.userManagerClient.getCurrentUser().toPromise();
    }
    return this._currentUser;
  }

  public getRoles(): UserRoles[] {
    if(this._currentUser != null) {
      return this._currentUser.userRoles;
    }
  }

  public isUserAdmin(): boolean{
    if(this._currentUser != null) {
      let roles = this.getRoles();
      let admin = roles.find(x=>x.role.roleName == UserManagerService.administratorRoleName);
      return admin != null;
    } else{
      return false;
    }
  }

  public get loginName(): string {
    if (this._currentUser != null)
      return this._currentUser.loginName;
    else
      return "";
  }

  public get benutzerArt(): number {
    if (this._currentUser != null)
      return this._currentUser.benutzerart;
    else
      return null;
  }

  public get userLieferanten(): UserLieferant[] {
    if (this._currentUser != null)
      return this._currentUser.userLieferant;
    else
      return null;
  }

  public get userPartner(): UserPartner[] {
    if (this._currentUser != null)
      return this._currentUser.userPartner;
    else
      return null;
  }

  public get fullName(): string {
    let retValue = this.tokenService.resolveFullName();
    return retValue;
  }

  public get userId(): number {
    if (this._currentUser != null)
      return this._currentUser.userId;
    else
      return 0;
  }

  public get fullUserName(): string {
    let loginName: string = this.loginName;
    let fullName: string = this.fullName;

    if (fullName == null || fullName == "")
      return loginName;
    else
      return `${fullName} (${loginName})`;
  }

  constructor(@Inject(PLATFORM_ID)
              private platformId: any,
              private router: Router,
              private userManagerClient: UserManagerClient,
              private eventAggregator: EventAggregator,
              private waitCursorService: WaitCursorService,
              private tokenService : TokenService
  ) {
  }

  async login(refreshToken: boolean = false) {
    if (refreshToken) {
      await this.tokenService.renewToken();
      this._token = await this.tokenService.acquireToken();
    } else {
      if (this._token == null)
        this.tokenService.login();
      else
        await this.logout();
    }
  }

  async logout() {
    this.setTokenItem(null);
    this._currentUser = null;
    this.tokenService.logout();
  }

  hasPermission(permissionKey: string): boolean {
    if (this._currentUser != null) {
      if (this._currentUser.effectivePermissions.findIndex((item) => item.permissionKey == permissionKey) != -1) {
        return true;
      }
    }

    return false;
  }

  hasReadPermission(permissionKeyPart: string): boolean {
    return this.hasPermission(permissionKeyPart + UserPermissionSuffix.ReadPermission);
  }

  hasWritePermission(permissionKeyPart: string): boolean {
    return this.hasPermission(permissionKeyPart + UserPermissionSuffix.WritePermission);
  }

  addPermission(key: string, text: string) {
    this.permissions.push(new UserPermissionDefinition(text, key));
  }

  initUserManager(functionModules: FunctionModuleBase[]) {
    // build permission list from modules
    for (let functionModule of functionModules) {
      for (let perm of functionModule.userPermissions) {
        if (this.permissions.find(x => x.permissionKey == perm.permissionKey) === undefined)
          this.addPermission(perm.permissionKey, perm.permisssionName);
      }
    }
  }

}
