import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  public clientId: string;
  public issuer: string;
  public infoText: string;
  public maxFileSize: number;
  public contactMail: string;
  public kompaktLieferantsicht: string;

  constructor(private http: HttpClient) { }

  async load() {
    const jsonFile = `assets/config/config.json`;

    let result : any = await  this.http.get(jsonFile).toPromise()
    this.clientId = result.clientId;
    this.issuer = result.issuer;
    this.infoText = result.infoText;
    this.maxFileSize = result.maxFileSize;
    this.contactMail = result.contactMail;
    this.kompaktLieferantsicht = result.kompaktLieferantsicht;
  }
}
