<div class="detailarea sticky-top" [hidden]="!isModuleUrl">
  <nav class="navbar navbar-expand detailtoolbar">
    <div class="navbar-collapse collapse">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item mr-1" *ngIf="hasWritePermission && (yearselectionService.zeitraumActive || userManagerService.isUserAdmin())">
          <a class="nav-link navlink-hover" (click)="new()">
            <img src="./assets/images/New.svg"/><span>Neu</span>
          </a>
        </li>
        <li class="nav-item mr-1" *ngIf="hasWritePermission && (yearselectionService.zeitraumActive || userManagerService.isUserAdmin())">
          <a class="nav-link navlink-hover" (click)="delete()">
            <img src="./assets/images/Delete.svg"/><span>Löschen</span>
          </a>
        </li>
        <li class="nav-item mr-1">
          <a class="nav-link navlink-hover" (click)="export()">
            <img src="./assets/images/ExcelExport.svg"/><span>Exportieren</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link navlink-hover" (click)="refresh()">
            <img src="./assets/images/Refresh.svg"/><span>Aktualisieren</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link navlink-hover" (click)="reset()">
            <img src="./assets/images/Reset.svg"/><span>Zurücksetzen</span>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</div>

<div class="d-flex flex-column flex-grow-1 overflow-hidden" [hidden]="!isModuleUrl"
     [ngClass]="!isModuleUrl ? 'display-none-important' : ''">
  <dx-data-grid #grid class="d-flex flex-column h-100 vh-100-safari w-100 mw-100" style="height: 100px"
                [dataSource]="dataSource"
                [allowColumnReordering]="true"
                [allowColumnResizing]="true"
                columnResizingMode="widget"
                [showBorders]="true"
                [showColumnLines]="true"
                [showRowLines]="true"
                [remoteOperations]="true"
                [wordWrapEnabled]="true"
                [hoverStateEnabled]="true"
                [errorRowEnabled]="true"
                (onExporting)="onGridExporting($event)"
                (onToolbarPreparing)="onGridToolbarPreparing($event)"
                (onRowDblClick)="onDblClick($event)"
                (onKeyDown)="onKeyDown($event)"
                (onRowUpdating)="updatingEvent($event)"
                (onRowValidating)="validatingEvent($event)"
  >

    <dxo-editing mode="batch"
                 [allowUpdating]="(yearselectionService.zeitraumActive || userManagerService.isUserAdmin())"
                 selectTextOnEditStart="true"
                 startEditAction="dblClick">
    </dxo-editing>
    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-builder-popup></dxo-filter-builder-popup>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
    <dxo-selection mode="single"></dxo-selection>
    <dxo-grouping #expand [autoExpandAll]="false"></dxo-grouping>
    <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
    <dxo-load-panel [showPane]="false"></dxo-load-panel>
    <dxo-export [enabled]="true" [fileName]="fileName()" [allowExportSelectedData]="false"></dxo-export>

    <dxi-column dataField="id" caption="Jahr Id" alignment="left" minWidth="100" dataType="number"
                [visible]="false" [allowFiltering]="false" [allowGrouping]="false"></dxi-column>
    <dxi-column dataField="wawinr" caption="Wawi Nr." alignment="left" minWidth="50" dataType="string"
                [allowEditing]="false"></dxi-column>
    <dxi-column dataField="vlnr" caption="VL-Nr." alignment="left" minWidth="50" dataType="number"
                [allowEditing]="false" [filterOperations]="filterOperations"></dxi-column>
    <dxi-column dataField="name" caption="Name" alignment="left" minWidth="100" dataType="string"
                [allowEditing]="false"></dxi-column>
    <dxi-column dataField="gegenleistungsart" caption="Gegenleistungsart" alignment="left" minWidth="100"
                dataType="string" [allowEditing]="false"></dxi-column>
    <dxi-column dataField="ksnr" caption="KS-Nr." alignment="left" minWidth="65" width="65" dataType="number">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxi-validation-rule type="range" min="1" max="2147483647" message="Der Wert muss zwichen '1' und '2147483647' liegen"></dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="ksbezeichnung" caption="KS-Bezeichnung" alignment="left" minWidth="100" [editorOptions]="{ maxLength: 255 }"
                dataType="string"></dxi-column>
    <dxi-column dataField="anzahlGegenleistungen" caption="Anzahl Gegenleistungen" alignment="left" minWidth="100"
                dataType="number">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxi-validation-rule type="range" min="1" max="2147483647" message="Der Wert muss zwichen '1' und '2147483647' liegen"></dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="anzahlArtikel" caption="Anzahl Artikel" alignment="left" minWidth="100" dataType="number">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxi-validation-rule type="range" min="1" max="2147483647" message="Der Wert muss zwichen '1' und '2147483647' liegen"></dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="summe" caption="Summe" alignment="left" minWidth="100" dataType="number"
                [allowEditing]="false"></dxi-column>
    <dxi-column dataField="wkz" caption="% / Fix - WKZ" alignment="left" minWidth="100" dataType="string">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="bemerkungExtern" caption="Bemerkung Extern" alignment="left" minWidth="100" [editorOptions]="{ maxLength: 255 }">
                dataType="string"></dxi-column>
    <dxi-column dataField="bemerkungIntern" caption="Bemerkung Intern" alignment="left" minWidth="100" [editorOptions]="{ maxLength: 255 }"
                dataType="string"></dxi-column>
    <dxi-column dataField="zeitraum" caption="Zeitraum" alignment="left" minWidth="100" dataType="number"
                [visible]="false"></dxi-column>

    <dxo-summary>
      <dxi-total-item column="bemerkungIntern" summaryType="count" alignment="right">
      </dxi-total-item>
    </dxo-summary>
  </dx-data-grid>
</div>

<ng-container *ngIf="!isModuleUrl">
  <router-outlet></router-outlet>
</ng-container>
