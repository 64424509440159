import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {FunctionWindow} from "../../../../framework/functionwindow/function-window";
import {UserManagerService} from "../../../../usermanager/usermanager.service";
import {ActivatedRoute, Router} from "@angular/router";
import {WaitCursorService} from "../../../../framework/wait-cursor/wait-cursor.service";
import {PartnerdatenFunctionModule} from "../../functionmodules/partnerdaten-function-module";
import {DxDataGridComponent} from "devextreme-angular";
import {InteractionController} from "../../../../framework/interaction-controller/interaction.controller";
import {PartnerDialogComponent, PartnerDialogParameter} from "../partner-dialog/partner-dialog.component";
import {PartnerClient} from "../../../../webapi/webapi.service";

@Component({
  selector: 'app-partner',
  templateUrl: './partnerdaten.component.html',
  host: {class: 'container-fluid d-flex h-100 flex-column p-0 bg-detailtoolbar'}
})
export class PartnerdatenComponent extends FunctionWindow implements OnInit, OnDestroy {
  @ViewChild('grid') dataGrid: DxDataGridComponent;
  gridKey: string[] = ["id"];

  get hasWritePermission(): boolean {
    return this.userManagerService.hasWritePermission(this.functionModule.contractName);
  }

  constructor(@Inject('BASE_URL') baseUrl: string,
              @Inject(PLATFORM_ID) protected platformId: any,
              protected userManagerService: UserManagerService,
              private client: PartnerClient,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected waitCursorService: WaitCursorService,
              protected functionModule: PartnerdatenFunctionModule) {
    super(platformId, userManagerService, router, waitCursorService, functionModule);

    this.loadUrl = baseUrl + 'api/partner/partnerlist';
    this.createDataSource();
  }

  async new() {
    if (this.hasWritePermission) {
      let parameter: PartnerDialogParameter = new PartnerDialogParameter();
      parameter.partnerId = 0;
      if (await InteractionController.showDialog(PartnerDialogComponent, parameter)) {
        this.dataGrid.instance.refresh();
        this.dataGrid.selectedRowKeys = [{id: parameter.partnerId}];
      }
    }
  }

  async edit() {
    if (this.hasWritePermission) {
      if (this.dataGrid.selectedRowKeys.length > 0) {
        let parameter: PartnerDialogParameter = new PartnerDialogParameter();
        parameter.partnerId = this.dataGrid.selectedRowKeys[0].id;
        if (await InteractionController.showDialog(PartnerDialogComponent, parameter)) {
          this.dataGrid.instance.refresh();
          this.dataGrid.selectedRowKeys = [{id: parameter.partnerId}];
        }
      } else
        InteractionController.MessageBox.showWarning("Keine Auswahl", "Bitte selektieren Sie einen Eintrag aus der Liste und führen Sie die Operation erneut aus.");
    }
  }

  async delete() {
    try {
      if (this.dataGrid.selectedRowKeys.length > 0) {
        if (await InteractionController.MessageBox.showInfoConfirmation("Partner löschen",
          "Der Partner wird unwiderruflich gelöscht. Wollen Sie den ausgewählten Partner wirklich löschen?")) {
          let retValue = await this.client.deletePartner(this.dataGrid.selectedRowKeys[0].id).toPromise();
          this.refresh();
        }
      } else {
        InteractionController.MessageBox.showWarning("Keine Auswahl", "Bitte selektieren Sie einen Eintrag aus der Liste und führen Sie die Operation erneut aus.");
      }
    } catch (e) {
      InteractionController.MessageBox.showApplicationException(e);
    }
  }
}
