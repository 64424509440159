import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import {FunctionWindow} from "../../../framework/functionwindow/function-window";
import {UserManagerService} from "../../usermanager.service";
import {ActivatedRoute, Router} from "@angular/router";
import {WaitCursorService} from "../../../framework/wait-cursor/wait-cursor.service";
import {DxDataGridComponent} from "devextreme-angular";
import {InteractionController} from "../../../framework/interaction-controller/interaction.controller";
import {UserFunctionModule} from "../functionmodules/user-function-module";
import {ReturnCodesSave, UserManagerClient} from "../../../webapi/webapi.service";
import {GridHelper} from "../../../framework/grid/gridhelper";
import DevExpress from "devextreme";

@Component({
  selector: 'app-user-function-window',
  templateUrl: './user-function-window.component.html',
  host: {class: 'container-fluid d-flex h-100 flex-column p-0 bg-detailtoolbar'}
})
export class UserFunctionWindowComponent extends FunctionWindow implements OnInit, OnDestroy {
  @ViewChild('grid') dataGrid: DxDataGridComponent;
  gridKey: string[] = ["userId"];
  gridKeyLookUp: string[] = ["roleId"];
  loadUrlLookUp: string;
  roleLookupDataSource: DevExpress.data.CustomStore;
  contractName: string = "UserFunctionWindow";

  constructor(@Inject('BASE_URL') baseUrl: string,
              @Inject(PLATFORM_ID) protected platformId: any,
              protected userManagerService: UserManagerService,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected waitCursorService: WaitCursorService,
              protected functionModule: UserFunctionModule,
              private userManagerClient: UserManagerClient) {
    super(platformId, userManagerService, router, waitCursorService, functionModule);

    this.loadUrl = baseUrl + 'api/usermanager/userlist';
    this.loadUrlLookUp = baseUrl + 'api/usermanager/rolelist';
    this.createDataSource();
    this.createLookUpDataSource();
  }

  onMainModuleNavigation(state: any) {
    super.onMainModuleNavigation(state);

    // we navigated back from client
    if (window.history.state.id !== undefined && window.history.state.id != 0) {
      if (this.dataGrid !== undefined) {
        this.dataGrid.selectedRowKeys = [{userId: +window.history.state.id}];
      }
    }
  }

  statusLookupData = {
    store: {
      type: 'array',
      data: [
        {status: 0, name: 'Für Sie Benutzer'},
        {status: 1, name: 'Externer Benutzer'}
      ],
      key: "status"
    }
  };

  createLookUpDataSource(loadParams: Object = undefined) {
    this.roleLookupDataSource = GridHelper.createGridDataSource(this.gridKeyLookUp, this.loadUrlLookUp, this.router, this.userManagerService, this, loadParams);
  }

  new() {
    this.router.navigate(['user'], {relativeTo: this.activatedRoute});
  }

  edit() {
    if (this.dataGrid.selectedRowKeys.length > 0) {
      this.router.navigate([`user/${this.dataGrid.selectedRowKeys[0].userId}`], {relativeTo: this.activatedRoute});
    } else
      InteractionController.MessageBox.showWarning("Keine Auswahl", "Bitte selektieren Sie einen Eintrag aus der Liste und führen Sie die Operation erneut aus.");
  }

  async delete() {
    try {
      if (this.dataGrid.selectedRowKeys.length > 0) {
        if (await InteractionController.MessageBox.showInfoConfirmation("Datensatz löschen",
          "Der Datensatz wird unwiderruflich gelöscht. Wollen Sie den ausgewählten Datensatz wirklich löschen?")) {

          let retValue = await this.userManagerClient.deleteUser(this.dataGrid.selectedRowKeys[0].userId).toPromise();

          if (retValue == ReturnCodesSave.NoAdminRemains)
            InteractionController.MessageBox.showWarning("Benutzerberechtigung",
              'Der ausgewählte Benutzer kann nicht gelöscht werden. Es gibt keinen anderen Benutzer mit der Berechtigung "Benutzer verwalten".');
          else
            this.refresh();
        }
      } else
        InteractionController.MessageBox.showWarning("Keine Auswahl", "Bitte selektieren Sie einen Eintrag aus der Liste und führen Sie die Operation erneut aus.");
    } catch (e) {
      InteractionController.MessageBox.showApplicationException(e);
    }
  }

  public fileName(){
    let fileDate: string = new Date().getFullYear().toString()+ '-' + (new Date().getMonth()+1).toString()+ '-' + new Date().getDate().toString();
    return "Benutzerverwaltung" + '-' + fileDate;
  }
}
