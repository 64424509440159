import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {PartnerdatenComponent} from './partner/partnerdaten/partnerdaten.component';
import {FunctionModuleBase} from "../../framework/functionmodule/function-module-base";
import {PartnerdatenFunctionModule} from "./functionmodules/partnerdaten-function-module";
import {DxDataGridModule, DxDateBoxModule} from "devextreme-angular";
import {RouterModule} from "@angular/router";
import {AngularSplitModule} from "../../framework/splitter/module";
import {PartnerDialogComponent} from './partner/partner-dialog/partner-dialog.component';
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {LieferantendatenComponent} from "./lieferanten/lieferantendaten/lieferantendaten.component";
import {LieferantendatenFunctionModule} from "./functionmodules/lieferantendaten-function-module";
import {LieferantenDialogComponent} from "./lieferanten/lieferanten-dialog/lieferanten-dialog.component";
import {ZeitraumdatenComponent} from "./zeitraum/zeitraumdaten/zeitraumdaten.component";
import {ZeitraumDialogComponent} from "./zeitraum/zeitraum-dialog/zeitraum-dialog.component";
import {ZeitraumdatenFunctionModule} from "./functionmodules/zeitraumdaten-function-module";
import {LieferantenkonditionenFunctionModule} from "./functionmodules/lieferantenkonditionen-function-module";
import {PartnerkonditionenFunctionModule} from "./functionmodules/partnerkonditionen-function-module";
import {GegenleistungsartdatenComponent} from "./gegenleistungsart/gegenleistungsartdaten/gegenleistungsartdaten.component";
import {GegenleistungsartdatenFunctionModule} from "./functionmodules/gegenleistungsartdaten-function-module";
import {LieferantenkonditionenComponent} from "./konditionen/lieferanten/lieferantenkonditionen.component";
import {GegenleistungsartDialogComponent} from "./gegenleistungsart/gegenleistungsart-dialog/gegenleistungsart-dialog.component";
import {LieferantenkonditionenDialogComponent} from "./konditionen/lieferanten/lieferantenkonditionen-dialog/lieferantenkonditionen-dialog.component";
import {PartneruebersichtComponent} from "./konditionen/partner/partner-uebersicht/partneruebersicht.component";
import {PartnerkonditionenComponent} from "./konditionen/partner/partnerkonditionen/partnerkonditionen.component";
import {PartnerkonditionenDialogComponent} from "./konditionen/partner/partnerkonditionen-dialog/partnerkonditionen-dialog.component";
import {LieferantenkonditionenpflegeFunctionModule} from "./functionmodules/lieferantenkonditionenpflege-function-module";
import {LieferantenkonditionenpflegeUebersichtComponent} from "./konditionen/lieferantenkonditionen/lieferantenkonditionpflege-uebersicht/lieferantenkonditionenpflege-uebersicht.component";
import {LieferantenkonditionenpflegeComponent} from "./konditionen/lieferantenkonditionen/lieferantenkonditionpflege/lieferantenkonditionenpflege.component";
import {LieferantenkonditionenpflegeDialogComponent} from "./konditionen/lieferantenkonditionen/lieferantenkontitionenpflege-dialog/lieferantenkonditionenpflege-dialog.component";


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DxDataGridModule,
    RouterModule,
    AngularSplitModule,
    NgbTooltipModule,
    DxDateBoxModule
  ],
  declarations: [
    PartnerdatenComponent,
    PartnerDialogComponent,
    PartneruebersichtComponent,
    PartnerkonditionenComponent,
    PartnerkonditionenDialogComponent,
    LieferantendatenComponent,
    LieferantenDialogComponent,
    ZeitraumdatenComponent,
    ZeitraumDialogComponent,
    GegenleistungsartdatenComponent,
    GegenleistungsartDialogComponent,
    LieferantenkonditionenComponent,
    LieferantenkonditionenDialogComponent,
    LieferantenkonditionenpflegeComponent,
    LieferantenkonditionenpflegeUebersichtComponent,
    LieferantenkonditionenpflegeDialogComponent
  ],
  providers: [
    {provide: FunctionModuleBase, useClass: LieferantenkonditionenFunctionModule, multi: true},
    {provide: FunctionModuleBase, useClass: PartnerkonditionenFunctionModule, multi: true},
    {provide: FunctionModuleBase, useClass: LieferantendatenFunctionModule, multi: true},
    {provide: FunctionModuleBase, useClass: PartnerdatenFunctionModule, multi: true},
    {provide: FunctionModuleBase, useClass: ZeitraumdatenFunctionModule, multi: true},
    {provide: FunctionModuleBase, useClass: GegenleistungsartdatenFunctionModule, multi: true},
    {provide: FunctionModuleBase, useClass: LieferantenkonditionenpflegeFunctionModule, multi: true}
  ]
})
export class StammdatenModule {
}
