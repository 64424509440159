import {Component, OnInit, Inject, ViewChild} from '@angular/core';
import {UserManagerService} from '../usermanager/usermanager.service';
import {Router} from '@angular/router';
import {WaitCursorService} from '../framework/wait-cursor/wait-cursor.service';
import {FunctionMenuService} from "../framework/functionmodule/function-menu.service";


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  host: {class: 'container-fluid d-flex h-100 flex-column p-0'}
})
export class HomeComponent implements OnInit {

  constructor(@Inject('BASE_URL') baseUrl: string, protected userManagerService: UserManagerService,
              protected router: Router, protected waitCursorService: WaitCursorService, public functionMenuService: FunctionMenuService) {
  }

  navigateToModuleComponent(path: string, url: string = null) {
    if (url != null){
      window.open(url);
    }else{
      this.router.navigate(['/' + path]);
    }
  }

  ngOnInit() {

  }
}
