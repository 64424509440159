import {Component} from '@angular/core';
import {WaitCursorService} from './wait-cursor.service';

@Component({
  selector: 'wait-cursor',
  templateUrl: './wait-cursor.component.html',
  styleUrls: ['./wait-cursor.component.scss']
})
export class WaitCursorComponent {

  constructor(public waitCursorService: WaitCursorService) {
  }

}
