import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {FunctionWindow} from "../../../framework/functionwindow/function-window";
import {DxDataGridComponent} from "devextreme-angular";
import {UserManagerService} from "../../usermanager.service";
import {ActivatedRoute, Router} from "@angular/router";
import {WaitCursorService} from "../../../framework/wait-cursor/wait-cursor.service";
import {UserManagerClient} from "../../../webapi/webapi.service";
import {InteractionController} from "../../../framework/interaction-controller/interaction.controller";
import {RoleFunctionModule} from "../functionmodules/role-function-module";

@Component({
  selector: 'app-role-function-window',
  templateUrl: './role-function-window.component.html',
  host: {class: 'container-fluid d-flex h-100 flex-column p-0 bg-detailtoolbar'}
})
export class RoleFunctionWindowComponent extends FunctionWindow implements OnInit, OnDestroy {
  @ViewChild('grid') dataGrid: DxDataGridComponent;
  gridKey: string[] = ["roleId"];
  contractName: string = "RoleFunctionWindow";

  constructor(@Inject('BASE_URL') baseUrl: string,
              @Inject(PLATFORM_ID) protected platformId: any,
              protected userManagerService: UserManagerService,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected waitCursorService: WaitCursorService,
              protected functionModule: RoleFunctionModule,
              private userManagerClient: UserManagerClient) {
    super(platformId, userManagerService, router, waitCursorService, functionModule);

    this.loadUrl = baseUrl + 'api/usermanager/rolelist';
    this.createDataSource();
  }

  onMainModuleNavigation(state: any) {
    super.onMainModuleNavigation(state);

    // we navigated back from client
    if (window.history.state.id !== undefined && window.history.state.id != 0) {
      if (this.dataGrid !== undefined) {
        this.dataGrid.selectedRowKeys = [{roleId: +window.history.state.id}];
      }
    }
  }

  new() {
    this.router.navigate(['role'], {relativeTo: this.activatedRoute});
  }

  edit() {
    if (this.dataGrid.selectedRowKeys.length > 0) {
      this.router.navigate([`role/${this.dataGrid.selectedRowKeys[0].roleId}`], {relativeTo: this.activatedRoute});
    } else
      InteractionController.MessageBox.showWarning("Keine Auswahl", "Bitte selektieren Sie einen Eintrag aus der Liste und führen Sie die Operation erneut aus.");
  }

  async delete() {
    try {
      if (this.dataGrid.selectedRowKeys.length > 0) {
        if (await InteractionController.MessageBox.showInfoConfirmation("Datensatz löschen",
          "Der Datensatz wird unwiderruflich gelöscht. Wollen Sie den ausgewählten Datensatz wirklich löschen?")) {

          let retValue = await this.userManagerClient.deleteRole(this.dataGrid.selectedRowKeys[0].roleId).toPromise();

          this.refresh();
        }
      } else
        InteractionController.MessageBox.showWarning("Keine Auswahl", "Bitte selektieren Sie einen Eintrag aus der Liste und führen Sie die Operation erneut aus.");
    } catch (e) {
      InteractionController.MessageBox.showApplicationException(e);
    }
  }

  public fileName(){
    let fileDate: string = new Date().getFullYear().toString()+ '-' + (new Date().getMonth()+1).toString()+ '-' + new Date().getDate().toString();
    return "Rollenverwaltung" + '-' + fileDate;
  }
}
