import {Component, OnInit, ViewChild} from '@angular/core';
import {DialogBase} from "../../../../framework/dialog/dialog.base";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {WaitCursor, WaitCursorService} from "../../../../framework/wait-cursor/wait-cursor.service";
import {UserManagerService} from "../../../../usermanager/usermanager.service";
import {NgForm} from "@angular/forms";
import {Using} from "../../../../framework/using/using";
import {TaskDialogButtons} from "../../../../framework/message-box/taskdialog/task-dialog-buttons";
import {InteractionController} from "../../../../framework/interaction-controller/interaction.controller";
import {KonditionNachweisDetail, PartnersichtClient} from "../../../../webapi/webapi.service";

export class PartnersichtNachweisDialogParameter {
  konditionNachweisDetailId: number;
}

@Component({
  selector: 'app-partnersichtnachweis-dialog',
  templateUrl: './partnersicht-nachweis-dialog.component.html'
})
export class PartnersichtNachweisDialogComponent extends DialogBase implements OnInit {
  @ViewChild('dialogForm') dialogForm: NgForm;
  title: string = 'Nachweisdetails';
  konditionNachweisDetail: KonditionNachweisDetail = new KonditionNachweisDetail();

  constructor(public activeModal: NgbActiveModal,
              public waitCursorService: WaitCursorService,
              private _userManagerService: UserManagerService,
              private dialogParameter: PartnersichtNachweisDialogParameter,
              private client: PartnersichtClient) {
    super(activeModal, waitCursorService);
  }

  async ngOnInit() {
    try {
      await Using.using(new WaitCursor(this.waitCursorService), async () => {
        let konditionNachweisDetail = await this.client.getKonditionnachweisDetailById(this.dialogParameter.konditionNachweisDetailId).toPromise();
        if (konditionNachweisDetail != null) {
          this.konditionNachweisDetail = konditionNachweisDetail;
        } else {
          await InteractionController.MessageBox.showWarning("Keine Daten", "Nachweisdaten konnten nicht abgerufen werden. Möglicherweise existiert der Konditionsnachweis nicht.");
          this.close();
        }
      });
    } catch (e) {
      await InteractionController.MessageBox.showApplicationException(e);
      this.close();
    }
  }

  public async onOK() {
    try {
      await Using.using(new WaitCursor(this.waitCursorService), async () => {
        if (this.konditionNachweisDetail.anzahl <= 2147483647) {
          if (this.dialogForm.valid) {
            if (this.dialogForm.dirty) {
              await this.client.saveNachweisdetail(this.konditionNachweisDetail).toPromise();
              this.close();
              this.onButtonPressed.emit(TaskDialogButtons.OK);
            } else	// no changes, just close
              this.close();
          } else {
            this.dialogForm.form.controls['anzahl'].markAsTouched();
            this.dialogForm.form.controls['seitennummern'].markAsTouched();
            InteractionController.MessageBox.showWarning("Validierungsfehler",
              "Ein oder mehrere Validierungsfehler sind aufgetreten. Die fehlerhaften Eingaben sind rot markiert. Bitte korrigieren Sie Ihre Eingaben und versuchen Sie erneut den Datensatz zu speichern.");
          }
        }else{
          InteractionController.MessageBox.showWarning("Anzahl", 'Die eingegebene Anzahl darf den Wert "2147483647" nicht überschreiten.');
          this.dialogForm.form.controls['anzahl'].setErrors({});
          this.dialogForm.form.controls['anzahl'].markAsTouched();
          return;
        }
      });
    } catch (e) {
      InteractionController.MessageBox.showApplicationException(e);
    }
  }
}
