import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {FunctionWindow} from "../../../../framework/functionwindow/function-window";
import {UserManagerService} from "../../../../usermanager/usermanager.service";
import {ActivatedRoute, Router} from "@angular/router";
import {WaitCursorService} from "../../../../framework/wait-cursor/wait-cursor.service";
import {DxDataGridComponent} from "devextreme-angular";
import {GegenleistungsartdatenFunctionModule} from "../../functionmodules/gegenleistungsartdaten-function-module";
import {GridHelper} from "../../../../framework/grid/gridhelper";
import {InteractionController} from "../../../../framework/interaction-controller/interaction.controller";
import {GegenleistungsartClient} from "../../../../webapi/webapi.service";
import {GegenleistungsartDialogComponent, GegenleistungsartDialogParameter} from "../gegenleistungsart-dialog/gegenleistungsart-dialog.component";

@Component({
	selector: 'app-gegenleistungsartdaten',
  templateUrl: './gegenleistungsartdaten.component.html',  
	host: {class: 'container-fluid d-flex h-100 flex-column p-0 bg-detailtoolbar'}
})
export class GegenleistungsartdatenComponent extends FunctionWindow implements OnInit, OnDestroy {
	@ViewChild('grid') dataGrid: DxDataGridComponent;
	gridKey: string[] = ["id"];
	insertUrl: string;
	deleteUrl: string;
	updateUrl: string;

  get hasWritePermission(): boolean {
    return this.userManagerService.hasWritePermission(this.functionModule.contractName);
  }

	constructor(@Inject('BASE_URL') baseUrl: string,
              @Inject(PLATFORM_ID) protected platformId: any,
              protected userManagerService: UserManagerService,
              private client: GegenleistungsartClient,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected waitCursorService: WaitCursorService,
              protected functionModule: GegenleistungsartdatenFunctionModule) {
		super(platformId, userManagerService, router, waitCursorService, functionModule);

		this.loadUrl = baseUrl + 'api/gegenleistungsart/query';
		this.insertUrl = baseUrl + 'api/gegenleistungsart/Save';
		this.deleteUrl = undefined; // baseUrl + 'api/gegenleistungsart/Delete';
		this.updateUrl = baseUrl + 'api/gegenleistungsart/Update';
		this.dataSource = GridHelper.createEditableGridDataSource(this.gridKey, this.loadUrl, this.updateUrl, this.deleteUrl, this.insertUrl, this.router, this.userManagerService, this, undefined);
	}

  async new() {
    if (this.hasWritePermission) {
      let parameter: GegenleistungsartDialogParameter = new GegenleistungsartDialogParameter();
      parameter.gegenleistungsartId = 0;
      if (await InteractionController.showDialog(GegenleistungsartDialogComponent, parameter)) {
        this.dataGrid.instance.refresh();
        this.dataGrid.selectedRowKeys = [{ id: parameter.gegenleistungsartId }];
      }
    }
  }

  async edit() {
    if (this.hasWritePermission) {
      if (this.dataGrid.selectedRowKeys.length > 0) {
        let parameter: GegenleistungsartDialogParameter = new GegenleistungsartDialogParameter();
        parameter.gegenleistungsartId = this.dataGrid.selectedRowKeys[0].id;
        if (await InteractionController.showDialog(GegenleistungsartDialogComponent, parameter)) {
          this.dataGrid.instance.refresh();
          this.dataGrid.selectedRowKeys = [{id: parameter.gegenleistungsartId}];
        }
      } else
        InteractionController.MessageBox.showWarning("Keine Auswahl", "Bitte selektieren Sie einen Eintrag aus der Liste und führen Sie die Operation erneut aus.");
    }
  }

  async delete() {
    try {
      if (this.dataGrid.selectedRowKeys.length > 0) {
        if (await InteractionController.MessageBox.showInfoConfirmation("Gegenleistungsart löschen",
          "Die Gegenleistungsart wird unwiderruflich gelöscht. Wollen Sie die ausgewählte Gegenleistungsart wirklich löschen?")) {
          let retValue = await this.client.delete(this.dataGrid.selectedRowKeys[0].id).toPromise();
          this.refresh();
        }
      } else {
        InteractionController.MessageBox.showWarning("Keine Auswahl", "Bitte selektieren Sie einen Eintrag aus der Liste und führen Sie die Operation erneut aus.");
      }
    } catch (e) {
      InteractionController.MessageBox.showApplicationException(e);
    }
  }
}



