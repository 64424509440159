import {Component, OnInit, ViewChild} from '@angular/core';
import {DialogBase} from "../../../../framework/dialog/dialog.base";
import {NgForm} from "@angular/forms";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {WaitCursor, WaitCursorService} from "../../../../framework/wait-cursor/wait-cursor.service";
import {UserManagerService} from "../../../../usermanager/usermanager.service";
import {Using} from "../../../../framework/using/using";
import {InteractionController} from "../../../../framework/interaction-controller/interaction.controller";
import {TaskDialogButtons} from "../../../../framework/message-box/taskdialog/task-dialog-buttons";
import {PartnersichtClient} from "../../../../webapi/webapi.service";

export class ChangeStatusDialogParameter {
  currentStatus: boolean;
  ids: Array<number> = new Array<number>();
}

@Component({
  selector: 'partnersichtchangestatus',
  templateUrl: './partnersicht-change-status.component.html'
})
export class PartnersichtChangeStatusComponent extends DialogBase implements OnInit {
  @ViewChild('dialogForm') dialogForm: NgForm;
  title: string = 'Status ändern';
  status: number;

  constructor(public activeModal: NgbActiveModal,
              public waitCursorService: WaitCursorService,
              private _userManagerService: UserManagerService,
              private dialogParameter: ChangeStatusDialogParameter,
              private partnerClient: PartnersichtClient) {
    super(activeModal, waitCursorService);
  }

  ngOnInit() {
    if (this.dialogParameter.ids.length == 1 && this.dialogParameter.currentStatus != null) {
      if (this.dialogParameter.currentStatus == true) {
        this.status = 1;
      } else {
        this.status = 0;
      }
    } else {
      this.status = -1;
    }
  }

  public async onOK() {
    try {
      if (this.status == -1) {
        InteractionController.MessageBox.showWarning("Status auswählen", "Bitte wählen Sie einen Status aus der Liste aus.");
        return;
      }
      if (this.dialogForm.dirty) {
        let contentText = "";
        contentText = "Wollen Sie den Status der ausgewählten Konditionen wirklich ändern?";

        if (await InteractionController.MessageBox.showInfoConfirmation("Status ändern", contentText)) {
          await Using.using(new WaitCursor(this.waitCursorService), async () => {
            await this.partnerClient.changeKonditionStatus(this.status, this.dialogParameter.ids).toPromise();
            // close dialog with OK
            this.close();
            this.onButtonPressed.emit(TaskDialogButtons.OK);
          });
        }
      } else	// no changes, just close
        this.close();
    } catch (e) {
      InteractionController.MessageBox.showApplicationException(e);
    }
  }
}
