import { Inject, OnDestroy, OnInit, PLATFORM_ID, Directive } from "@angular/core";
import {DxDataGridComponent} from "devextreme-angular";
import {WaitCursorService} from "../wait-cursor/wait-cursor.service";
import DevExpress from "devextreme/bundles/dx.all";
import {GridHelper} from "../grid/gridhelper";
import {NavigationEnd, Router} from "@angular/router";
import {UserManagerService} from "../../usermanager/usermanager.service";
import {filter} from "rxjs/operators";
import {Subscription} from "rxjs";
import {FunctionModuleBase} from "../functionmodule/function-module-base";
import {DxGridSettings} from "../grid/dx-grid-settings";
import {DetailPanelSettings} from "../splitter/settings/detail-panel-settings";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";

declare var DetailToolbarHelper: any;
declare var NavLinkHoverHelper: any;

@Directive()
export abstract class FunctionWindow implements OnInit, OnDestroy {
  contractName: string;
  dataGrid: DxDataGridComponent;
  dataSource: DevExpress.data.CustomStore;
  loadUrl: string;
  gridKey: string[];
  routerSubscription: Subscription;
  // splitter, detail panel
  sizeMainPanel: number;
  sizeDetailPanel: number;
  detailPanelSettings: DetailPanelSettings = null;
  splitterSize: number;
  filterOperations: any = [ "contains", "=","<>", "<", ">", "<=", ">=", "between" ];

  get isModuleUrl(): boolean {
    if (this.functionModule != null)
      return `/${this.functionModule.routerPath}` == this.router.url;
    else
      return true;
  }

  protected constructor(protected platformId: any, protected userManagerService: UserManagerService, protected router: Router,
    protected waitCursorService: WaitCursorService, protected functionModule: FunctionModuleBase) {
    this.contractName = functionModule.contractName;

    // splitter size
    if (window.screen.width > 768)
      this.splitterSize = 11;
    else
      this.splitterSize = 15;
  }

  ngOnInit(): void {
    this.registerVisualJsEvents();

    this.routerSubscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(
      (event) => {
        if (this.isModuleUrl) {
          this.onMainModuleNavigation(window.history.state);
        }
      }
    );
  }

  async canChangeDate():  Promise<boolean>{
    return true;
  }

  ngOnDestroy(): void {
    this.unregisterVisualJsEvents();

    this.routerSubscription.unsubscribe();
  }

  protected registerVisualJsEvents() {
    DetailToolbarHelper.registerOnDropdownShow();
    DetailToolbarHelper.registerOnDropdownHide();
    NavLinkHoverHelper.registerOnNavLinkTouch();
  }

  protected unregisterVisualJsEvents() {
    DetailToolbarHelper.unregisterOnDropdownShow();
    DetailToolbarHelper.unregisterOnDropdownHide();
    NavLinkHoverHelper.unregisterOnNavLinkTouch();
  }

  new() {

  }

  edit() {

  }

  async delete() {

  }

  onDblClick(e) {
    this.edit();
  }

  async onKeyDown(e) {
    if (e.event.key == "Delete" && this.dataGrid.selectedRowKeys.length > 0)
      await this.delete();
  }

  onGridToolbarPreparing(e) {
    let toolbarItems = e.toolbarOptions.items;
    toolbarItems.forEach((item) => {
      if (item.name == "exportButton") {
        // hide excel export button
        item.visible = false;
      }
    });
  }

  refresh() {
    if (this.dataGrid !== undefined)
      this.dataGrid.instance.refresh();
  }

  reset() {
    if (this.dataGrid !== undefined)
      this.dataGrid.instance.state({});
  }

  export() {
    if (this.dataGrid !== undefined) {
      this.waitCursorService.showWaitCursor();

      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Daten');
      let filename = this.dataGrid.export['fileName'];
      if (!filename)
        filename = 'undefined';
      if (!filename.endsWith('.xlsx'))
        filename += '.xlsx';

      exportDataGrid({
        component: this.dataGrid.instance,
        worksheet: worksheet,
        loadPanel: {
          enabled: false
        }
      }).then(function () {
        workbook.xlsx.writeBuffer()
          .then(function (buffer: BlobPart) {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), filename);
          });
      }).finally(() => {
        this.waitCursorService.hideWaitCursor();
      });
    }
  }

  onGridExporting(e) {
    this.export();
  };

  createDataSource(loadParams: Object = undefined) {
    this.dataSource = GridHelper.createGridDataSource(this.gridKey, this.loadUrl, this.router, this.userManagerService, this, loadParams);
  }

  onMainModuleNavigation(state: any) {
    this.registerVisualJsEvents();
    this.refresh();
  }

  loadState = () => {
    return DxGridSettings.loadDataGridSettings(this.dataGrid, this.resolveSettingsName(), this.platformId);
  };

  saveState = (state) => {
    DxGridSettings.saveDataGridSettings(state, this.resolveSettingsName(), this.platformId);
  };

  public fileName(){
    let fileDate: string = new Date().getFullYear().toString()+ '-' + (new Date().getMonth()+1).toString()+ '-' + new Date().getDate().toString();
    return this.resolveSettingsName() + '-' + fileDate;
  }

  public resolveSettingsName() {
    return this.contractName;
  }

  // splitter detail panel
  protected initDetailPanelSettings() {
    this.detailPanelSettings = this.loadDetailPanelSettings();
    if (this.detailPanelSettings == null) {
      this.detailPanelSettings = new DetailPanelSettings();
      if (window.screen.width > 768) {	// desktop
        this.detailPanelSettings.detailPanelWidth = 20;
        this.detailPanelSettings.detailPanelVisible = true;
      } else if (window.screen.width > 576) { // tablet
        this.detailPanelSettings.detailPanelWidth = 40;
        this.detailPanelSettings.detailPanelVisible = false;
      } else { // smartphone
        this.detailPanelSettings.detailPanelWidth = 100;
        this.detailPanelSettings.detailPanelVisible = false;
      }
    }
  }

  protected setDetailPanelValues() {
    if (this.detailPanelSettings.detailPanelVisible) {
      this.sizeDetailPanel = this.detailPanelSettings.detailPanelWidth;
      this.sizeMainPanel = 100 - this.sizeDetailPanel;
    } else {
      this.sizeDetailPanel = 0;
      this.sizeMainPanel = 100;
    }

    this.saveDetailPanelSettings();
  }

  protected loadDetailPanelSettings() {
    return DetailPanelSettings.loadDetailPanelSettings(`${this.contractName}DetailPanel`, this.platformId);
  }

  protected saveDetailPanelSettings() {
    DetailPanelSettings.saveDetailPanelSettings(this.detailPanelSettings, `${this.contractName}DetailPanel`, this.platformId);
  }

  splitterClick(e) {
    if (this.detailPanelSettings.detailPanelVisible && this.detailPanelSettings.detailPanelWidth == 0)	// set default value
      this.detailPanelSettings.detailPanelWidth = 20;
    else
      this.detailPanelSettings.detailPanelVisible = !this.detailPanelSettings.detailPanelVisible;
    this.setDetailPanelValues();
  }

  splitterDragEnd(e) {
    this.detailPanelSettings.detailPanelWidth = e.sizes[1];
    this.setDetailPanelValues();
  }
}
