import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {FunctionWindow} from "../../../../framework/functionwindow/function-window";
import {UserManagerService} from "../../../../usermanager/usermanager.service";
import {ActivatedRoute, Router} from "@angular/router";
import {WaitCursor, WaitCursorService} from "../../../../framework/wait-cursor/wait-cursor.service";
import {DxDataGridComponent} from "devextreme-angular";
import {ZeitraumChangedEvent} from "../../../../shared/events/zeitraum-changed-event";
import {EventAggregator} from "../../../../framework/event-aggregator/event.aggregator";
import {YearselectionService} from "../../../../shared/services/yearselection.service";
import {LieferantsichtFunctionModule} from "../../functionsmodules/lieferantsicht-function-module.service";
import {Using} from "../../../../framework/using/using";
import {InteractionController} from "../../../../framework/interaction-controller/interaction.controller";
import {FileData, LieferantensichtClient} from "../../../../webapi/webapi.service";
import {IBreadcrumb} from "../../../../shared/interfaces/ibreadcrumb";
import {CustomBreadcrumbEvent} from "../../../../shared/events/custom-breadcrumb-event";
import {FileTool} from "../../../../framework/tools/FileTool";

@Component({
  selector: 'app-lieferantensichtnachweis',
  templateUrl: './lieferantensicht-nachweis.component.html',
  host: {class: 'container-fluid d-flex h-100 flex-column p-0 bg-detailtoolbar'}
})
export class LieferantensichtNachweisComponent extends FunctionWindow implements OnInit, OnDestroy {
  @ViewChild('grid') dataGrid: DxDataGridComponent;
  gridKey: string[] = ["id"];
  loadParams;
  lieferantId: number;
  partnerKonditionId: number;
  rootObject: any = {};
  subscribeLambda = year => {
    this.setYear(year);
  };

  get hasWritePermission(): boolean {
    return this.userManagerService.hasWritePermission(this.functionModule.contractName);
  }

  get isModuleUrl(): boolean {
    return true;
  }

  constructor(@Inject('BASE_URL') baseUrl: string,
              @Inject(PLATFORM_ID) protected platformId: any,
              protected userManagerService: UserManagerService,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected waitCursorService: WaitCursorService,
              protected functionModule: LieferantsichtFunctionModule,
              private yearselectionService: YearselectionService,
              private client: LieferantensichtClient,
              private eventAggregator: EventAggregator) {
    super(platformId, userManagerService, router, waitCursorService, functionModule);
    this.eventAggregator.getEvent(ZeitraumChangedEvent).subscribe(this.subscribeLambda);


    if (this.activatedRoute.snapshot.params.nachweisid !== undefined) {
      this.partnerKonditionId = this.activatedRoute.snapshot.params.nachweisid;
    }
    if (this.activatedRoute.snapshot.parent.params.id !== undefined) {
      this.lieferantId = this.activatedRoute.snapshot.parent.params.id;
    }
    if (window.history.state.rootObjectOffer !== undefined) {
      this.rootObject = window.history.state.rootObjectOffer;
    }

    this.initDetailPanelSettings();
    this.setDetailPanelValues();

    this.loadParams = {year: yearselectionService.year, partnerKonditionId: this.partnerKonditionId};
    this.loadUrl = baseUrl + 'api/lieferantensicht/querynachweisdetailbyid';
    this.createDataSource(this.loadParams);
  }

  async ngOnInit() {
    try {
      await Using.using(new WaitCursor(this.waitCursorService), async () => {
        super.ngOnInit();

        if ((Object.keys(this.rootObject).length === 0)) {
          let offer = await this.client.getKondition(this.partnerKonditionId).toPromise();
          if (offer != null) {
            let lieferant = await this.client.getLieferant(this.lieferantId).toPromise();

            if (lieferant != null) {
              this.rootObject = offer;
              this.rootObject.wawinr = lieferant.wawinr;
              this.rootObject.vlnr = lieferant.vlnr;
              this.rootObject.name = lieferant.name;
            } else {
              await InteractionController.MessageBox.showWarning("Keine Lieferantendaten", "Lieferantendaten konnten nicht abgerufen werden. Möglicherweise existiert der Lieferant nicht.");
              this.router.navigate([`${this.functionModule.routerPath}`]);
            }
          } else {
            await InteractionController.MessageBox.showWarning("Keine Konditionsdaten", "Konditionsdaten konnten nicht abgerufen werden. Möglicherweise existiert die Kondition nicht.");
            this.router.navigate(['.'], {relativeTo: this.activatedRoute.parent});
          }
        }

        if ((Object.keys(this.rootObject).length !== 0)) {
          this.publishCustomBreadCrumb();
        }
      });
    } catch (e) {
      await InteractionController.MessageBox.showApplicationException(e);
      this.router.navigate(['.'], {relativeTo: this.activatedRoute.parent});
    }
  }

  refresh() {
    super.refresh();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.eventAggregator.getEvent(ZeitraumChangedEvent).unsubscribe(this.subscribeLambda);
  }

  statusLookupData = {
    store: {
      type: 'array',
      data: [
        {monat: 1, name: 'Januar'},
        {monat: 2, name: 'Februar'},
        {monat: 3, name: 'März'},
        {monat: 4, name: 'April'},
        {monat: 5, name: 'Mai'},
        {monat: 6, name: 'Juni'},
        {monat: 7, name: 'Juli'},
        {monat: 8, name: 'August'},
        {monat: 9, name: 'September'},
        {monat: 10, name: 'Oktober'},
        {monat: 11, name: 'November'},
        {monat: 12, name: 'Dezember'}
      ],
      key: "monat"
    }
  };

  setYear(year: number) {
    this.loadParams = {year: year, partnerKonditionId: this.partnerKonditionId};
    this.createDataSource(this.loadParams);
  }

  back() {
    this.router.navigate([`.`], {
      relativeTo: this.activatedRoute.parent,
      state: {id: this.partnerKonditionId}
    });
  }

  async navigateToDatei(rowData: any) {
    let entry:FileData = null;
    try {
      await Using.using(new WaitCursor(this.waitCursorService), async () => {
        entry = await this.client.getFile(rowData.datei.id).toPromise();
      });
    } catch (e) {
      console.log(e);
      await InteractionController.MessageBox.showWarning("Fehler beim Abrufen der Datei", "Die gewählte Datei konnte nicht abgerufen werden. Möglicherweise existiert die Datei nicht.");
    }
    if(entry != null){
      try {
        FileTool.download(entry.file, entry.type, entry.fileName);
      } catch (e) {
        console.log(e);
        await InteractionController.MessageBox.showWarning("Fehler beim Öffnen der Datei", "Die gewählte Datei konnte nicht geöffnet werden.");
      }
    }
  }

  public resolveSettingsName() {
    return super.resolveSettingsName() + "_Nachweis";
  }

  private publishCustomBreadCrumb() {
    if (this.userManagerService.userLieferanten.length == 1 && this.userManagerService.benutzerArt != 0) {
      let breadcrumbs: IBreadcrumb[] = [];
      let breadcrumb1: IBreadcrumb = {
        breadcrumbGroup: "",
        breadcrumbLabel: `Lieferant: ${this.rootObject.name}`,
        params: {},
        url: this.functionModule.routerPath + `/lieferant/${this.lieferantId}`,
        state: {id: this.partnerKonditionId}
      };
      breadcrumbs.push(breadcrumb1);

      let breadcrumb2: IBreadcrumb = {
        breadcrumbGroup: "",
        breadcrumbLabel: `Partner: ${this.rootObject.bbn}`,
        params: {},
        url: "",
        state: {}
      };
      breadcrumbs.push(breadcrumb2);
      this.eventAggregator.getEvent(CustomBreadcrumbEvent).publish(breadcrumbs);
    } else {
      let breadcrumbs: IBreadcrumb[] = [];

      let breadcrumb1: IBreadcrumb = {
        breadcrumbGroup: "",
        breadcrumbLabel: `Lieferant: ${this.rootObject.name}`,
        params: {},
        url: this.functionModule.routerPath,
        state: {id: this.lieferantId}
      };
      breadcrumbs.push(breadcrumb1);

      let breadcrumb2: IBreadcrumb = {
        breadcrumbGroup: "",
        breadcrumbLabel: `Partner: ${this.rootObject.bbn}`,
        params: {},
        url: this.functionModule.routerPath + `/lieferant/${this.lieferantId}`,
        state: {id: this.partnerKonditionId}
      };
      breadcrumbs.push(breadcrumb2);

      let breadcrumb3: IBreadcrumb = {
        breadcrumbGroup: "",
        breadcrumbLabel: "Nachweis",
        params: {},
        url: "",
        state: {}
      };
      breadcrumbs.push(breadcrumb3);
      this.eventAggregator.getEvent(CustomBreadcrumbEvent).publish(breadcrumbs);
    }
  }
}
