import {Router} from '@angular/router';
import {UserManagerService} from "../../usermanager/usermanager.service";
import DevExpress from "devextreme/bundles/dx.all";
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import {NavigationExtras} from "@angular/router";
import {FunctionWindow} from "../functionwindow/function-window";

export class GridHelper {

  public static createGridDataSource(key: string[], url: string, router: Router, userManagerService: UserManagerService, functionWindow: FunctionWindow, loadParams: Object = undefined): DevExpress.data.CustomStore {
    let dataSource = AspNetData.createStore({
      key: key,
      loadUrl: url,
      loadParams: loadParams,
      onBeforeSend: function (method, ajaxOptions) {
        if (userManagerService.currentToken != null) {
          ajaxOptions.headers = {"Authorization": `Bearer ${userManagerService.currentToken}`};
        }
      },
      onAjaxError: async function (e) {
        // if unauthorized, then probably expiration time is reached.
        if (e.xhr.status == 401) {
          let returnUrl = router.url;
          try {
            // try to restore token
            await userManagerService.login(true);
            if (functionWindow != null) {
              if (functionWindow.dataGrid !== undefined)
                functionWindow.dataGrid.instance.refresh();
            }
          } catch (innerError) {
            // token could not be restored, go to login page
            userManagerService.logout();
          }
        }
        else if (e.xhr.status == 403) {		// forbidden, go to home
			router.navigate(['/']);
		}
      }
    });

    return dataSource;
  }

  public static createEditableGridDataSource(key: string[], url: string,updateUrl: string, deleteUrl: string, insertUrl: string, router: Router, userManagerService: UserManagerService, functionWindow: FunctionWindow, loadParams: Object = undefined): DevExpress.data.CustomStore {
    let dataSource = AspNetData.createStore({
      key: key,
      loadUrl: url,
      loadParams: loadParams,
      updateMethod: "POST",
      updateUrl: updateUrl,
      deleteMethod: "DELETE",
      deleteUrl: deleteUrl,
      insertMethod: "POST",
      insertUrl: insertUrl,
      onBeforeSend: function (method, ajaxOptions) {
        if (userManagerService.currentToken != null) {
          ajaxOptions.headers = {"Authorization": `Bearer ${userManagerService.currentToken}`};
        }
      },
      onAjaxError: async function (e) {
        // if unauthorized, then probably expiration time is reached.
        if (e.xhr.status == 401) {
          let returnUrl = router.url;
          try {
            // try to restore token
            await userManagerService.login(true);
            if (functionWindow != null) {
              if (functionWindow.dataGrid !== undefined)
                functionWindow.dataGrid.instance.refresh();
            }
          } catch (innerError) {
            // token could not be restored, go to login page
            userManagerService.logout();
          }
        }
      }
    });

    return dataSource;
  }
}
