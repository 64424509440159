import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WaitCursorComponent} from "./wait-cursor.component";

@NgModule({
  declarations: [
    WaitCursorComponent
  ],
  exports: [
    WaitCursorComponent
  ],
  imports: [
    CommonModule
  ]
})
export class WaitCursorModule {
}
