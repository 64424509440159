import {Component, OnInit} from '@angular/core';
import {UserManagerService} from "../usermanager/usermanager.service";
import {ActivatedRoute, NavigationEnd, NavigationExtras, Params, PRIMARY_OUTLET, Router} from "@angular/router";
import {filter} from 'rxjs/operators';
import {IBreadcrumb} from '../shared/interfaces/ibreadcrumb'
import {EventAggregator} from "../framework/event-aggregator/event.aggregator";
import {CustomBreadcrumbEvent} from "../shared/events/custom-breadcrumb-event";

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent implements OnInit {
  public breadcrumbs: IBreadcrumb[] = [];

  constructor(public userManagerService: UserManagerService, private activatedRoute: ActivatedRoute, private router: Router, private eventAggregator: EventAggregator) {
    this.eventAggregator.getEvent(CustomBreadcrumbEvent).subscribe(breadcrumbs => {
      this.breadcrumbs = breadcrumbs as IBreadcrumb[];
    });
  }

  ngOnInit() {
    //subscribe to the NavigationEnd event
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      //set breadcrumbs
      let root: ActivatedRoute = this.activatedRoute.root;
      this.breadcrumbs = this.getBreadcrumbs(root);
    });
  }

  private getBreadcrumbs(route: ActivatedRoute, url: string = "", breadcrumbs: IBreadcrumb[] = []): IBreadcrumb[] {
    const ROUTE_DATA_BREADCRUMB: string = "breadcrumbLabel";
    const ROUTE_DATA_BREADCRUMB_GROUP: string = "breadcrumbGroup";

    //get the child routes
    let children: ActivatedRoute[] = route.children;

    //return if there are no more children
    if (children.length === 0) {
      return breadcrumbs;
    }

    //iterate over each children
    for (let child of children) {
      //verify primary route
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }

      //verify the custom data property "breadcrumb" is specified on the route
      if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }

      //get the route's URL segment
      let routeURL: string = child.snapshot.url.map(segment => segment.path).join("/");

      //append route URL to URL
      url += `/${routeURL}`;

      //add breadcrumb
      let breadcrumb: IBreadcrumb = {
        breadcrumbGroup: child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB_GROUP) ? child.snapshot.data[ROUTE_DATA_BREADCRUMB_GROUP] : "",
        breadcrumbLabel: child.snapshot.data[ROUTE_DATA_BREADCRUMB],
        params: child.snapshot.params,
        url: url,
        state: {}
      };
      breadcrumbs.push(breadcrumb);

      //recursive
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }

    //we should never get here, but just in case
    return breadcrumbs;
  }

  navigate(breadcrumbItem: IBreadcrumb) {
    let navigationExtras: NavigationExtras = {state: breadcrumbItem.state};
    if (!(Object.keys(breadcrumbItem).length === 0)) {
      navigationExtras = {queryParams: breadcrumbItem.params, state: breadcrumbItem.state};
    }
    this.router.navigate([breadcrumbItem.url], navigationExtras);
  }
}
