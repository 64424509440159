<header>
  <nav class="navbar navbar-expand-lg navbar-toggleable-lg maintoolbar smallmedia-navbar py-1">

    <button class="navbar-toggler collapsed smallmedia"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target=".navbar-collapse"
            aria-label="Toggle navigation"
            aria-expanded="false" [hidden]="userManagerService.loginName == ''">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="navbar-brand me ms-3 smallmedia" *ngIf="userManagerService.loginName == '';else elseBlockIcon">
      <img src="./assets/images/logo-small.svg"/><span>FS PoKuLi</span>
    </div>
    <ng-template #elseBlockIcon>
      <a class="navbar-brand me-lg-5 ms-lg-3 smallmedia" [routerLink]="['/']">
        <img src="./assets/images/logo-small.svg"/><span>FS PoKuLi</span>
      </a>
    </ng-template>

    <div *ngIf="isNotMobile"
         placement="bottom"
         [ngbTooltip]="infoText"
         class="order-lg-3 me ms-auto smallmedia hinweisText"
         [hidden]="userManagerService.loginName == ''">
      {{infoText}}
    </div>

    <form class="order-lg-3 me ms-auto smallmedia-selector" [hidden]="userManagerService.loginName == ''">
      <select #selectYear class="me-sm-2 custom-select-sm" id="zeitraumSelect" [(ngModel)]="yearselectionService.year"
               [ngModelOptions]="{standalone: true}">
        <option *ngFor="let element of yearselectionService.yearList" [ngValue]="element.jahr">{{element.jahr}}
          <!--          <ng-container *ngIf="element.status == true"> (aktiv)</ng-container>-->
          <!--          <ng-container *ngIf="element.status == false"> (inaktiv)</ng-container>-->
        </option>
      </select>
    </form>

    <div class="quickfunctions order-lg-3 nav-item smallmedia" [hidden]="userManagerService.loginName == ''">
      <a class="nav-link navlink-hover" (click)="openPdf()">
        <img height="" src="./assets/images/Hilfe.svg"/>
      </a>
    </div>

    <div class="quickfunctions order-lg-3 nav-item smallmedia" [hidden]="userManagerService.loginName == ''">
      <a class="nav-link navlink-hover" [href]="emailstring">
        <img height="" src="./assets/images/Mail.svg"/>
      </a>
    </div>

    <div class="quickfunctions order-lg-3 me-3 smallmedia" [hidden]="userManagerService.loginName == ''">
      <div class="nav-item dropdown">
        <a class="nav-link navlink-hover" href="#" id="userDropdown" role="button" data-bs-toggle="dropdown"
           aria-haspopup="true" aria-expanded="false">
          <img src="./assets/images/User.svg"/>
        </a>
        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
          <p class="dropdown-header">{{userManagerService.fullUserName}}</p>
          <a class="dropdown-item" (click)="logout()">Abmelden</a>
        </div>
      </div>
    </div>

    <div class="navbar-collapse collapse d-lg-inline-flex flex-lg-row-reverse order-lg-2"
         [hidden]="userManagerService.loginName == ''">
      <ul class="navbar-nav me-auto" [hidden]="userManagerService.loginName == ''">
        <ng-container *ngFor="let group of this.functionMenuService.functionGroups">
          <li class="nav-item dropdown me-2" data-bs-toggle="collapse" data-target=".navbar-collapse.show"
              [hidden]="!group.visible">
            <a class="nav-link dropdown-toggle navlink-hover" href="#" role="button" data-bs-toggle="dropdown"
               aria-haspopup="true" aria-expanded="false">
              {{group.itemName}}
            </a>

            <ng-template #menuTemplateRef let-group>
              <ng-container *ngFor="let menu of group.menuItems">
                <ng-container *ngIf="menu.menuItems.length != 0;else elseBlock">

                  <div class="nav-item dropdown-submenu" [hidden]="!menu.visible">
                    <a class="dropdown-item dropdown-toggle" href="#" role="button"
                       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                       {{menu.itemName}}
                    </a>

                    <!-- Invoke the recursive template. -->
                    <div class="dropdown-menu">
                      <ng-container
                        *ngTemplateOutlet="menuTemplateRef; context:{ $implicit: menu }"></ng-container>
                    </div>
                  </div>

                </ng-container>
                <ng-template #elseBlock>
                  <ng-container *ngIf="menu.functionModule.externalURLPath != null">
                    <a  class="dropdown-item" (click)=openExternalURL(menu.functionModule.externalURLPath)
                       [hidden]="!menu.visible">{{menu.itemName}}</a>
                  </ng-container>
                  <ng-container *ngIf="menu.functionModule.externalURLPath == null">
                    <a class="dropdown-item" [routerLink]="menu.functionModule.routerPath"
                       [hidden]="!menu.visible">{{menu.itemName}}</a>
                  </ng-container>
                </ng-template>
              </ng-container>
            </ng-template>

            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <ng-container
                *ngTemplateOutlet="menuTemplateRef; context:{ $implicit: group }"></ng-container>
            </div>
          </li>
        </ng-container>
      </ul>
    </div>

    <!--    <div class="navbar-collapse collapse d-lg-inline-flex flex-lg-row-reverse order-lg-2"-->
    <!--         [hidden]="userManagerService.loginName == ''">-->
    <!--    </div>-->


  </nav>

</header>
