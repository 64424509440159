export class FileTool {

  public static open(base64Data : string, type: string, filename: string){
    if(this.isIE()){
      let blob = this.binaryToBlob(base64Data, type);
      (window.navigator as any).msSaveOrOpenBlob(blob, filename);
    }
    else {
      const fileURL = this.binaryToFileUrl(base64Data, type);
      this.showInBrowser(fileURL, filename);
      setTimeout(function() {
        window.URL.revokeObjectURL(fileURL);
      }, 100);
    }
  }

  public static download(base64Data : string, type: string, filename: string){
    if(this.isIE()){
      let blob = this.binaryToBlob(base64Data, type);
      (window.navigator as any).msSaveOrOpenBlob(blob, filename);
    } else{
      const fileURL = this.binaryToFileUrl(base64Data, type);
      this.openFileLink(fileURL, filename);
      setTimeout(function() {
        window.URL.revokeObjectURL(fileURL);
      }, 100);
    }
  }

  private static binaryToFileUrl(base64Data: string, type: string) {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const file = new Blob([byteArray], {type: type});
    const fileURL = URL.createObjectURL(file);
    return fileURL;
  }

  private static binaryToBlob(base64Data: string, type: string) {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {type: type});
    return blob;
  }

  private static showInBrowser(fileURL, filename: string) {
    window.open(fileURL, filename);
  }

  private static openFileLink(fileURL, filename: string) {
    const fileLink = document.createElement('a');
    fileLink.href = fileURL;
    fileLink.download = filename;
    fileLink.click();
    fileLink.remove();
  }

  private static isIE() : boolean {
    let retValue = false;

    let ua = window.navigator.userAgent;
    if (ua.indexOf("MSIE") > 0 || ua.indexOf("Trident") > 0)
    {
      retValue = true;
    }
    console.log("IS IE:" + retValue);
    return retValue;
  }
}
