import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {FunctionWindow} from "../../../../framework/functionwindow/function-window";
import {UserManagerService} from "../../../../usermanager/usermanager.service";
import {ActivatedRoute, Router} from "@angular/router";
import {WaitCursorService} from "../../../../framework/wait-cursor/wait-cursor.service";
import {DxDataGridComponent} from "devextreme-angular";
import {GridHelper} from "../../../../framework/grid/gridhelper";
import {EventAggregator} from "../../../../framework/event-aggregator/event.aggregator";
import {ZeitraumChangedEvent} from "../../../../shared/events/zeitraum-changed-event";
import {YearselectionService} from "../../../../shared/services/yearselection.service";
import {LieferantenkonditionenFunctionModule} from "../../functionmodules/lieferantenkonditionen-function-module";
import {InteractionController} from "../../../../framework/interaction-controller/interaction.controller";
import {LieferantenkonditionClient} from "../../../../webapi/webapi.service";
import {
  LieferantenkonditionenDialogComponent,
  LieferantenkonditionenDialogParameter
} from "./lieferantenkonditionen-dialog/lieferantenkonditionen-dialog.component";
import {Observable} from "rxjs";
import {ZeitraumCanChangeEvent} from "../../../../shared/events/zeitraum-can-change-event";

@Component({
  selector: 'app-lieferantenkonditionen',
  templateUrl: './lieferantenkonditionen.component.html',
  host: {class: 'container-fluid d-flex h-100 flex-column p-0 bg-detailtoolbar'}
})
export class LieferantenkonditionenComponent extends FunctionWindow implements OnInit, OnDestroy {


  @ViewChild('grid') dataGrid: DxDataGridComponent;
  gridKey: string[] = ["id", "gegenleistungsart", "zeitraum", "ksnr", "lieferantenkonditionId"];
  insertUrl: string;
  deleteUrl: string;
  updateUrl: string;
  loadParams;
  subscribeLambda = year => {
    this.setYear(year);
  };

  get hasWritePermission(): boolean {
    return this.userManagerService.hasWritePermission(this.functionModule.contractName);
  }

  constructor(@Inject('BASE_URL') baseUrl: string,
              @Inject(PLATFORM_ID) protected platformId: any,
              public userManagerService: UserManagerService,
              private client: LieferantenkonditionClient,
              public yearselectionService: YearselectionService,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected waitCursorService: WaitCursorService,
              protected functionModule: LieferantenkonditionenFunctionModule,
              private eventAggregator: EventAggregator) {
    super(platformId, userManagerService, router, waitCursorService, functionModule);
    this.eventAggregator.getEvent(ZeitraumChangedEvent).subscribe(this.subscribeLambda);
    this.loadUrl = baseUrl + 'api/lieferantenkondition/query';
    this.insertUrl = null; //baseUrl + 'api/gegenleistungsart/Save';
    this.deleteUrl = null; //baseUrl + 'api/gegenleistungsart/Delete';
    this.updateUrl = baseUrl + 'api/lieferantenkondition/Update';
    this.loadParams = {year: yearselectionService.year};
    this.dataSource = GridHelper.createEditableGridDataSource(this.gridKey, this.loadUrl, this.updateUrl, this.deleteUrl, this.insertUrl, this.router, this.userManagerService, this, this.loadParams);
    this.refresh();
    this.eventAggregator.getEvent(ZeitraumCanChangeEvent).publish(this);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.eventAggregator.getEvent(ZeitraumChangedEvent).unsubscribe(this.subscribeLambda);
    this.eventAggregator.getEvent(ZeitraumCanChangeEvent).publish(undefined);
  }

  setYear(year: number) {
    this.loadParams = {year: year};
    this.dataSource = GridHelper.createEditableGridDataSource(this.gridKey, this.loadUrl, this.updateUrl, this.deleteUrl, this.insertUrl, this.router, this.userManagerService, this, this.loadParams);
  }

  updatingEvent(e) {
    if (e.newData.ksnr != undefined) {
      e.newData.context = {ksnr: e.newData.ksnr};
    } else if (e.oldData.ksnr != undefined) {
      e.newData.context = {ksnr: e.oldData.ksnr};
    }
  }

  validatingEvent(e) {
    if (!e.isValid) {
      InteractionController.MessageBox.showWarning("Validierungsfehler",
        "Ein oder mehrere Validierungsfehler sind aufgetreten. Die fehlerhaften Eingaben sind rot markiert. Bitte korrigieren Sie Ihre Eingaben und versuchen Sie erneut den Datensatz zu speichern.");
    }
  }

  async new() {
    if (this.hasWritePermission) {
      let parameter: LieferantenkonditionenDialogParameter = new LieferantenkonditionenDialogParameter();
      parameter.lieferantenkonditionId = 0;
      if (await InteractionController.showDialog(LieferantenkonditionenDialogComponent, parameter, {size: 'xl'})) {
        this.dataGrid.instance.refresh();
        this.dataGrid.selectedRowKeys = [{id: parameter.lieferantenkonditionId}];
      }
    }
  }

  async delete() {
    console.log(this.dataGrid.selectedRowKeys[0]);
    try {
      if (this.dataGrid.selectedRowKeys.length > 0) {
        if (await InteractionController.MessageBox.showInfoConfirmation("Lieferantenkondition löschen",
          "Die Lieferantenkondition wird unwiderruflich gelöscht.<br /> Falls Partnerkonditionen mit der gewählten Lieferantenkondition verbunden sind werden diese mit gelöscht.<br /> Wollen Sie die ausgewählte Lieferantenkondition wirklich löschen?")) {
          let retValue = await this.client.delete(this.dataGrid.selectedRowKeys[0].lieferantenkonditionId, this.dataGrid.selectedRowKeys[0].gegenleistungsart, this.dataGrid.selectedRowKeys[0].zeitraum, this.dataGrid.selectedRowKeys[0].ksnr).toPromise();
          this.refresh();
        }
      } else {
        InteractionController.MessageBox.showWarning("Keine Auswahl", "Bitte selektieren Sie einen Eintrag aus der Liste und führen Sie die Operation erneut aus.");
      }
    } catch (e) {
      InteractionController.MessageBox.showApplicationException(e);
    }
  }

  async canDeactivate(): Promise<Observable<boolean> | boolean> {
    return this.checkDatagridEditData();
  }

  async canChangeDate(): Promise<boolean> {
    return this.checkDatagridEditData();
  }

  private async checkDatagridEditData() {
    if (this.dataGrid.instance.hasEditData()) {
      if (await InteractionController.MessageBox.showInfoConfirmation("Änderungen verwerfen",
        "Sie haben noch nicht gespeicherte Änderungen. Wollen Sie die Änderungen verwerfen?")) {
        this.dataGrid.instance.cancelEditData();
        return true;
      } else {
        return false
      }
    } else {
      return true;
    }
  }
}
