import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {FunctionWindow} from "../../../../framework/functionwindow/function-window";
import {UserManagerService} from "../../../../usermanager/usermanager.service";
import {ActivatedRoute, Router} from "@angular/router";
import {WaitCursor, WaitCursorService} from "../../../../framework/wait-cursor/wait-cursor.service";
import {DxDataGridComponent} from "devextreme-angular";
import {ZeitraumChangedEvent} from "../../../../shared/events/zeitraum-changed-event";
import {EventAggregator} from "../../../../framework/event-aggregator/event.aggregator";
import {YearselectionService} from "../../../../shared/services/yearselection.service";
import {LieferantsichtFunctionModule} from "../../functionsmodules/lieferantsicht-function-module.service";
import {InteractionController} from "../../../../framework/interaction-controller/interaction.controller";
import {IBreadcrumb} from "../../../../shared/interfaces/ibreadcrumb";
import {CustomBreadcrumbEvent} from "../../../../shared/events/custom-breadcrumb-event";
import {LieferantensichtClient} from "../../../../webapi/webapi.service";
import {Using} from "../../../../framework/using/using";

@Component({
  selector: 'app-lieferantensichtpartner',
  templateUrl: './lieferantensicht-partner.component.html',
  host: {class: 'container-fluid d-flex h-100 flex-column p-0 bg-detailtoolbar'}
})
export class LieferantensichtPartnerComponent extends FunctionWindow implements OnInit, OnDestroy {
  @ViewChild('grid') dataGrid: DxDataGridComponent;
  gridKey: string[] = ["partnerId"];
  loadParamsPartner;
  lieferantId: number;
  rootObject: any = {};
  subscribeLambda = year => {
    this.setYear(year);
  };

  statusLookupData = {
    store: {
      type: 'array',
      data: [
        {status: null, name: ''},
        {status: false, name: 'Nein'},
        {status: true, name: 'Ja'}
      ],
      key: "status"
    }
  };

  customizeTooltip = (pointsInfo) => {
    return {text: parseInt(pointsInfo.originalValue) + "%"};
  };

  get hasWritePermission(): boolean {
    return this.userManagerService.hasWritePermission(this.functionModule.contractName);
  }

  constructor(@Inject('BASE_URL') baseUrl: string,
              @Inject(PLATFORM_ID) protected platformId: any,
              protected userManagerService: UserManagerService,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected waitCursorService: WaitCursorService,
              protected functionModule: LieferantsichtFunctionModule,
              private yearselectionService: YearselectionService,
              private client: LieferantensichtClient,
              private eventAggregator: EventAggregator) {
    super(platformId, userManagerService, router, waitCursorService, functionModule);
    this.eventAggregator.getEvent(ZeitraumChangedEvent).subscribe(this.subscribeLambda);

    if (this.activatedRoute.snapshot.params.id !== undefined) {
      this.lieferantId = this.activatedRoute.snapshot.params.id;
    }
    if (window.history.state.rootObjectOffer !== undefined) {
      this.rootObject = window.history.state.rootObjectOffer;
    }

    this.initDetailPanelSettings();
    this.setDetailPanelValues();

    this.loadParamsPartner = {year: yearselectionService.year, lieferantId: this.lieferantId};
    this.loadUrl = baseUrl + 'api/lieferantensicht/querypartnerkonditionbyid';
    this.createDataSource(this.loadParamsPartner);
  }

  onMainModuleNavigation(state: any) {
    super.onMainModuleNavigation(state);
    this.publishCustomBreadCrumb();
    // we navigated back from client
    if (window.history.state.id !== undefined && window.history.state.id != 0) {
      if (this.dataGrid !== undefined) {
        this.dataGrid.selectedRowKeys = [{id: +window.history.state.id}];
      }
    }
  }

  async ngOnInit() {
    try {
      await Using.using(new WaitCursor(this.waitCursorService), async () => {
        super.ngOnInit();
        this.rootObject = await this.client.getLieferant(this.lieferantId).toPromise();
        if ((Object.keys(this.rootObject).length === 0)) {
          let lieferant = await this.client.getLieferant(this.lieferantId).toPromise();
          if (lieferant != null) {
            this.rootObject.wawinr = lieferant.wawinr;
            this.rootObject.vlnr = lieferant.vlnr;
            this.rootObject.name = lieferant.name;
          } else {
            await InteractionController.MessageBox.showWarning("Keine Lieferantendaten", "Lieferantendaten konnten nicht abgerufen werden. Möglicherweise existiert der Lieferant nicht.");
            this.router.navigate([`${this.functionModule.routerPath}`]);
          }
        }
        if ((Object.keys(this.rootObject).length !== 0)) {
          this.publishCustomBreadCrumb();
        }
      });
    } catch (e) {
      await InteractionController.MessageBox.showApplicationException(e);
      this.router.navigate(['.'], {relativeTo: this.activatedRoute.parent});
    }
  }

  private publishCustomBreadCrumb() {
    if (this.userManagerService.userLieferanten.length == 1 && this.userManagerService.benutzerArt != 0) {
      let breadcrumbs: IBreadcrumb[] = [];
      let breadcrumb1: IBreadcrumb = {
        breadcrumbGroup: `Lieferant: ${this.rootObject.name}`,
        breadcrumbLabel: "Konditionen",
        params: {},
        url: "",
        state: {}
      };
      breadcrumbs.push(breadcrumb1);
      this.eventAggregator.getEvent(CustomBreadcrumbEvent).publish(breadcrumbs);
    } else {
      let breadcrumbs: IBreadcrumb[] = [];
      let breadcrumb1: IBreadcrumb = {
        breadcrumbGroup: "",
        breadcrumbLabel: `Lieferant: ${this.rootObject.name}`,
        params: {},
        url: this.functionModule.routerPath,
        state: {id: this.lieferantId}
      };
      breadcrumbs.push(breadcrumb1);

      let breadcrumb2: IBreadcrumb = {
        breadcrumbGroup: "",
        breadcrumbLabel: "Konditionen",
        params: {},
        url: "",
        state: {}
      };
      breadcrumbs.push(breadcrumb2);
      this.eventAggregator.getEvent(CustomBreadcrumbEvent).publish(breadcrumbs);
    }
  }

  get isModuleUrl(): boolean {
    if (this.functionModule != null) {
      let url = this.router.url;
      url = url.replace(`/${this.functionModule.routerPath}`, "");
      let urlParts: string[] = url.split("/");
      return urlParts.length == 3;	// second navigation level
    } else
      return true;
  }

  get showBackButton(): boolean {
    return (this.userManagerService.userLieferanten.length != 1 || this.userManagerService.benutzerArt == 0);
  }

  refresh() {
    super.refresh();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.eventAggregator.getEvent(ZeitraumChangedEvent).unsubscribe(this.subscribeLambda);
  }

  setYear(year: number) {
    this.loadParamsPartner = {year: year, lieferantId: this.lieferantId};
    this.createDataSource(this.loadParamsPartner);
  }

  back() {
    this.router.navigate(['.'], {relativeTo: this.activatedRoute.parent, state: {id: this.lieferantId}});
  }

  public resolveSettingsName() {
    return super.resolveSettingsName() + "_Partner";
  }

  getErfuellunsgrad(data: number) : number {
    let retValue: number = 0;
    if (data != null){
      retValue = data;
    }
    return retValue;
  }

  navigateToNachweis(rowData: any) {
    rowData.wawinr = this.rootObject.wawinr;
    rowData.vlnr = this.rootObject.vlnr;
    rowData.name = this.rootObject.name;
    this.router.navigate([`nachweis/${rowData.partnerkonditionId}`], {
      relativeTo: this.activatedRoute,
      state: {rootObjectOffer: rowData}
    });
  }
}
