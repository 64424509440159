import {Injectable} from "@angular/core";
import {FunctionModuleBase} from "../../../framework/functionmodule/function-module-base";
import {UserManagerService} from "../../../usermanager/usermanager.service";
import {Router} from "@angular/router";
import {PartnersichtPartnerUebersichtComponent} from "../partner/partnersicht-partner-uebersicht/partnersicht-partner-uebersicht.component";
import {ModuleRoute} from "../../../framework/functionmodule/module-route";
import {UserPermissionSuffix} from "../../../usermanager/model/user-permission-suffix";
import {PartnersichtKonditionComponent} from "../partner/partnersicht-kondition/partnersicht-kondition.component";
import {PartnersichtGegenleistungComponent} from "../partner/partnersicht-gegenleistung/partnersicht-gegenleistung.component";
import {PartnersichtGegenleistungUebersichtComponent} from "../partner/partnersicht-gegenleistung-uebersicht/partnersicht-gegenleistung-uebersicht.component";

@Injectable({
  providedIn: 'root'
})
export class PartnersichtFunctionModule extends FunctionModuleBase {
  contractName = "Partnersicht";
  functionName = "Partnersicht";
  groupName = "Funktionen";
  routerPath = "partnersicht";
  component = PartnersichtPartnerUebersichtComponent;
  homeTileClass = "tileGroupClass1";
  homeTileImageSource = './assets/images/Report.svg';
  homeTileSubTitle = "Partnersicht";

  constructor(protected userManagerService: UserManagerService, protected router: Router) {
    super(userManagerService, router);
    this.configureFunctionModule();
  }

  configureModuleRoutes() {
    super.configureModuleRoutes();

    let offerRoute = new ModuleRoute('partner/:id', 'Konditionen', PartnersichtKonditionComponent, `${this.contractName}${UserPermissionSuffix.ReadPermission}`);
    offerRoute.moduleRoutes.push(new ModuleRoute('nachweis', 'Nachweis', PartnersichtGegenleistungComponent, `${this.contractName}${UserPermissionSuffix.ReadPermission}`),
                                 new ModuleRoute('nachweis/:nachweisId', 'Nachweis Übersicht', PartnersichtGegenleistungUebersichtComponent, `${this.contractName}${UserPermissionSuffix.ReadPermission}`));

    this.moduleRoutes.push(
      offerRoute
    );
  }
}
