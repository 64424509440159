import {NgModule} from '@angular/core';
import {YearselectionService} from "./services/yearselection.service";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {DxDataGridModule} from "devextreme-angular";
import {NgbTooltipModule, NgbTypeaheadModule} from "@ng-bootstrap/ng-bootstrap";
import {RouterModule} from "@angular/router";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DxDataGridModule,
    NgbTooltipModule,
    NgbTypeaheadModule,
    RouterModule,
  ],
  declarations: [
  ],
  providers: [
    YearselectionService
  ]
})
export class SharedModule {
}
