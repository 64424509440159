import {Component, OnInit, ViewChild} from '@angular/core';
import {DialogBase} from "../../../../framework/dialog/dialog.base";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {WaitCursor, WaitCursorService} from "../../../../framework/wait-cursor/wait-cursor.service";
import {UserManagerService} from "../../../../usermanager/usermanager.service";
import {NgForm} from "@angular/forms";
import {Using} from "../../../../framework/using/using";
import {TaskDialogButtons} from "../../../../framework/message-box/taskdialog/task-dialog-buttons";
import {InteractionController} from "../../../../framework/interaction-controller/interaction.controller";
import {LieferantenClient, Lieferant, ReturnCodesSaveLieferant} from "../../../../webapi/webapi.service";

export class LieferantenDialogParameter {
  lieferantId: number;
}

@Component({
  selector: 'app-lieferanten-dialog',
  templateUrl: './lieferanten-dialog.component.html'
})
export class LieferantenDialogComponent extends DialogBase implements OnInit {
  @ViewChild('dialogForm') dialogForm: NgForm;
  title: string = 'Lieferant';
  lieferant: Lieferant = new Lieferant();

  constructor(public activeModal: NgbActiveModal,
              public waitCursorService: WaitCursorService,
              private _userManagerService: UserManagerService,
              private dialogParameter: LieferantenDialogParameter,
              private lieferantClient: LieferantenClient) {
    super(activeModal, waitCursorService);
  }

  async ngOnInit() {
    try {
      await Using.using(new WaitCursor(this.waitCursorService), async () => {
        // create or load lieferant
        if (this.dialogParameter.lieferantId == 0) {
          this.lieferant = new Lieferant();
          this.lieferant.id = 0;
        } else {
          let lieferant = await this.lieferantClient.getLieferanten(this.dialogParameter.lieferantId).toPromise();
          if (lieferant != null) {
            this.lieferant = lieferant;
          } else {
            await InteractionController.MessageBox.showWarning("Keine Lieferantendaten", "Lieferantendaten konnten nicht abgerufen werden. Möglicherweise existiert der Lieferant nicht.");
            this.close();
          }
        }
      });
    } catch (e) {
      await InteractionController.MessageBox.showApplicationException(e);
      this.close();
    }
  }

  public async onOK() {
    try {
      await Using.using(new WaitCursor(this.waitCursorService), async () => {
        if (this.dialogForm.valid) {
          if (this.dialogForm.dirty) {
            if (this.lieferant.vlnr <= 2147483647) {
              // save
              this.lieferant.deleted = false;
              let retValue = await this.lieferantClient.saveLieferanten(this.lieferant).toPromise();
              this.dialogParameter.lieferantId = retValue.id;

              // check return value
              if (retValue.returnCode == ReturnCodesSaveLieferant.Duplicate) {
                InteractionController.MessageBox.showWarning("VL-Nr.", "Die eingegebene VL-Nr. existiert bereits.");
                this.dialogForm.form.controls['lieferanten.vlnr'].setErrors({});
                this.dialogForm.form.controls['lieferanten.vlnr'].markAsTouched();
                return;
              } else if (retValue.returnCode == ReturnCodesSaveLieferant.DuplicateName) {
                InteractionController.MessageBox.showWarning("WAWI-Nr.", "Die eingegebene WAWI-Nr. existiert bereits.");
                this.dialogForm.form.controls['lieferanten.wawinr'].setErrors({});
                this.dialogForm.form.controls['lieferanten.wawinr'].markAsTouched();
                return;
              }
            }else{
              InteractionController.MessageBox.showWarning("VL-Nr.", 'Die eingegebene VL-Nr. darf den Wert "2147483647" nicht überschreiten.');
              this.dialogForm.form.controls['lieferanten.wawinr'].setErrors({});
              this.dialogForm.form.controls['lieferanten.wawinr'].markAsTouched();
              return;
            }

            // close dialog with OK
            this.close();
            this.onButtonPressed.emit(TaskDialogButtons.OK);
          } else	// no changes, just close
            this.close();
        } else {
          this.dialogForm.form.controls['lieferanten.wawinr'].markAsTouched();
          this.dialogForm.form.controls['lieferanten.vlnr'].markAsTouched();
          this.dialogForm.form.controls['lieferanten.name'].markAsTouched();
          InteractionController.MessageBox.showWarning("Validierungsfehler",
            "Ein oder mehrere Validierungsfehler sind aufgetreten. Die fehlerhaften Eingaben sind rot markiert. Bitte korrigieren Sie Ihre Eingaben und versuchen Sie erneut den Datensatz zu speichern.");
        }
      });
    } catch (e) {
      InteractionController.MessageBox.showApplicationException(e);
    }
  }
}
