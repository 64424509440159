import {Component, Inject, OnInit} from '@angular/core';

import deMessages from "devextreme/localization/messages/de.json";
import {locale, loadMessages} from "devextreme/localization";
import {UserManagerService} from "./usermanager/usermanager.service";
import {FunctionModuleBase} from "./framework/functionmodule/function-module-base";
import {InteractionController} from "./framework/interaction-controller/interaction.controller";
import {slideInAnimation} from "./animations";
import {Router, RouterOutlet} from "@angular/router";
import {WaitCursorService} from "./framework/wait-cursor/wait-cursor.service";
import {TokenService} from "./authentication/token.service";
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [slideInAnimation]
})
export class AppComponent implements OnInit {
  assetImages = new Array();

  constructor(
    private userManagerService: UserManagerService,
    @Inject(FunctionModuleBase)
    private functionModules: FunctionModuleBase[],
    private interactionController: InteractionController,
    private waitCursorService: WaitCursorService,
    private tokenService: TokenService,
    private location: Location
  ) {
    loadMessages(deMessages);
    locale(navigator.language);

    tokenService.setLocation(location.path());
    tokenService.configureAuth();
    tokenService.parseUrlTokens();
    userManagerService.initUserManager(functionModules);
  }

  ngOnInit(): void {
    this.preloadImages(
      "./assets/images/Back.svg",
      "./assets/images/Cancel.svg",
      "./assets/images/Copy.svg",
      "./assets/images/Delete.svg",
      "./assets/images/Edit.svg",
      "./assets/images/error.svg",
      "./assets/images/ExcelExport.svg",
      "./assets/images/Home.svg",
      "./assets/images/info.svg",
      "./assets/images/New.svg",
      "./assets/images/Print.svg",
      "./assets/images/question.svg",
      "./assets/images/Refresh.svg",
      "./assets/images/Save.svg",
      "./assets/images/User.svg",
      "./assets/images/View.svg",
      "./assets/images/warning.svg",
      "./assets/images/logo-small.svg",
      "./assets/images/logo.svg",
      "./assets/images/FunctionWindow.svg",
      "./assets/images/UserManager.svg",
      "./assets/images/Download.svg",
      "./assets/images/Report.svg",
      "./assets/images/Reset.svg",
      "./assets/images/Abrechnung.svg",
      "./assets/images/ChangeState.svg",
      "./assets/images/Folder.svg",
      "./assets/images/File.svg",
      "./assets/images/Textfile.svg",
      "./assets/images/Word.svg",
      "./assets/images/Excel.svg",
      "./assets/images/Pdf.svg",
      "./assets/images/Upload.svg",
      "./assets/images/Mail.svg",
      "./assets/images/Hilfe.svg",
      "./assets/images/Liste.svg",
      "./assets/images/Lieferant.svg",
      "./assets/images/Zeitraum.svg",
      "./assets/images/Partner.svg",
      "./assets/images/Gesamtansicht.svg",
      "./assets/images/Rollen.svg",
      "./assets/images/Arten.svg",
      "./assets/images/Nachweis.svg"
    );
  }

  preloadImages(...args: any[]): void {
    for (let i = 0; i < args.length; ++i) {
      this.assetImages[i] = new Image();
      this.assetImages[i].src = args[i];
    }
  }

  getAnimationData(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }
}
