import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {FunctionWindow} from "../../../../../framework/functionwindow/function-window";
import {UserManagerService} from "../../../../../usermanager/usermanager.service";
import {ActivatedRoute, Router} from "@angular/router";
import {WaitCursor, WaitCursorService} from "../../../../../framework/wait-cursor/wait-cursor.service";
import {DxDataGridComponent} from "devextreme-angular";
import {InteractionController} from "../../../../../framework/interaction-controller/interaction.controller";

import {LieferantensichtClient} from "../../../../../webapi/webapi.service";
import {LieferantenkonditionenpflegeFunctionModule} from "../../../functionmodules/lieferantenkonditionenpflege-function-module";
import {ZeitraumChangedEvent} from "../../../../../shared/events/zeitraum-changed-event";
import {YearselectionService} from "../../../../../shared/services/yearselection.service";
import {EventAggregator} from "../../../../../framework/event-aggregator/event.aggregator";
import {IBreadcrumb} from "../../../../../shared/interfaces/ibreadcrumb";
import {CustomBreadcrumbEvent} from "../../../../../shared/events/custom-breadcrumb-event";
import {Using} from "../../../../../framework/using/using";
import {
  LieferantenkonditionenpflegeDialogComponent,
  LieferantenkonditionenpflegeDialogParameter
} from "../lieferantenkontitionenpflege-dialog/lieferantenkonditionenpflege-dialog.component";

@Component({
  selector: 'app-lieferantenkonditionenpflege',
  templateUrl: './lieferantenkonditionenpflege.component.html',
  host: {class: 'container-fluid d-flex h-100 flex-column p-0 bg-detailtoolbar'}
})
export class LieferantenkonditionenpflegeComponent extends FunctionWindow implements OnInit, OnDestroy {
  @ViewChild('grid') dataGrid: DxDataGridComponent;
  gridKey: string[] = ["id", "lieferantenkonditionId"];
  loadParams;
  lieferantenId: number;
  rootObject: any = {};

  subscribeLambda = year => {
    this.setYear(year);
  };

  get hasWritePermission(): boolean {
    return this.userManagerService.hasWritePermission(this.functionModule.contractName);
  }

  constructor(@Inject('BASE_URL') baseUrl: string,
              @Inject(PLATFORM_ID) protected platformId: any,
              public userManagerService: UserManagerService,
              private client: LieferantensichtClient,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected waitCursorService: WaitCursorService,
              protected functionModule: LieferantenkonditionenpflegeFunctionModule,
              public yearselectionService: YearselectionService,
              private eventAggregator: EventAggregator) {
    super(platformId, userManagerService, router, waitCursorService, functionModule);

    this.eventAggregator.getEvent(ZeitraumChangedEvent).subscribe(this.subscribeLambda);

    if (this.activatedRoute.snapshot.params.id !== undefined) {
      this.lieferantenId = this.activatedRoute.snapshot.params.id;
    }

    if (window.history.state.rootObjectOffer !== undefined) {
      this.rootObject = window.history.state.rootObjectOffer;
    }

    this.loadParams = {id: this.lieferantenId, year: yearselectionService.year};
    this.loadUrl = baseUrl + 'api/lieferantenkonditionOverview/getKonditionenByLieferantenId';
    this.createDataSource(this.loadParams);
  }

  onMainModuleNavigation(state: any) {
    super.onMainModuleNavigation(state);
    this.publishCustomBreadCrumb();
    // we navigated back from client
    if (window.history.state.id !== undefined && window.history.state.id != 0) {
      if (this.dataGrid !== undefined) {
        this.dataGrid.selectedRowKeys = [{id: +window.history.state.id}];
      }
    }
  }

  async ngOnInit() {
    try {
      await Using.using(new WaitCursor(this.waitCursorService), async () => {
        super.ngOnInit();

        this.rootObject = await this.client.getLieferant(this.lieferantenId).toPromise();
        if ((Object.keys(this.rootObject).length === 0)) {
          let lieferant = await this.client.getLieferant(this.lieferantenId).toPromise();
          if (lieferant != null) {
            this.rootObject.wawinr = lieferant.wawinr;
            this.rootObject.vlnr = lieferant.vlnr;
            this.rootObject.name = lieferant.name;
          } else {
            await InteractionController.MessageBox.showWarning("Keine Lieferantendaten", "Lieferantendaten konnten nicht abgerufen werden. Möglicherweise existiert der Lieferant nicht.");
            this.router.navigate([`${this.functionModule.routerPath}`]);
          }
        }

        if ((Object.keys(this.rootObject).length !== 0)) {
          this.publishCustomBreadCrumb();
        }
      });
    } catch (e) {
      await InteractionController.MessageBox.showApplicationException(e);
      this.router.navigate(['.'], {relativeTo: this.activatedRoute.parent});
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.eventAggregator.getEvent(ZeitraumChangedEvent).unsubscribe(this.subscribeLambda);
  }

  async add() {
    if (this.hasWritePermission) {
      if (this.dataGrid.selectedRowKeys.length > 0) {
        let parameter: LieferantenkonditionenpflegeDialogParameter = new LieferantenkonditionenpflegeDialogParameter();
        parameter.lieferantenkonditionId = this.dataGrid.selectedRowKeys[0].lieferantenkonditionId;
        if (await InteractionController.showDialog(LieferantenkonditionenpflegeDialogComponent, parameter, {size: 'xl'})) {
          this.dataGrid.instance.refresh();
        }
      }
      else {
        InteractionController.MessageBox.showWarning("Keine Auswahl", "Bitte selektieren Sie einen Eintrag aus der Liste und führen Sie die Operation erneut aus.");
      }
    }
  }

  setYear(year: number) {
    this.loadParams = {id: this.lieferantenId, year: year};
    this.createDataSource(this.loadParams);
  }

  private publishCustomBreadCrumb() {
    if (this.userManagerService.userLieferanten.length == 1 && this.userManagerService.benutzerArt != 0) {
      let breadcrumbs: IBreadcrumb[] = [];
      let breadcrumb1: IBreadcrumb = {
        breadcrumbGroup: `Lieferant: ${this.rootObject.name}`,
        breadcrumbLabel: "Konditionen",
        params: {},
        url: "",
        state: {}
      };
      breadcrumbs.push(breadcrumb1);
      this.eventAggregator.getEvent(CustomBreadcrumbEvent).publish(breadcrumbs);
    } else {
      let breadcrumbs: IBreadcrumb[] = [];
      let breadcrumb1: IBreadcrumb = {
        breadcrumbGroup: "",
        breadcrumbLabel: `Lieferant: ${this.rootObject.name}`,
        params: {},
        url: this.functionModule.routerPath,
        state: {id: this.lieferantenId}
      };
      breadcrumbs.push(breadcrumb1);

      let breadcrumb2: IBreadcrumb = {
        breadcrumbGroup: "",
        breadcrumbLabel: "Konditionen",
        params: {},
        url: "",
        state: {}
      };
      breadcrumbs.push(breadcrumb2);
      this.eventAggregator.getEvent(CustomBreadcrumbEvent).publish(breadcrumbs);
    }
  }
}
