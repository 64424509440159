<div class="detailarea sticky-top">
  <nav class="navbar navbar-expand detailtoolbar">
    <div class="navbar-collapse collapse">
      <ul class="navbar-nav me-auto">
        <li class="nav-item me-1">
          <a class="nav-link navlink-hover" (click)="save()">
            <img src="./assets/images/Save.svg"/><span>Speichern</span>
          </a>
        </li>
        <li class="nav-item me-1">
          <a class="nav-link navlink-hover" (click)="cancel()">
            <img src="./assets/images/Cancel.svg"/><span>Abbrechen</span>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</div>
<div class="d-flex flex-column flex-grow-1 container-fluid detail-area">
  <form #dialogForm="ngForm" class="p-3">
    <div class="row">
      <div class="card col-xl-6 p-0">
        <div class="card-header p-2">Benutzerdaten</div>
        <div class="card-body">
          <div class="form-group row mb-3">
            <label for="loginName" class="col-form-label col-sm-3">Loginname:</label>
            <div class="col">
              <input type="text" class="form-control" autofocus="autofocus" id="loginName" name="user.loginName"
                     [(ngModel)]="user.loginName" required maxlength="255"
                     ngbTooltip="Die Eingabe eines eindeutigen Loginnamens ist erforderlich."
                     tooltipClass="errortooltip"
                     [disableTooltip]="!dialogForm.form.controls['user.loginName']?.errors || !dialogForm.form.controls['user.loginName']?.touched">
            </div>
          </div>
          <div class="form-group row mb-3">
            <label for="description" class="col-form-label col-sm-3 ">Beschreibung:</label>
            <div class="col">
              <input type="text" class="form-control " id="description" name="user.description"
                     [(ngModel)]="user.description" maxlength="255">
            </div>
          </div>
          <div class="form-group row">
            <label for="benutzerart" class="col-form-label col-sm-3">Benutzerart:</label>
            <div class="col">
              <select class="form-control form-select" id="benutzerart" name="benutzerart" [(ngModel)]="user.benutzerart">
                <option value="0">FÜR SIE Benutzer</option>
                <option value="1">Externer Benutzer</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="card col-xl-6 p-0">
        <div class="card-header p-2">Berechtigungen</div>
        <div class="card-body">

          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" id="lieferanten-tab" data-bs-toggle="tab" href="#lieferanten" role="tab"
                 aria-controls="lieferanten" (click)="lieferantTabActivated()"
                 aria-selected="false">Lieferanten</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="partner-tab" data-bs-toggle="tab" href="#partner" role="tab" aria-controls="partner"
                 (click)="partnerTabActivated()"
                 aria-selected="false">Partner</a>
            </li>

          </ul>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane show active" id="lieferanten" role="tabpanel" aria-labelledby="lieferanten-tab">
              <dx-data-grid #gridLieferant class="mt-3" style="height: 100px"
                            [dataSource]="dataSourceLieferant"
                            [allowColumnReordering]="true"
                            [allowColumnResizing]="true"
                            columnResizingMode="widget"
                            [showBorders]="true"
                            [showColumnLines]="true"
                            [showRowLines]="true"
                            [remoteOperations]="true"
                            [wordWrapEnabled]="true"
                            [hoverStateEnabled]="true"
                            [errorRowEnabled]="true"
                            height="565px"
                            (onSelectionChanged)="onSelectionChanged($event)"
              >

                <dxo-sorting mode="multiple"></dxo-sorting>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-filter-panel [visible]="false"></dxo-filter-panel>
                <dxo-filter-builder-popup></dxo-filter-builder-popup>
                <dxo-header-filter [visible]="true"></dxo-header-filter>
                <dxo-group-panel [visible]="false"></dxo-group-panel>
                <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
                <dxo-selection mode="multiple"></dxo-selection>
                <dxo-grouping #expand [autoExpandAll]="false"></dxo-grouping>
                <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
                <dxo-load-panel [showPane]="false"></dxo-load-panel>
                <dxo-state-storing [enabled]="true" type="custom" [customLoad]="loadStateLieferanten"
                                   [customSave]="saveStateLieferanten"></dxo-state-storing>

                <dxi-column dataField="id" caption="FS Lieferanten Id" alignment="left" dataType="number"
                            [visible]="false"></dxi-column>
                <dxi-column dataField="wawinr" caption="WAWI-NR." alignment="left" dataType="string"></dxi-column>
                <dxi-column dataField="vlnr" caption="VL-Nr." alignment="left" dataType="string"></dxi-column>
                <dxi-column dataField="name" caption="Name" alignment="left" dataType="string"></dxi-column>

              </dx-data-grid>
            </div>
            <div class="tab-pane" id="partner" role="tabpanel" aria-labelledby="partner-tab">
              <dx-data-grid #gridPartner class="mt-3" style="height: 100px"
                            [dataSource]="dataSourcePartner"
                            
                            [allowColumnReordering]="true"
                            [allowColumnResizing]="true"
                            columnResizingMode="widget"
                            [showBorders]="true"
                            [showColumnLines]="true"
                            [showRowLines]="true"
                            [remoteOperations]="true"
                            [wordWrapEnabled]="true"
                            [hoverStateEnabled]="true"
                            [errorRowEnabled]="true"
                            height="565px"
                            (onSelectionChanged)="onSelectionChanged($event)"
              >

                <dxo-sorting mode="multiple"></dxo-sorting>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-filter-panel [visible]="false"></dxo-filter-panel>
                <dxo-filter-builder-popup></dxo-filter-builder-popup>
                <dxo-header-filter [visible]="true"></dxo-header-filter>
                <dxo-group-panel [visible]="false"></dxo-group-panel>
                <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
                <dxo-selection mode="multiple"></dxo-selection>
                <dxo-grouping #expand [autoExpandAll]="false"></dxo-grouping>
                <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
                <dxo-load-panel [showPane]="false"></dxo-load-panel>
                <dxo-state-storing [enabled]="true" type="custom" [customLoad]="loadStatePartner"
                                   [customSave]="saveStatePartner"></dxo-state-storing>

                <dxi-column dataField="id" caption="FS Partner Id" alignment="left" dataType="number"
                            [visible]="false"></dxi-column>
                <dxi-column dataField="bbn" caption="BBN" alignment="left" dataType="number"></dxi-column>
                <dxi-column dataField="anschlusshaus" caption="Anschlusshaus" alignment="left"
                            dataType="string"></dxi-column>

              </dx-data-grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
