import {Component, OnInit, ViewChild} from '@angular/core';
import {DialogBase} from "../../../../framework/dialog/dialog.base";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {WaitCursor, WaitCursorService} from "../../../../framework/wait-cursor/wait-cursor.service";
import {UserManagerService} from "../../../../usermanager/usermanager.service";
import {NgForm} from "@angular/forms";
import {Using} from "../../../../framework/using/using";
import {TaskDialogButtons} from "../../../../framework/message-box/taskdialog/task-dialog-buttons";
import {InteractionController} from "../../../../framework/interaction-controller/interaction.controller";
import {ReturnCodesSaveZeitraum, Zeitraum, ZeitraumClient} from "../../../../webapi/webapi.service";
import {EventAggregator} from "../../../../framework/event-aggregator/event.aggregator";
import {ZeitraumlistChangedEvent} from "../../../../shared/events/zeitraumlist-changed-event";

export class ZeitraumDialogParameter {
  zeitraumId: number;
}

@Component({
  selector: 'app-zeitraum-dialog',
  templateUrl: './zeitraum-dialog.component.html'
})
export class ZeitraumDialogComponent extends DialogBase implements OnInit {
  @ViewChild('dialogForm') dialogForm: NgForm;
  title: string = 'Zeitraum';
  zeitraum: Zeitraum = new Zeitraum();
  private _zeitraumStatus: number = 1;

  constructor(public activeModal: NgbActiveModal,
              public waitCursorService: WaitCursorService,
              private _userManagerService: UserManagerService,
              private dialogParameter: ZeitraumDialogParameter,
              private zeitraumClient: ZeitraumClient) {
    super(activeModal, waitCursorService);
  }

  get zeitraumStatus(): number {
    if (this.zeitraum != null) {
      if (this.zeitraum.status == false) {
        return 0;
      } else {
        return 1;
      }
    } else {
      return this._zeitraumStatus
    }
  }

  set zeitraumStatus(value: number) {
    if (value == 0) {
      this.zeitraum.status = false;
    } else {
      this.zeitraum.status = true;
    }
  }

  async ngOnInit() {
    try {
      await Using.using(new WaitCursor(this.waitCursorService), async () => {
        // create or load zeitraum
        if (this.dialogParameter.zeitraumId == 0) {
          this.zeitraum = new Zeitraum();
          this.zeitraum.id = 0;
          this.zeitraum.status = true;
        } else {
          let zeitraum = await this.zeitraumClient.getZeitraum(this.dialogParameter.zeitraumId).toPromise();
          if (zeitraum != null) {
            this.zeitraum = zeitraum;
          } else {
            await InteractionController.MessageBox.showWarning("Keine Zeitraumdaten", "Zeitraumdaten konnten nicht abgerufen werden. Möglicherweise existiert der Zeitraum nicht.");
            this.close();
          }
        }
      });
    } catch (e) {
      await InteractionController.MessageBox.showApplicationException(e);
      this.close();
    }
  }

  public async onOK() {
    try {
      await Using.using(new WaitCursor(this.waitCursorService), async () => {
        if (this.dialogForm.valid) {
          if (this.dialogForm.dirty) {
            if (this.zeitraum.jahr <= 2147483647) {
              // save
              this.zeitraum.deleted = false;
              let retValue = await this.zeitraumClient.saveZeitraum(this.zeitraum).toPromise();
              this.dialogParameter.zeitraumId = retValue.id;

              if (retValue.returnCode == ReturnCodesSaveZeitraum.DuplicateName) {
                InteractionController.MessageBox.showWarning("Jahr", "Das eingegebene Jahr existiert bereits.");
                this.dialogForm.form.controls['zeitraum.jahr'].setErrors({});
                this.dialogForm.form.controls['zeitraum.jahr'].markAsTouched();
                return;
              }
            }else{
              InteractionController.MessageBox.showWarning("Jahr", 'Das eingegebene Jahr darf den Wert "2147483647" nicht überschreiten.');
              this.dialogForm.form.controls['zeitraum.jahr'].setErrors({});
              this.dialogForm.form.controls['zeitraum.jahr'].markAsTouched();
              return;
            }
            // close dialog with OK
            this.close();
            this.onButtonPressed.emit(TaskDialogButtons.OK);
          } else	// no changes, just close
            this.close();
        } else {
          this.dialogForm.form.controls['zeitraum.wawinr'].markAsTouched();
          InteractionController.MessageBox.showWarning("Validierungsfehler",
            "Ein oder mehrere Validierungsfehler sind aufgetreten. Die fehlerhaften Eingaben sind rot markiert. Bitte korrigieren Sie Ihre Eingaben und versuchen Sie erneut den Datensatz zu speichern.");
        }
      });
    } catch (e) {
      InteractionController.MessageBox.showApplicationException(e);
    }
  }
}
