import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {FunctionWindow} from "../../../../framework/functionwindow/function-window";
import {UserManagerService} from "../../../../usermanager/usermanager.service";
import {ActivatedRoute, Router} from "@angular/router";
import {WaitCursorService} from "../../../../framework/wait-cursor/wait-cursor.service";
import {DxDataGridComponent} from "devextreme-angular";
import {GesamtansichtFunctionModule} from "../../functionsmodules/gesamtansicht-function-module";
import {ZeitraumChangedEvent} from "../../../../shared/events/zeitraum-changed-event";
import {EventAggregator} from "../../../../framework/event-aggregator/event.aggregator";
import {YearselectionService} from "../../../../shared/services/yearselection.service";
import {InteractionController} from "../../../../framework/interaction-controller/interaction.controller";

@Component({
  selector: 'app-gesamtsicht-uebersicht',
  templateUrl: './gesamtsicht-uebersicht.component.html',
  host: {class: 'container-fluid d-flex h-100 flex-column p-0 bg-detailtoolbar'}
})
export class GesamtsichtUebersichtComponent extends FunctionWindow implements OnInit, OnDestroy {
  @ViewChild('gridOverview') dataGrid: DxDataGridComponent;
  gridKey: string[] = ["partnerkonditionId", "lieferantId", "partnerId"];
  loadParams;
  subscribeLambda = year => {
    this.setYear(year);
  };

  statusLookupData = {
    store: {
      type: 'array',
      data: [
        {status: null, name: ''},
        {status: false, name: 'Nein'},
        {status: true, name: 'Ja'}
      ],
      key: "status"
    }
  };

  get hasWritePermission(): boolean {
    return this.userManagerService.hasWritePermission(this.functionModule.contractName);
  }

  constructor(@Inject('BASE_URL') baseUrl: string,
              @Inject(PLATFORM_ID) protected platformId: any,
              protected userManagerService: UserManagerService,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected waitCursorService: WaitCursorService,
              private yearselectionService: YearselectionService,
              protected functionModule: GesamtansichtFunctionModule,
              private eventAggregator: EventAggregator) {
    super(platformId, userManagerService, router, waitCursorService, functionModule);
    this.eventAggregator.getEvent(ZeitraumChangedEvent).subscribe(this.subscribeLambda);

    this.loadParams = {year: yearselectionService.year};
    this.loadUrl = baseUrl + 'api/gesamtsicht/querygesamtoverview';
    this.createDataSource(this.loadParams);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.refresh();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.eventAggregator.getEvent(ZeitraumChangedEvent).unsubscribe(this.subscribeLambda);
  }

  setYear(year: number) {
    this.loadParams = {year: year};
    this.createDataSource(this.loadParams);
  }

  onMainModuleNavigation(state: any) {
    super.onMainModuleNavigation(state);
    if (window.history.state.id !== undefined && window.history.state.id != 0) {
      if (this.dataGrid !== undefined) {
        this.dataGrid.selectedRowKeys = [{id: +window.history.state.id}];
      }
    }
  }

  getErfuellunsgrad(data: number) : number {
    let retValue: number = 0;
    if (data != null){
      retValue = data;
    }
    return retValue;
  }

  navigateToEntry(rowData: any) {
    this.router.navigate([`kondition/${rowData.partnerkonditionId}`], {
      relativeTo: this.activatedRoute,
      state: {rootObjectOffer: rowData}
    });
  }
}
