import {Component, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {DialogBase} from "../../../../../framework/dialog/dialog.base";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {WaitCursor, WaitCursorService} from "../../../../../framework/wait-cursor/wait-cursor.service";
import {UserManagerService} from "../../../../../usermanager/usermanager.service";
import {NgForm} from "@angular/forms";
import {Using} from "../../../../../framework/using/using";
import {TaskDialogButtons} from "../../../../../framework/message-box/taskdialog/task-dialog-buttons";
import {InteractionController} from "../../../../../framework/interaction-controller/interaction.controller";
import {
  Gegenleistungsart,
  GegenleistungsartClient,
  Lieferantenkondition,
  LieferantenkonditionClient,
  ReturnCodesSaveLieferantkondition
} from "../../../../../webapi/webapi.service";
import {DxDataGridComponent} from "devextreme-angular";
import DevExpress from "devextreme";
import {GridHelper} from "../../../../../framework/grid/gridhelper";
import {Router} from "@angular/router";
import {YearselectionService} from "../../../../../shared/services/yearselection.service";
import {DxGridSettings} from "../../../../../framework/grid/dx-grid-settings";

export class LieferantenkonditionenDialogParameter {
  lieferantenkonditionId: number;
}

@Component({
  selector: 'app-lieferantenkonditionen-dialog',
  templateUrl: './lieferantenkonditionen-dialog.component.html'
})
export class LieferantenkonditionenDialogComponent extends DialogBase implements OnInit {
  @ViewChild('dialogForm') dialogForm: NgForm;
  @ViewChild('grid') dataGrid: DxDataGridComponent;
  gridKey: string[] = ["id"];
  dataSource: DevExpress.data.CustomStore;
  loadUrl: string;
  title: string = 'Lieferantenkondition';
  lieferantenkondition: Lieferantenkondition = new Lieferantenkondition();
  selectedId: number;
  public selectorList: Gegenleistungsart[];
  filterOperations: any = [ "=", "contains", "<>", "<", ">", "<=", ">=", "between" ];

  constructor(@Inject('BASE_URL') baseUrl: string,
              @Inject(PLATFORM_ID) protected platformId: any,
              public activeModal: NgbActiveModal,
              protected router: Router,
              private yearselectionService: YearselectionService,
              protected userManagerService: UserManagerService,
              public waitCursorService: WaitCursorService,
              private _userManagerService: UserManagerService,
              private gegenleistungsartClient: GegenleistungsartClient,
              private dialogParameter: LieferantenkonditionenDialogParameter,
              private lieferantenkonditionClient: LieferantenkonditionClient) {
    super(activeModal, waitCursorService);
    this.loadUrl = baseUrl + 'api/lieferanten/lieferantenlist';
    this.dataSource = GridHelper.createGridDataSource(this.gridKey, this.loadUrl, this.router, this.userManagerService, null, undefined);
  }

  async ngOnInit() {
    try {
      this.lieferantenkondition = new Lieferantenkondition();
      this.lieferantenkondition.zeitraum = this.yearselectionService.year;
      await this.initGegenleistungsartList();
    } catch (e) {
      await InteractionController.MessageBox.showApplicationException(e);
      this.close();
    }
  }

  changeValue(e) {
    this.lieferantenkondition.gegenleistungsart = e.target.selectedOptions[0].text;
  }

  async initGegenleistungsartList() {
    try {
      await Using.using(new WaitCursor(this.waitCursorService), async () => {
        this.selectorList = await this.gegenleistungsartClient.getList().toPromise();
        if (this.selectorList.length > 0) {
          this.selectedId = this.selectorList[0].id;
          this.lieferantenkondition.gegenleistungsart = this.selectorList[0].name;
        }
      });
    } catch (e) {
      InteractionController.MessageBox.showApplicationException(e);
    }
  }

  public async onOK() {
    try {
      await Using.using(new WaitCursor(this.waitCursorService), async () => {
        if (this.checkMaxNumber()) {
          if (this.dialogForm.valid) {
            if (this.dialogForm.dirty) {
                if (this.dataGrid.selectedRowKeys.length > 0) {
                  // save
                  this.lieferantenkondition.id = 0;
                  this.lieferantenkondition.lieferantenId = this.dataGrid.selectedRowKeys[0].id;

                  let retValue = await this.lieferantenkonditionClient.save(this.lieferantenkondition).toPromise();
                  this.dialogParameter.lieferantenkonditionId = retValue.id;

                  // check return value
                  if (retValue.returnCode == ReturnCodesSaveLieferantkondition.DuplicateEntry) {
                    InteractionController.MessageBox.showWarning("Kondition", "Die eingegebene Lieferantenkondition existiert bereits.");
                    return;
                  }

                  // close dialog with OK
                  this.close();
                  this.onButtonPressed.emit(TaskDialogButtons.OK);
                } else {
                  InteractionController.MessageBox.showWarning("Keine Auswahl", "Bitte selektieren Sie einen Eintrag aus der Lieferantenliste und führen Sie die Operation erneut aus.");
                }
            } else	// no changes, just close
              this.close();
          } else {
            this.dialogForm.form.controls['lieferantenkondition.ksnr'].markAsTouched();
            this.dialogForm.form.controls['lieferantenkondition.anzahlArtikel'].markAsTouched();
            this.dialogForm.form.controls['lieferantenkondition.anzahlGegenleistungen'].markAsTouched();
            this.dialogForm.form.controls['lieferantenkondition.wkz'].markAsTouched();
            InteractionController.MessageBox.showWarning("Validierungsfehler",
              "Ein oder mehrere Validierungsfehler sind aufgetreten. Die fehlerhaften Eingaben sind rot markiert. Bitte korrigieren Sie Ihre Eingaben und versuchen Sie erneut den Datensatz zu speichern.");
          }
        } else {
          return
        }
      });
    } catch (e) {
      InteractionController.MessageBox.showApplicationException(e);
    }
  }

  checkMaxNumber(): boolean{
    let retValue: boolean = true;
    if (this.lieferantenkondition.anzahlGegenleistungen > 2147483647) {
      this.dialogForm.form.controls['lieferantenkondition.anzahlGegenleistungen'].setErrors({});
      this.dialogForm.form.controls['lieferantenkondition.anzahlGegenleistungen'].markAsTouched();
      retValue = false;
    }
    if (this.lieferantenkondition.anzahlArtikel > 2147483647) {
      this.dialogForm.form.controls['lieferantenkondition.anzahlArtikel'].setErrors({});
      this.dialogForm.form.controls['lieferantenkondition.anzahlArtikel'].markAsTouched();
      retValue = false;
    }
    if (this.lieferantenkondition.ksnr > 2147483647) {
      this.dialogForm.form.controls['lieferantenkondition.ksnr'].setErrors({});
      this.dialogForm.form.controls['lieferantenkondition.ksnr'].markAsTouched();
      retValue = false;
    }
    if (retValue == false) {
      InteractionController.MessageBox.showWarning("Zahlen prüfen", 'Die eingegebenen Zahlen dürfen den Wert "2147483647" nicht überschreiten.');
    }
    return retValue;
  }

  public onGridToolbarPreparing(e) {
    let toolbarItems = e.toolbarOptions.items;
    toolbarItems.forEach((item) => {
      item.visible = false;
    });
    e.toolbarOptions.visible = false;
  }

  loadState = () => {
    return DxGridSettings.loadDataGridSettings(this.dataGrid, "LieferantenkonditionenDialog", this.platformId);
  };

  saveState = (state) => {
    DxGridSettings.saveDataGridSettings(state, "LieferantenkonditionenDialog", this.platformId);
  }
}
