import {TypedEvent} from "../message-box/typed.event";
import {TaskDialogButtons} from "../message-box/taskdialog/task-dialog-buttons";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {WaitCursorService} from "../wait-cursor/wait-cursor.service";

export abstract class DialogBase {
  public onButtonPressed = new TypedEvent<TaskDialogButtons>();

  protected constructor(public activeModal: NgbActiveModal, public waitCursorService: WaitCursorService) {
    this.waitCursorService.hideWaitCursor();
  }

  public onOK() {
    this.close();
    this.onButtonPressed.emit(TaskDialogButtons.OK);
  }

  public onCancel() {
    this.close();
    this.onButtonPressed.emit(TaskDialogButtons.Cancel);
  }

  public onClose() {
    this.close();
    this.onButtonPressed.emit(TaskDialogButtons.Close);
  }

  public close() {
    this.activeModal.close();
  }
}
