import {Injectable} from "@angular/core";
import {FunctionModuleBase} from "../../../framework/functionmodule/function-module-base";
import {UserManagerService} from "../../../usermanager/usermanager.service";
import {Router} from "@angular/router";
import {GegenleistungsartdatenComponent} from "../gegenleistungsart/gegenleistungsartdaten/gegenleistungsartdaten.component";

@Injectable({
  providedIn: 'root'
})
export class GegenleistungsartdatenFunctionModule extends FunctionModuleBase {
  contractName = "Gegenleistungsartdaten";
  functionName = "Gegenleistungsarten";
  groupName = "Stammdaten";
  routerPath = "gegenleistungsart";
  component = GegenleistungsartdatenComponent;
  homeTileClass = "tileGroupClass3";
  homeTileImageSource = './assets/images/Arten.svg';
  homeTileSubTitle = "Gegenleistungsartenübersicht";

  constructor(protected userManagerService: UserManagerService, protected router: Router) {
    super(userManagerService, router);
    this.configureFunctionModule();
  }

  configureModuleRoutes() {
    super.configureModuleRoutes();
  }
}
