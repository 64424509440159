import {Injectable, OnInit, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WaitCursorService {
  active: boolean = false;

  showWaitCursor() {
    this.active = true;
  }

  hideWaitCursor() {
    this.active = false;
  }
}

export class WaitCursor {
  constructor(public waitCursorService: WaitCursorService) {
    this.waitCursorService.showWaitCursor();
  }

  dispose() {
    this.waitCursorService.hideWaitCursor();
  }
}
